import React from 'react';
import { object, array } from 'prop-types';
import { throttle } from 'lodash';
import { Controller, Scene } from 'react-scrollmagic';
import classNames from 'classnames';
import { LinkWrap } from 'components';
import Slider from 'react-slick';
// import 'imports-loader?define=>false!slick-carousel/slick/slick.scss';
// import 'imports-loader?define=>false!slick-carousel/slick/slick-theme.scss';

import ImageWrap from '../ImageWrap';
import './styles.scss';

class HubsSet extends React.Component {
  static propTypes = {
    dataSet: array.isRequired
  };

  render() {
    const {
      props: { dataSet }
    } = this;

    const dataSetRs = dataSet ? dataSet : [];

    return (
      <>
        {dataSetRs.map(
          ({ sm3_image_name, sm3_image, sm3_image_link, sm3_image_link_rel = null }) => (
            <div
              key={sm3_image_name}
              className="MultipleImagesSignpostModuleSm003-contentBox"
            >
              <LinkWrap to={sm3_image_link} rel={sm3_image_link_rel}>
                <ImageWrap
                  className="MultipleImagesSignpostModuleSm003-image"
                  image={sm3_image}
                />
                <h3 className="MultipleImagesSignpostModuleSm003-subTitle">
                  {sm3_image_name}
                </h3>
              </LinkWrap>
            </div>
          )
        )}
      </>
    );
  }
}

class HubsSlider extends React.Component {
  static propTypes = {
    dataSet: array.isRequired
  };

  render() {
    const {
      props: { dataSet }
    } = this;
    const settings = {
      className: 'center',
      centerMode: true,
      infinite: true,
      centerPadding: '150px 0 50px',
      slidesToShow: 1,
      speed: 500,
      focusOnSelect: true,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            centerPadding: '100px 0 50px'
          }
        },
        {
          breakpoint: 480,
          settings: {
            centerPadding: '50px'
          }
        }
      ]
    };

    const dataSetRs = dataSet ? dataSet : [];

    return (
      <Slider {...settings}>
        {dataSetRs.map(
          ({ sm3_image_name, sm3_image, sm3_image_link, sm3_image_link_rel = null }) => (
            <div
              key={sm3_image_name}
              className="MultipleImagesSignpostModuleSm003-contentBox"
            >
              <LinkWrap sm3_image_link={sm3_image_link} rel={sm3_image_link_rel}>
                <ImageWrap
                  className="MultipleImagesSignpostModuleSm003-image"
                  image={sm3_image}
                />
                <h3 className="MultipleImagesSignpostModuleSm003-subTitle">
                  {sm3_image_name}
                </h3>
              </LinkWrap>
            </div>
          )
        )}
      </Slider>
    );
  }
}

export default class MultipleImagesSignpostModuleSm003 extends React.PureComponent {
  static propTypes = {
    prismic: object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isCarouselVisible: true,
      stickDuration: 0
    };
    this.node = React.createRef();
    this.throttleScroll = throttle(this._imgSlider, 100, {
      capture: true,
      passive: true
    });
  }

  componentDidMount() {
    this._imgSlider();
    document.addEventListener('scroll', this.throttleScroll);
    window.addEventListener('resize', this._imgSlider);

    // Set the initial sticking duration
    let contentNodes = document.getElementsByClassName(
      'MultipleImagesSignpostModuleSm003-content'
    );
    let innerWrapNode = document.getElementsByClassName(
      'MultipleImagesSignpostModuleSm003-innerWrap'
    );

    let stickDuration =
      (contentNodes[0] ? contentNodes[0].offsetHeight : 0) -
      (innerWrapNode[0] ? innerWrapNode[0].offsetHeight : 0);
    this.setState({ stickDuration });

    // Make sure sticking duration is updated
    document.addEventListener(
      'scroll',
      throttle(() => {
        stickDuration =
          (contentNodes[0] ? contentNodes[0].clientHeight : 0) -
          (innerWrapNode[0] ? innerWrapNode[0].clientHeight : 0);
        this.setState({ stickDuration });
      }, 500)
    );
  }

  componentWillUnmount() {
    this.node = null;
    document.removeEventListener('scroll', this.throttleScroll);
    window.removeEventListener('resize', this._imgSlider);
  }

  // Method for switching the image grid to the image slider when switching to tablet resolution.
  _imgSlider = () => {
    if (window.matchMedia('(max-width: 767px)').matches) {
      this.setState({ isCarouselVisible: true });
    } else {
      this.setState({ isCarouselVisible: false });
    }
  };

  minMatching = () => {
    if (typeof window !== 'undefined') {
      return !window.matchMedia('(max-width: 520px)').matches;
    }
  };

  render() {
    const {
      state: { isCarouselVisible, stickDuration },
      props: {
        prismic: {
          primary: {
            sm3_title,
            sm3_link,
            sm3_link_rel,
            sm3_link_name,
            sm3_description
          },
          items
        }
      },
      minMatching
    } = this;
    return (
      <section className="MultipleImagesSignpostModuleSm003">
        <h2 className="MultipleImagesSignpostModuleSm003-title">
          {sm3_title}
        </h2>
        <div className="js-stickyContainer-trigger" />
        <div
          className="MultipleImagesSignpostModuleSm003-container"
          ref={this.node}
        >
          <div className="MultipleImagesSignpostModuleSm003-content">
            {isCarouselVisible ? (
              <HubsSlider dataSet={items} />
            ) : (
              <Controller>
                <HubsSet dataSet={items} />
                <Scene
                  classToggle={[
                    '.is-stickySidebar',
                    'is-bottom'
                  ]}
                  triggerHook="onLeave"
                  offset={-300}
                >
                  <div />
                </Scene>
              </Controller>
            )}
          </div>
          <Controller>
            <Scene
              classToggle={['.is-stickySidebar', 'is-fixed']}
              triggerHook="onLeave"
              triggerElement=".js-stickyContainer-trigger"
              offset={0}
              duration={stickDuration}
              pin={minMatching()}
            >
              <div
                className={classNames(
                  'MultipleImagesSignpostModuleSm003-sidebar',
                  {
                    'is-stickySidebar': !isCarouselVisible
                  }
                )}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: sm3_description && sm3_description.html ? sm3_description.html : ''
                  }}
                  className="MultipleImagesSignpostModuleSm003-boxText"
                />
                <div className="MultipleImagesSignpostModuleSm003-boxButton">
                  {sm3_link ? (
                    <a
                      href={sm3_link.url}
                      target={sm3_link.target}
                      rel={sm3_link_rel}
                    >
                      {sm3_link_name}
                    </a>
                  ) : null}
                </div>
              </div>
            </Scene>
          </Controller>
        </div>
      </section>
    );
  }
}
