import React from 'react';
import { string, object, func, number, bool } from 'prop-types';
import classNames from 'classnames';
import ImageWrap from 'components/ImageWrap';
import './styles.scss';

const AccordionSection = ({
  title,
  text,
  image,
  linkType,
  link,
  linkName,
  openHandler,
  sectionIndex,
  index,
  isOpened
}) => (
  <section className="AccordionSection">
    <label className="AccordionSection-titleWrap">
      <input
        type="checkbox"
        className="AccordionSection-checkbox"
        onChange={() => openHandler(index)}
        checked={sectionIndex === index && isOpened}
      />
      <h4 className="AccordionSection-title">{title}</h4>
      <div className="AccordionSection-arrowBlock">
        <div
          className={classNames(
            'AccordionSection-arrow',
            sectionIndex === index && isOpened
              ? 'AccordionSection-arrow--down'
              : 'AccordionSection-arrow--right'
          )}
        />
      </div>
    </label>
    <div
      className={classNames(
        'AccordionSection-contentWrap',
        sectionIndex === index &&
        isOpened &&
        'AccordionSection-contentWrap--opened'
      )}
    >
      <div
        className="AccordionSection-copy"
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <div className="AccordionSection-mediaWrap">
        {link && (
          <a
            className="AccordionSection-link"
            href={link.url}
            target="blank"
            download={linkType === 'download'}
          >
            {linkName}
          </a>
        )}
        {image && image.fluid && (
          <ImageWrap
            image={image}
            className="AccordionSection-image"
          />
        )}
      </div>
    </div>
  </section>
);

AccordionSection.propTypes = {
  title: string.isRequired,
  text: string.isRequired,
  openHandler: func.isRequired,
  sectionIndex: number.isRequired,
  index: number.isRequired,
  isOpened: bool.isRequired,
  image: object,
  linkType: string,
  link: object,
  linkName: string
};

AccordionSection.defaultProps = {
  linkType: '',
  link: null,
  image: null,
  linkName: ''
};

export default AccordionSection;
