import React, { Fragment } from 'react';
import { object } from 'prop-types';
import { isSafari, isMobileSafari } from 'react-device-detect';
import classNames from 'classnames';
import { Formik, Field, Form } from 'formik';
import { enquiry, interestedIn, newsletter } from '../../utils/validation';
import { t } from '../../utils/lang-helper';
import './styles.scss';
import { spotlerSubscribe } from '../../utils/spotler-subscribe';
import { bcc } from '../../utils/bcc';

export default class ContextualEnquiryFormCfm001 extends React.PureComponent {
  static propTypes = {
    primary: object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      formFocus: false,
      isSubmit: false,
      submiting: false,
      submitFailed: false,
      msg: ''
    };
  }

  onFormFocus = () => {
    this.setState({ formFocus: true });
  };

  onFormBlur = value => {
    if (isSafari || isMobileSafari) {
      Object.values(value).some(el => el === '') &&
      this.setState({ formFocus: false });
    } else {
      this.setState({ formFocus: false });
    }
  };

  onReset = () => {
    this.setState({
      isSubmit: false
    });
  };

  render() {
    const {
      formFocus,
      isSubmit,
      submitFailed,
      msg,
      submiting
    } = this.state;
    const {
      primary: {
        cfm1_hide,
        cfm1_title,
        cfm1_copy,
        cfm1_form_type,
        cfm1_privacy_note,
        cfm1_success_title,
        cfm1_success_copy
      }
    } = this.props;

    // Email settings
    let validationSchema;
    let emailSubject = '';
    let toEmail = '';

    if (cfm1_form_type === 'newsletter') {
      validationSchema = newsletter;
      emailSubject = 'Exclusive Preview';
      toEmail = 'iskoarquas@isko.com.tr';
    } else if (cfm1_form_type === 'products') {
      validationSchema = enquiry;
      emailSubject = 'Exclusive Preview';
      toEmail = 'iskoarquas@isko.com.tr';
    } else if (cfm1_form_type === 'interested_in') {
      validationSchema = interestedIn;
      emailSubject = 'Interested In - Contact Request';
      toEmail = 'iskoarquas@isko.com.tr';
    } else {
      validationSchema = enquiry;
      emailSubject = 'New contact request';
      toEmail = 'info@isko.com.tr';
    }

    return cfm1_hide === 'false' ? (
      <section
        className={classNames(
          'CEForm',
          cfm1_form_type === 'interested_in' && 'CEForm-fullWidth'
        )}
      >
        <div
          className={classNames(
            'CEForm-confirmation',
            !isSubmit && 'CEForm-none'
          )}
        >
          <h2 className="CEForm-confirmTitle">
            {cfm1_success_title}
          </h2>
          <div
            dangerouslySetInnerHTML={{
              __html: cfm1_success_copy.html
            }}
            className="CEForm-confirmDesc"
          />
          {/*<button*/}
          {/*    className="CEForm-confirmButton"*/}
          {/*    type="button"*/}
          {/*    onClick={this.onReset}*/}
          {/*>*/}
          {/*    {t('Reset')}*/}
          {/*</button>*/}
        </div>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            company: '',
            country: '',
            privacyCheck: false,
            subscribeCheck: false
          }}
          validationSchema={validationSchema}
          onSubmit={async (val, actions) => {
            const {
              email,
              firstName,
              lastName,
              company,
              country,
              privacyCheck,
              subscribeCheck
            } = val;
            const pageUrl = window.location.href;
            this.setState({ submiting: true });
            // Newsletter form submissions
            if (cfm1_form_type === 'newsletter') {
              const addToMicrosoftDynamics = email => {
                async function postData(url, data) {
                  const response = await fetch(url, {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    redirect: 'follow',
                    referrerPolicy: 'no-referrer',
                    body: JSON.stringify(data)
                  });
                  return await response.json();
                }

                postData(`${process.env.MICROSOFT_DYNAMICS}`, {
                  EmailAddress: email
                }).then(data => {
                  if (data.IsSuccess === true) {
                    this.setState({
                      isSubmit: true,
                      formFocus: false,
                      submiting: false,
                      submitFailed: false
                    });
                  } else {
                    this.setState({
                      msg: data.Message,
                      submitFailed: true
                    });
                  }
                });
              };
              await addToMicrosoftDynamics(email, actions);
            } else {
              let mailData = {
                subject: emailSubject,
                html: `
                  <h2>${emailSubject}</h2>
                  <hr/>
                  <p>First name: ${firstName}</p>
                  <p>Last name: ${lastName}</p>
                  <p>Email: ${email}</p>
                  <p>Company: ${company}</p>
                  <p>Country: ${country}</p>
                  <p>Privacy Agree: ${privacyCheck ? 'Yes' : 'No'}</p>
                  <p>Subscribe Check: ${subscribeCheck ? 'Yes' : 'No'}</p>
                  <p>Page: ${pageUrl}</p>
                `,
                from_email: email,
                from_name:
                  firstName + ' ' + lastName,
                to: [
                  {
                    email: toEmail,
                    type: 'to'
                  }
                ]
              };

              // All other form submitting
              fetch(
                'https://mandrillapp.com/api/1.0/messages/send.json',
                {
                  method: 'post',
                  body: JSON.stringify({
                    key: '0K9RSSnf1OxqWsXqaIYZQw',
                    message: mailData
                  })
                }
              ).then(responseData => {
                if (responseData.ok) {
                  actions.resetForm();
                  this.setState({
                    isSubmit: true,
                    formFocus: false,
                    submiting: false,
                    submitFailed: false
                  });
                } else {
                  this.setState({
                    msg: 'Something was wrong, try again.',
                    submitFailed: true
                  });
                }

                try {
                  window.dataLayer.push({
                    'event': 'formSubmission',
                    'data': {
                      component: 'ContextualEnquiryFormCfm001',
                      type: 'type003'
                    }
                  });
                } catch (e) {
                  //
                }

                // Send user subscription
                const formName = 'enquiry-form-cfm001';
                if (subscribeCheck) {
                  spotlerSubscribe(email, firstName, lastName, formName);
                }

                // BCC
                bcc(mailData.subject, mailData.html, email, formName);
              });
            }
          }}
        >
          {({ values, errors }) => (
            <Form
              name="Form"
              className={classNames(
                isSubmit ? 'CEForm-none' : 'CEForm-form'
              )}
              onFocus={this.onFormFocus}
              onBlur={() => this.onFormBlur(values)}
            >
              <h2 className="CEForm-title">{cfm1_title}</h2>

              <div
                dangerouslySetInnerHTML={{
                  __html: cfm1_copy.html
                }}
                className="CEForm-subtitle"
              />
              {cfm1_form_type === 'newsletter' ? (
                ''
              ) : (
                <Fragment>
                  <Field
                    className={classNames(
                      'CEForm-input',
                      values.firstName &&
                      'CEForm-imageWithIcon'
                    )}
                    type="text"
                    name="firstName"
                    placeholder={t('First Name')}
                    autoComplete="off"
                    value={values.firstName}
                  />
                  {errors.firstName && values.firstName && (
                    <div className="CEForm-fieldError">
                      {errors.firstName}
                    </div>
                  )}
                  <Field
                    className={classNames(
                      'CEForm-input',
                      values.lastName &&
                      'CEForm-imageWithIcon'
                    )}
                    type="text"
                    name="lastName"
                    autoComplete="off"
                    placeholder={t('Last Name')}
                    value={values.lastName}
                  />
                  {errors.lastName && values.lastName && (
                    <div className="CEForm-fieldError">
                      {errors.lastName}
                    </div>
                  )}
                </Fragment>
              )}
              <Field
                className={classNames(
                  'CEForm-input',
                  values.email && 'CEForm-imageWithIcon'
                )}
                type="email"
                name="email"
                autoComplete="off"
                placeholder={t(
                  'Enter your e-mail address here'
                )}
                value={values.email}
              />
              {errors.email && values.email && (
                <div className="CEForm-fieldError">
                  {errors.email}
                </div>
              )}

              {cfm1_form_type === 'interested_in' &&
                <>
                  <Field
                    className={classNames(
                      'CEForm-input',
                      values.company && 'CEForm-imageWithIcon'
                    )}
                    type="text"
                    name="company"
                    autoComplete="off"
                    placeholder={'*' + t(
                      'Your company'
                    )}
                    value={values.company}
                  />
                  {errors.company && values.company && (
                    <div className="CEForm-fieldError">
                      {errors.company}
                    </div>
                  )}

                  <Field
                    className={classNames(
                      'CEForm-input',
                      values.country && 'CEForm-imageWithIcon'
                    )}
                    type="text"
                    name="country"
                    autoComplete="off"
                    placeholder={'*' + t(
                      'Country'
                    )}
                    value={values.country}
                  />
                  {errors.country && values.country && (
                    <div className="CEForm-fieldError">
                      {errors.country}
                    </div>
                  )}
                </>
              }

              <label className="custom-check">
                {t('agree form text')}
                <Field type="checkbox" name="privacyCheck" required={true} />
                <span className="checkmark" />
              </label>

              <label className="custom-check">
                {t('subscribe form text')}
                <Field type="checkbox" name="subscribeCheck" />
                <span className="checkmark" />
              </label>

              {submitFailed && (
                <p className="CEForm-fieldError">{msg}</p>
              )}

              <div
                className={classNames(
                  formFocus
                    ? 'CEForm-is-visible'
                    : 'CEForm-is-hidden'
                )}
              >
                {cfm1_form_type === 'newsletter' ? (
                  ''
                ) : (
                  <p
                    className="CEForm-desc"
                    dangerouslySetInnerHTML={{
                      __html: cfm1_privacy_note.html
                    }}
                  />
                )}

                {cfm1_form_type === 'newsletter' &&
                  <button
                    className="CEForm-button"
                    type="submit"
                    disabled={!values.email}
                  >
                    {t('sign up')}
                  </button>
                }
                {cfm1_form_type === 'interested_in' &&
                  <button
                    className="CEForm-button"
                    type="submit"
                    disabled={
                      (!values.email || errors.email)
                      || (!values.company || errors.company)
                      || (!values.country || errors.country)
                      || (!values.privacyCheck || errors.privacyCheck)
                    }
                  >
                    {t('Submit')}
                  </button>
                }
                {cfm1_form_type !== 'newsletter' && cfm1_form_type !== 'interested_in' &&
                  <button
                    className="CEForm-button"
                    type="submit"
                    disabled={
                      !values.firstName ||
                      !values.lastName ||
                      !values.email ||
                      !values.privacyCheck
                    }
                  >
                    {t('Submit')}
                  </button>
                }

                {!isSubmit && submiting && (
                  <p className="CEForm-fieldError">
                    {t('Please wait, data processing...')}
                  </p>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </section>
    ) : null;
  }
}
