import React, { Component } from 'react';
import { string, object, oneOfType } from 'prop-types';
import { navigate } from 'gatsby';
import classNames from 'classnames';

// This wrapper is necessary for a rational work of rendering a modal window when user makes
// first internal redirect from the home page in a user session.
class FirstRedirectWrapperForLink extends Component {
  state = {
    isModalVisible: false
  };

  static propTypes = {
    children: oneOfType([string, object]),
    to: string.isRequired,
    linkClass: string.isRequired
  };

  // This method is turned off so that the modal window does not appear,
  // since the problem with styles has not been solved.
  handleClick = (props) => {
    if (this.props.to === '#') {
      return;
    }

    if (!sessionStorage.getItem('singleRenderSubscription')) {
      sessionStorage.setItem('singleRenderSubscription', true);
    }
    navigate(props.to);
  };

  render() {
    let {
      props: { linkClass, rel = null, target = null, callback = null },
      handleClick
    } = this;

    const callStack = [
      handleClick
    ];

    if (typeof callback === 'function') {
      callStack.push(callback);
    }

    return (
      <span className={linkClass} data-rel={rel} data-target={target} onClick={() => {
        callStack.forEach(call => call(this.props));
      }}>
        {this.props.children}
      </span>
    );
  }
}

const NavLink = ({
  liClass, aClass, link, children, rel = null, target = null, callback = null,
  highlighted = false, liChildren = null
}) => (
  <li className={liClass}>
    {link.includes('http') ? (
      <>
        <a className={aClass} href={link} target={target} rel={rel}>
          {children}
        </a>
        {liChildren}
      </>
    ) : (
      <>
        <FirstRedirectWrapperForLink
          className={aClass}
          to={link}
          target={target}
          linkClass={aClass}
          rel={rel}
          callback={callback}
        >
          <span className={classNames({ 'highlighted': highlighted })}>{children}</span>
        </FirstRedirectWrapperForLink>
        {liChildren}
      </>
    )}
  </li>
);

NavLink.propTypes = {
  liClass: string.isRequired,
  aClass: string.isRequired,
  link: string.isRequired,
  children: oneOfType([string, object])
};

export default NavLink;
