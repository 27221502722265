import React from 'react';
import { object, string } from 'prop-types';
import { LinkWrap } from 'components';
import classNames from 'classnames';
import './styles.scss';
// import { string } from 'postcss-selector-parser';

const CopyOnlySignpostSm0091 = ({
  primary: {
    sm91_hide,
    sm91_section_alignment,
    sm91_title,
    sm91_description,
    sm91_link_name,
    sm91_link,
    sm91_link_rel
  },
  type
}) => {
  return sm91_hide === 'false' ? (
    <section
      className={classNames(
        'COSSm0091',
        sm91_section_alignment === 'right'
          ? 'COSSm0091--right'
          : sm91_section_alignment === 'left'
            ? 'COSSm0091--right'
            : 'COSSm0091--center'
      )}
    >
      <div
        className={classNames('COSSm0091-wrap', {
          'is-faq': type
        })}
      >
        <h3 className="COSSm0091-title">{sm91_title}</h3>
        <div
          dangerouslySetInnerHTML={{ __html: sm91_description.html }}
          className="COSSm0091-description"
        />
        <LinkWrap className="COSSm0091-link" to={sm91_link} rel={sm91_link_rel}>
          {sm91_link_name}
        </LinkWrap>
      </div>
    </section>
  ) : null;
};

CopyOnlySignpostSm0091.propTypes = {
  primary: object.isRequired,
  type: string
};

CopyOnlySignpostSm0091.defaultProps = {
  type: ''
};

export default CopyOnlySignpostSm0091;
