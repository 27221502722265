import React, { Component } from 'react';
import Img from 'gatsby-image';
import { object, string } from 'prop-types';

export default class ImageWrap extends Component {
  static propTypes = {
    image: object,
    className: string,
    alt: string,
    style: object
  };

  render() {
    const { image, className, style, alt } = this.props;

    if (!image) {
      return null;
    }

    // Local image
    return image.localFile ? (
      <>
        {image.localFile.extension === 'gif' && (
          <img
            className={className}
            style={style}
            src={image.localFile.publicURL}
            alt={alt}
          />
        )}
        {image.localFile.childImageSharp && (
          <Img
            className={className}
            fluid={image.localFile.childImageSharp.fluid}
            style={style}
            alt={alt}
          />
        )}
      </>
    ) : image.dimensions ? (
      <img
        className={className}
        style={style}
        width={image.dimensions.width}
        height={image.dimensions.height}
        src={image.url}
        alt={alt}
      />
    ) : (
      <>
        {image.fluid && (
          <Img
            className={className}
            fluid={image.fluid}
            style={style}
            alt={alt}
          />
        )}
      </>
    );
  }
}
