import React, { PureComponent } from 'react';
import { object, array } from 'prop-types';
import { isMobile } from 'react-device-detect';
import { navigate } from 'gatsby';
import ProductCard from 'components/ProductCard';
import CollectionCard from 'components/CollectionCard';
import { getLocalePrefix } from '../../utils/lang-helper';
import './styles.scss';

export default class ProductListingGridGm002 extends PureComponent {
  static propTypes = {
    primary: object.isRequired,
    items: array.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      videoLoop: false,
      index: 0
    };
    this.hasBeenTouchedRecently = false;
  }

  componentWillUnmount() {
    clearTimeout(this.hoverTime);
    clearTimeout(this.buttonPressTimer);
  }

  handleMouseEnter = i => {
    this.hoverTime = setTimeout(() => {
      !isMobile && this.setState({ index: i, videoLoop: true });
    }, 500);
  };

  handleMouseLeave = () => {
    !isMobile && this.setState({ videoLoop: false });
    clearTimeout(this.hoverTime);
  };

  handleClick = url => {
    if (this.hasBeenTouchedRecently) {
      return;
    }
    navigate(getLocalePrefix() + url);
  };

  handleTouchStart = i => {
    this.buttonPressTimer = setTimeout(() => {
      this.hasBeenTouchedRecently = true;
      this.setState({
        index: i,
        videoLoop: true
      });
    }, 500);
  };

  handleTouchEnd = () => {
    this.hasBeenTouchedRecently = false;
    clearTimeout(this.buttonPressTimer);
  };

  render() {
    const {
      primary: {
        gm2_hide,
        gm2_product,
        gm2_header,
        gm2_description,
        gm2_is_collection,
        gm2_collection_name,
        gm2_featuring_copy,
        gm2_collection_img_vid,
        gm2_video_link
      },
      items
    } = this.props;
    const { videoLoop, index } = this.state;
    const url =
      gm2_video_link.embed_url === null ? '' : gm2_video_link.embed_url;
    return gm2_hide === 'false' ? (
      <section className="Grid">
        {gm2_is_collection === 'Yes' && gm2_product ? (
          <CollectionCard
            product={gm2_product.uid}
            description={gm2_description}
            heading={gm2_header.text}
            name={gm2_collection_name.text}
            copy={gm2_featuring_copy}
            image={gm2_collection_img_vid}
            videoUrl={url}
          />
        ) : null}
        <div className="Grid-products">
          {items.map((el, i) => {
            const url =
              el.gm2_video_link.embed_url === null
                ? ''
                : el.gm2_video_link.embed_url;
            return (
              <ProductCard
                key={i}
                featured={el.gm2_featured === 'Yes'}
                featuredText={el.gm2_feature_title.text}
                cardClassName="Grid-card"
                arrayIndex={i}
                listing={true}
                stateIndex={index}
                videoUrl={url}
                image={el.gm2_image}
                name={el.gm2_product_name}
                description={el.gm2_product_description}
                videoLoop={videoLoop}
                handleMouseEnter={() =>
                  this.handleMouseEnter(i)
                }
                handleMouseLeave={this.handleMouseLeave}
                handleTouchStart={() =>
                  this.handleTouchStart(i)
                }
                handleTouchEnd={this.handleTouchEnd}
                handleClick={() => {
                  if (el.gm2_product === null) {
                    return;
                  }
                  this.handleClick(el.gm2_product.uid);
                }}
              />
            );
          })}
        </div>
      </section>
    ) : null;
  }
}
