import React from 'react';
import { object, func, string, array } from 'prop-types';
import Slider from 'react-slick';
import { Controller, Scene } from 'react-scrollmagic';
import { LinkWrap } from 'components';
import { t } from '../../utils/lang-helper';
import { ImageWrap } from 'components';
import { ReactComponent as ArrowLeftSVG } from 'svg/arrow-left2.svg';
import { ReactComponent as ArrowRightSVG } from 'svg/arrow-left2.svg';
import './styles.scss';

function ArrowRight(props) {
  const { onClick, className } = props;
  return <ArrowRightSVG className={className} onClick={onClick} />;
}

function ArrowLeft(props) {
  const { onClick, className } = props;
  return <ArrowLeftSVG className={className} onClick={onClick} />;
}

ArrowLeft.propTypes = {
  onClick: func,
  className: string
};

ArrowRight.propTypes = {
  onClick: func,
  className: string
};

function change(fromTo) {
  const pagination = document.querySelectorAll('.Carousel-pagination');
  Array.from(pagination).map(el => {
    el.style.opacity = fromTo;
    el.style.transition = 'opacity .2s ease-in';
  });

  const prev = document.querySelectorAll('.slick-prev');
  const next = document.querySelectorAll('.slick-next');
  Array.from(prev).map(el => {
    el.style.opacity = fromTo;
    el.style.transition = 'opacity .2s ease-in';
  });
  Array.from(next).map(el => {
    el.style.opacity = fromTo;
    el.style.transition = 'opacity .2s ease-in';
  });
}

const settings = {
  centerMode: true,
  centerPadding: '200px',
  slidesToShow: 1,
  infinite: true,
  focusOnSelect: false,
  arrows: true,
  cssEase: 'cubic-bezier(.48,.46,.21,.96)',
  prevArrow: <ArrowLeft />,
  nextArrow: <ArrowRight />,
  speed: 600,
  beforeChange: () => {
    change(0);
  },
  afterChange: () => {
    change(1);
  },
  responsive: [
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerPadding: '15px',
        beforeChange: () => {
          return null;
        },
        afterChange: () => {
          return null;
        }
      }
    },
    {
      breakpoint: 768,
      settings: {
        centerPadding: '50px'
      }
    },
    {
      breakpoint: 1024,
      settings: {
        centerPadding: '100px'
      }
    }
  ]
};

const SignpostCarouselSm005 = ({
  items,
  primary: { sm5_hide, sm5_section_title }
}) => {
  items = items ? items : [];

  return sm5_hide === 'false' ? (
    <Controller>
      <Scene classToggle="is-visible" triggerHook={1}>
        <section className="Carousel">
          <p className="Carousel-runningTitle">{sm5_section_title}</p>
          <Slider {...settings}>
            {items.map((el, i) => {
              return (
                <div
                  className="Carousel-wrapper"
                  key={el.sm5_title}
                >
                  <div className="Carousel-gradient">
                    <ImageWrap
                      className="Carousel-img"
                      image={el.sm5_image}
                    />
                  </div>
                  <div className="Carousel-textContent">
                    <div>
                      <h3 className="Carousel-title">
                        {el.sm5_title}
                      </h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                          el.sm5_description.html
                        }}
                        className="Carousel-description"
                      />
                      {el.sm5_link && el.sm5_link.uid && (
                        <LinkWrap
                          to={el.sm5_link}
                          rel={el.sm5_link_rel}
                          className="Carousel-button"
                        >
                          {t('Explore More')}
                        </LinkWrap>
                      )}
                    </div>
                  </div>
                  <p className="Carousel-pagination">
                    {i + 1}/{items.length}
                  </p>
                </div>
              );
            })}
          </Slider>
        </section>
      </Scene>
    </Controller>
  ) : null;
};

SignpostCarouselSm005.propTypes = {
  primary: object.isRequired,
  items: array.isRequired
};

export default SignpostCarouselSm005;
