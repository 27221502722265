import React, { Component } from 'react';
import { object, string } from 'prop-types';
import classNames from 'classnames';
import ReactPlayer from 'react-player';
import { isIOS } from 'react-device-detect';
import { ReactComponent as SvgSpeakerOn } from 'svg/isko_icon_video-noise.svg';
import { ReactComponent as SvgSpeakerOff } from 'svg/isko_icon_video-mute.svg';
import ImageWrap from 'components/ImageWrap';

import './styles.scss';

export default class FullWidthVideoEm014 extends Component {
  state = {
    isSoundMuted: true,
    isSvgSpeaker: true,
    isPlaying: false
  };

  static propTypes = {
    primary: object.isRequired,
    uid: string
  };

  _playPause = () => {
    this.setState({ isPlaying: !this.state.isPlaying });
  };

  _onPlay = () => {
    this.setState({ isPlaying: true });
  };

  soundChange = () => {
    this.setState({
      isSvgSpeaker: !this.state.isSvgSpeaker,
      isSoundMuted: !this.state.isSoundMuted
    });
  };

  render() {
    const {
      state: { isSoundMuted, isPlaying, isSvgSpeaker },
      props: {
        primary: { em14_video_link, em14_image },
        uid
      }
    } = this;
    const iframeOptions = {
      width: '100%',
      height: '100%',
      playing: isPlaying,
      loop: true,
      controls: false,
      volume: 1,
      muted: isSoundMuted,
      playsinline: true
    };
    const SvgSpeaker = isSvgSpeaker ? <SvgSpeakerOff /> : <SvgSpeakerOn />;
    const video = em14_video_link ? em14_video_link.embed_url : null;
    return video ? (
      <section className="FullWidthVideoEm014 js-videoSection">
        <div className="FullWidthVideoEm014-box">
          <ReactPlayer
            className="FullWidthVideoEm014-iframe videoPlayer-overlay"
            url={em14_video_link.embed_url}
            {...iframeOptions}
            config={{
              vimeo: {
                playerOptions: {
                  background: true
                }
              }
            }}
            onReady={this._onPlay}
          />
        </div>
        <div className="FullWidthVideoEm014-capBox" />
        <div
          className={classNames('FullWidthVideoEm014-playTriangle', {
            isPlaying: !isPlaying
          })}
          onClick={this._playPause}
        />
        {uid !== 'a-denim-story-with-vogue-italia' && (
          <button
            onClick={this.soundChange}
            className={classNames(
              'CSVideo-muted',
              isIOS && 'CSVideo-muted--forIOS'
            )}
            type="button"
          >
            {SvgSpeaker}
          </button>
        )}
      </section>
    ) : (
      <ImageWrap
        className="FullWidthVideoEm014-image"
        image={em14_image}
      />
    );
  }
}
