import React from 'react';
import { object } from 'prop-types';
import { isMobileOnly, isTablet, isIOS } from 'react-device-detect';
import classNames from 'classnames';
import ReactPlayer from 'react-player';

import { ImageWrap } from 'components';
import { ReactComponent as SvgSpeakerOn } from 'svg/isko_icon_video-noise.svg';
import { ReactComponent as SvgSpeakerOff } from 'svg/isko_icon_video-mute.svg';
import './styles.scss';

class ContentSnippetInSituVideoModuleEm010 extends React.Component {
  static propTypes = {
    primary: object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isSvgSpeaker: true,
      isSoundMute: true,
      isVideoPlaying: false
    };
  }

  soundChange = () => {
    this.setState({
      isSvgSpeaker: !this.state.isSvgSpeaker,
      isSoundMute: !this.state.isSoundMute
    });
  };

  onPlay = () => {
    this.setState({ isVideoPlaying: true });
  };

  onPause = () => {
    this.setState({ isVideoPlaying: false });
  };

  render() {
    const {
      primary: { em10_hide, em10_video_thumbnail_image, em10_video_link }
    } = this.props;
    const video = em10_video_link ? em10_video_link.embed_url : null;
    const { isSoundMute, isSvgSpeaker, isVideoPlaying } = this.state;
    const SvgSpeaker = isSvgSpeaker ? <SvgSpeakerOff /> : <SvgSpeakerOn />;
    const iframeOptions = {
      width: isMobileOnly ? '100vw' : '100%',
      height: isMobileOnly ? '13rem' : isTablet ? '38vh' : '100%',
      loop: true,
      controls: false,
      volume: 1,
      muted: isSoundMute,
      playsinline: true
    };
    return em10_hide === 'false' ? (
      <section className="CSVideo">
        <div className="CSVideo-wrap">
          {isVideoPlaying ? (
            <ReactPlayer
              className="CSVideo-player videoPlayer-overlay"
              onPause={this.onPause}
              url={video}
              {...iframeOptions}
              config={{
                vimeo: {
                  playerOptions: {
                    background: true
                  }
                }
              }}
            />
          ) : (
            <ImageWrap
              className="CSVideo-image"
              image={em10_video_thumbnail_image}
            />
          )}
          {video !== null && (
            <>
              <button
                onClick={this.onPlay}
                className={classNames(
                  'CSVideo-play',
                  isVideoPlaying && 'CSVideo-hideButton'
                )}
                type="button"
              />
              <button
                onClick={this.soundChange}
                className={classNames(
                  'CSVideo-muted',
                  isIOS && 'CSVideo-muted--forIOS'
                )}
                type="button"
              >
                {SvgSpeaker}
              </button>
            </>
          )}
        </div>
      </section>
    ) : null;
  }
}

export default ContentSnippetInSituVideoModuleEm010;
