import React from 'react';
import { string, number, object, func, bool } from 'prop-types';
import { isMobile, isMobileOnly } from 'react-device-detect';
import classNames from 'classnames';
import { Controller, Scene } from 'react-scrollmagic';
import ReactPlayer from 'react-player';
import { CSSTransition } from 'react-transition-group';

import { ImageWrap } from 'components';
import './styles.scss';

export default class ProductCard extends React.Component {
  static propTypes = {
    arrayIndex: number.isRequired,
    stateIndex: number.isRequired,
    videoLoop: bool.isRequired,
    videoUrl: string,
    image: object.isRequired,
    title: string,
    subtitle: string,
    name: string,
    description: object,
    handleMouseEnter: func.isRequired,
    handleMouseLeave: func.isRequired,
    handleTouchStart: func.isRequired,
    handleTouchEnd: func.isRequired,
    handleClick: func.isRequired,
    cardClassName: string,
    featured: bool,
    featuredText: string,
    carousel: bool,
    listing: bool
  };

  static defaultPropsh = {
    featured: false,
    carousel: false,
    listing: false,
    featuredText: '',
    cardClassName: '',
    videoUrl: '',
    title: '',
    name: '',
    subtitle: '',
    description: {}
  };

  constructor(props) {
    super(props);
    this.state = {
      appearElements: true
    };
  }

  onContext = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    const {
      featured,
      featuredText,
      cardClassName,
      arrayIndex,
      stateIndex,
      videoUrl,
      image,
      name,
      description,
      handleMouseEnter,
      handleMouseLeave,
      handleTouchStart,
      handleTouchEnd,
      handleClick,
      videoLoop,
      carousel,
      listing
    } = this.props;
    const { appearElements } = this.state;
    const mobileWidth = featured ? '255vw' : '160vw';
    const tabletWidth = featured ? '180vw' : '75vw';
    const iframeOptions = {
      width: isMobileOnly ? mobileWidth : isMobile ? tabletWidth : '80vw',
      height: isMobile ? '95vh' : '50vmax',
      playing: true,
      loop: true,
      controls: false,
      muted: true,
      playsinline: true
    };
    return (
      <Controller>
        <Scene classToggle="is-visible" triggerHook={1}>
          <figure
            className={classNames('Card', cardClassName, {
              featured: featured
            })}
          >
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
              onContextMenu={this.onContext}
              onClick={handleClick}
            >
              {videoLoop &&
              videoUrl &&
              stateIndex === arrayIndex ? (
                <div
                  className={classNames(
                    featured
                      ? 'Card-featuredVideo'
                      : 'Card-video'
                  )}
                >
                  <div className="Card-block" />
                  <ReactPlayer
                    className={classNames(
                      featured ? 'Card-featuredPlayer' : 'Card-player',
                      'videoPlayer-overlay'
                    )}
                    url={videoUrl}
                    {...iframeOptions}
                    config={{
                      vimeo: {
                        playerOptions: {
                          background: true
                        }
                      }
                    }}
                  />
                </div>
              ) : (
                <div
                  className={classNames(
                    'Card-background',
                    carousel && 'Card-background--carousel'
                  )}
                >
                  <CSSTransition
                    in={appearElements}
                    appear={true}
                    timeout={500}
                    classNames="fade"
                  >
                    <>
                      <div
                        className={classNames(
                          featured
                            ? 'Card-featuredImage'
                            : 'Card-image',
                          videoUrl &&
                          'Card-switchable'
                        )}
                      >
                        {image && (
                          <ImageWrap
                            image={image}
                            className="Card-picture"
                            style={{
                              objectFit: 'cover'
                            }}
                          />
                        )}
                      </div>
                      {carousel && (
                        <div className="Card-imgBgCarousel" />
                      )}
                      {listing && (
                        <div className="Card-imgBgListing" />
                      )}
                    </>
                  </CSSTransition>
                </div>
              )}
            </div>
            <div
              onClick={handleClick}
              className={classNames(
                'Card-supportingText',
                featured && 'Card-supportingText--featured'
              )}
            >
              {featuredText && (
                <p className="Card-featuredText">
                  {featuredText}
                </p>
              )}
              <figcaption
                className={classNames(
                  featured
                    ? 'Card-featuredCaption'
                    : 'Card-caption'
                )}
              >
                {name}
              </figcaption>
              {description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: description.html
                  }}
                  className="Card-description"
                />
              )}
            </div>
          </figure>
        </Scene>
      </Controller>
    );
  }
}
