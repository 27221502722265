import React, { Component } from 'react';
import { string, object } from 'prop-types';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import { Controller, Scene } from 'react-scrollmagic';
import ReactPlayer from 'react-player';
import { CSSTransition } from 'react-transition-group';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { getLocalePrefix } from '../../utils/lang-helper';
import { ImageWrap } from 'components';
import './styles.scss';

export default class CollectionCard extends Component {
  static propTypes = {
    product: string.isRequired,
    description: object,
    heading: string,
    name: string.isRequired,
    copy: object,
    image: object.isRequired,
    videoUrl: string
  };

  static defaultProps = {
    videoUrl: '',
    copy: {},
    description: {},
    heading: ''
  };

  constructor(props) {
    super(props);
    this.state = {
      videoLoop: false,
      appearElements: true
    };
    this.hasBeenTouchedRecently = false;
  }

  handleMouseEnter = () => {
    this.hoverTime = setTimeout(() => {
      !isMobile && this.setState({ videoLoop: true });
    }, 500);
  };

  handleMouseLeave = () => {
    !isMobile && this.setState({ videoLoop: false });
    clearTimeout(this.hoverTime);
  };

  handleClick = url => {
    if (this.hasBeenTouchedRecently) {
      return;
    }
    navigate(getLocalePrefix() + url);
  };

  handleTouchStart = () => {
    this.buttonPressTimer = setTimeout(() => {
      this.hasBeenTouchedRecently = true;
      this.setState({ videoLoop: true });
    }, 500);
  };

  handleTouchEnd = () => {
    this.hasBeenTouchedRecently = false;
    clearTimeout(this.buttonPressTimer);
  };

  onContext = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  componentWillUnmount() {
    clearTimeout(this.buttonPressTimer);
    clearTimeout(this.hoverTime);
  }

  render() {
    const {
      product,
      heading,
      description,
      name,
      copy,
      image,
      videoUrl
    } = this.props;
    const { videoLoop, appearElements } = this.state;
    const iframeOptions = {
      width: isMobile ? '120vw' : '90vw',
      height: isMobileOnly ? '35vh' : isMobile ? '48vh' : '75vh',
      playing: true,
      loop: true,
      controls: false,
      muted: true,
      playsinline: true
    };
    return (
      <Controller>
        <Scene classToggle="is-visible" triggerHook={1}>
          <article className="Collection">
            <div className="Collection-topText">
              <h2 className="Collection-head">{heading}</h2>
              <div
                className="Collection-copy"
                dangerouslySetInnerHTML={{ __html: copy.html }}
              />
            </div>
            <figure
              className="Collection-item"
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onTouchStart={this.handleTouchStart}
              onTouchEnd={this.handleTouchEnd}
              onContextMenu={this.onContext}
              onClick={() => this.handleClick(product)}
            >
              {videoLoop && videoUrl ? (
                <div
                  onClick={() => this.handleClick(product)}
                  className="Collection-video"
                >
                  <div className="Card-block" />
                  <ReactPlayer
                    className="Collection-player"
                    url={videoUrl}
                    {...iframeOptions}
                    config={{
                      vimeo: {
                        playerOptions: {
                          background: true
                        }
                      }
                    }}
                  />
                </div>
              ) : (
                <div className="Collection-imgBg">
                  <CSSTransition
                    in={appearElements}
                    appear={true}
                    timeout={500}
                    classNames="fade"
                  >
                    <ImageWrap
                      className={classNames(
                        'Collection-image',
                        videoUrl &&
                        'Collection-switchable'
                      )}
                      image={image}
                    />
                  </CSSTransition>
                </div>
              )}
            </figure>
            <div
              className="Collection-bottomText"
              onClick={() => this.handleClick(product)}
            >
              <h3 className="Collection-head">{name}</h3>
              <div
                className="Collection-description"
                dangerouslySetInnerHTML={{
                  __html: description.html
                }}
              />
            </div>
          </article>
        </Scene>
      </Controller>
    );
  }
}
