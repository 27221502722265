import React from 'react';
import { string, number, bool, array } from 'prop-types';
import { Formik, Field, Form } from 'formik';
import classNames from 'classnames';
import BirthdayPicker from 'components/BirthdayPicker';
import MySelect from 'components/CustomSelect';
import { t, localizedSlug, getCurrentLocale } from '../../utils/lang-helper';
import { isEmpty } from '../../utils';
import { countries } from '../../utils/options';
import { applicant, contact } from '../../utils/validation';
import './styles.scss';
import { spotlerSubscribe } from '../../utils/spotler-subscribe';
import { bcc } from '../../utils/bcc';

export default class ContactForm extends React.Component {
  static propTypes = {
    index: number.isRequired,
    isAppeared: bool.isRequired,
    stateIndex: number,
    type: string.isRequired,
    clientEmail: string,
    title: string,
    copy: string,
    subtitle: string,
    options: array,
    pageLang: string
  };

  static defaultProps = {
    stateIndex: null,
    title: '',
    clientEmail: '',
    copy: '',
    subtitle: '',
    options: []
  };

  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      submiting: false,
      emptyFields: false,
      emails:
        props.options.length &&
        props.options.map(el => ({
          value: el.cfm3_name_of_contact_area,
          label: el.cfm3_name_of_contact_area,
          init: el.cfm3_contact_email,
          redirectTo: el.cfm3_redirect_to
        })),
      submitFailed: false,
      msg: ''
    };
  }

  emptyFieldHelper = () => {
    this.setState({ emptyFields: true });
  };
  addDetailsFromLinkedIn = () => {
    /**
     * @TODO here goes the logic to pre-fill the form using Linked-In script.
     * Pushing empty so the client can see the form on Staging and then whitelist the URL
     */
  };

  render() {
    const {
      isSubmit,
      submitFailed,
      msg,
      emails,
      submiting,
      emptyFields
    } = this.state;

    const {
      index,
      stateIndex,
      isAppeared,
      type,
      title,
      copy,
      subtitle,
      clientEmail,
      pageLang
    } = this.props;
    const type003 = 'contact_form_with_tabs_cfm003';

    return (
      index === stateIndex &&
      isAppeared && (
        <section className="ContactForm">
          <div
            className={classNames(
              'ContactForm-confirmation',
              !isSubmit && 'ContactForm-none'
            )}
          >
            <h2 className="ContactForm-confirmTitle">
              {t('Thank you')}
            </h2>
            <p className="ContactForm-confirmDesc">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              {t('We will get back to you shortly.')}
            </p>
          </div>
          {title && !isSubmit && (
            <h2 className="ContactForm-title">{title}</h2>
          )}
          {copy && !isSubmit && (
            <div
              className="ContactForm-copy"
              dangerouslySetInnerHTML={{
                __html: copy
              }}
            />
          )}
          {subtitle && !isSubmit && (
            <h3 className="ContactForm-subtitle">{subtitle}</h3>
          )}
          <Formik
            initialValues={
              type === type003
                ? {
                  firstName: '',
                  lastName: '',
                  email: '',
                  address: '',
                  phone: '',
                  file: '',
                  privacyCheck: false,
                  subscribeCheck: false
                }
                : {
                  firstName: '',
                  lastName: '',
                  email: '',
                  privacyCheck: false,
                  subscribeCheck: false
                }
            }
            validationSchema={
              type === type003 ? applicant : contact
            }
            onSubmit={async (val, actions) => {
              this.setState({
                submiting: true,
                emptyFields: false
              });
              if (type === type003) {
                const {
                  firstName,
                  lastName,
                  email,
                  address,
                  phone,
                  date,
                  country,
                  info,
                  file,
                  privacyCheck,
                  subscribeCheck
                } = val;

                let mailData = {
                  subject: `Career Inquiries`,
                  html: `
                    <h2>Applicant Details</h2>
                    <hr/>
                    <p>First name: ${firstName}</p>
                    <p>Last name: ${lastName}</p>
                    <p>Email: ${email}</p>
                    <p>Address: ${address}</p>
                    <p>Phone: ${phone}</p>
                    <p>Date of birthday: ${date}</p>
                    <p>Country of residence: ${country}</p>
                    <p>Privacy Agree: ${privacyCheck ? 'Yes' : 'No'}</p>
                    <p>Subscribe Check: ${subscribeCheck ? 'Yes' : 'No'}</p>
                    <hr/>
                    <p>About the applicant: ${info}</p>
                  `,
                  from_email: email,
                  from_name: firstName + ' ' + lastName,
                  to: [
                    {
                      email: `${clientEmail}`,
                      type: 'to'
                    }
                  ],
                  attachments: [file]
                };

                fetch('https://mandrillapp.com/api/1.0/messages/send.json', {
                  method: 'post',
                  body: JSON.stringify({
                    key: '0K9RSSnf1OxqWsXqaIYZQw',
                    message: mailData
                  })
                }).then(responseData => {
                  if (responseData.ok) {
                    actions.resetForm();
                  } else {
                    this.setState({
                      msg:
                        t('Something was wrong, try again.'),
                      submitFailed: true
                    });
                  }

                  try {
                    window.dataLayer.push({
                      'event': 'formSubmission',
                      'data': {
                        component: 'ContactForm',
                        type: 'type003'
                      }
                    });
                  } catch (e) {
                  }
                });

                // Send user subscription
                let formName = 'contact-form-type003';
                if (subscribeCheck) {
                  spotlerSubscribe(email, firstName, lastName, formName);
                }

                // BCC
                bcc(mailData.subject, mailData.html, email, formName);
              } else {
                const {
                  firstName,
                  lastName,
                  email,
                  contactArea,
                  info,
                  privacyCheck,
                  subscribeCheck
                } = val;
                const contact = emails.filter(
                  e => e.init === contactArea
                );
                const label = contact[0].label;
                const redirectTo = contact[0].redirectTo;

                if (!redirectTo.lang) {
                  redirectTo.lang = getCurrentLocale();
                }

                const mailData = {
                  subject: `General Inquiries - ${label}`,
                  html: `
                    <h2>${t('Inquiry')}</h2>
                    <hr/>
                    <p>${t('First name')}: ${firstName}</p>
                    <p>${t('Last name')}: ${lastName}</p>
                    <p>${t('Email')}: ${email}</p>
                    <p>Privacy Agree: ${privacyCheck ? 'Yes' : 'No'}</p>
                    <p>Subscribe Check: ${subscribeCheck ? 'Yes' : 'No'}</p>
                    <hr/>
                    <p>${t('Message')}: ${info}</p>
                  `,
                  from_email: email,
                  from_name:
                    firstName + ' ' + lastName,
                  to: [
                    {
                      email: `${contactArea}`,
                      type: 'to'
                    }
                  ]
                };

                fetch(
                  'https://mandrillapp.com/api/1.0/messages/send.json',
                  {
                    method: 'post',
                    body: JSON.stringify({
                      key: '0K9RSSnf1OxqWsXqaIYZQw',
                      message: mailData
                    })
                  }
                ).then(responseData => {
                  if (responseData.ok) {
                    actions.resetForm();

                    if (redirectTo) {
                      try {
                        window.dataLayer.push({
                          'event': 'formSubmission',
                          'data': {
                            component: 'ContactForm',
                            type: null
                          },
                          'eventCallback': function() {
                            document.location = localizedSlug(redirectTo);
                          },
                          'eventTimeout': 100
                        });
                      } catch (e) {
                        localizedSlug(redirectTo);
                      }

                      // Alternative redirect
                      setTimeout(() => {
                        document.location = localizedSlug(redirectTo);
                      }, 1000);
                    }
                  } else {
                    this.setState({
                      msg:
                        t('Something was wrong, try again.'),
                      submitFailed: true
                    });
                  }
                });

                // Send user subscription
                let formName = 'contact-form';
                if (subscribeCheck) {
                  spotlerSubscribe(email, firstName, lastName, formName);
                }

                // BCC
                bcc(mailData.subject, mailData.html, email, formName);
              }
            }}
          >
            {({ values, errors }) => (
              <Form
                name="Form"
                id="form"
                className={classNames(
                  isSubmit
                    ? 'ContactForm-none'
                    : 'ContactForm-form'
                )}
              >
                <Field
                  className={classNames(
                    'ContactForm-input',
                    values.firstName &&
                    'ContactForm-imageWithIcon'
                  )}
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder={t('Your name', pageLang)}
                  autoComplete="off"
                  value={values.firstName}
                />
                {errors.firstName && values.firstName && (
                  <div className="ContactForm-fieldError">
                    {errors.firstName}
                  </div>
                )}
                {errors.firstName &&
                  emptyFields &&
                  !values.firstName && (
                    <div className="ContactForm-fieldError">
                      {errors.firstName}
                    </div>
                  )}
                <Field
                  className={classNames(
                    'ContactForm-input',
                    values.lastName &&
                    'ContactForm-imageWithIcon'
                  )}
                  type="text"
                  name="lastName"
                  id="lastName"
                  autoComplete="off"
                  placeholder={t('Your last name', pageLang)}
                  value={values.lastName}
                />
                {errors.lastName && values.lastName && (
                  <div className="ContactForm-fieldError">
                    {errors.lastName}
                  </div>
                )}
                {errors.lastName &&
                  emptyFields &&
                  !values.lastName && (
                    <div className="ContactForm-fieldError">
                      {errors.lastName}
                    </div>
                  )}
                <Field
                  className={classNames(
                    'ContactForm-input',
                    values.email &&
                    'ContactForm-imageWithIcon'
                  )}
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="off"
                  placeholder={t('Your email', pageLang)}
                  value={values.email}
                />
                {errors.email && values.email && (
                  <div className="ContactForm-fieldError">
                    {errors.email}
                  </div>
                )}
                {errors.email &&
                  emptyFields &&
                  !values.email && (
                    <div className="ContactForm-fieldError">
                      {errors.email}
                    </div>
                  )}
                {type === type003 && (
                  <>
                    <Field
                      className={classNames(
                        'ContactForm-input',
                        values.address &&
                        'ContactForm-imageWithIcon'
                      )}
                      type="text"
                      name="address"
                      autoComplete="off"
                      placeholder={t('Your address', pageLang)}
                      value={values.address}
                    />
                    {errors.address && values.address && (
                      <div className="ContactForm-fieldError">
                        {errors.address}
                      </div>
                    )}
                    {errors.address &&
                      emptyFields &&
                      !values.address && (
                        <div className="ContactForm-fieldError">
                          {errors.address}
                        </div>
                      )}
                    <Field
                      className={classNames(
                        'ContactForm-input',
                        values.phone &&
                        'ContactForm-imageWithIcon'
                      )}
                      type="tel"
                      maxLength="16"
                      minLength="6"
                      name="phone"
                      id="phone"
                      autoComplete="off"
                      placeholder={t('Your mobile', pageLang)}
                      value={values.phone}
                    />
                    {errors.phone && values.phone && (
                      <div className="ContactForm-fieldError">
                        {errors.phone}
                      </div>
                    )}
                    {errors.phone &&
                      emptyFields &&
                      !values.phone && (
                        <div className="ContactForm-fieldError">
                          {errors.phone}
                        </div>
                      )}
                    <h5 className="ContactForm-selectHeading">
                      {t('Date of birth')}
                    </h5>
                    <Field
                      name="date"
                      component={BirthdayPicker}
                    />
                    {errors.date && emptyFields && (
                      <div className="ContactForm-fieldError">
                        {errors.date}
                      </div>
                    )}
                    <h5 className="ContactForm-selectHeading">
                      {t('Country')}
                    </h5>
                    <Field
                      name="country"
                      id="country"
                      type="applicant"
                      options={countries}
                      component={MySelect}
                      placeholder={t('Select country of residence', pageLang)}
                    />
                    {errors.country && emptyFields && (
                      <div className="ContactForm-fieldError">
                        {errors.country}
                      </div>
                    )}
                  </>
                )}
                {type !== type003 && (
                  <>
                    <Field
                      name="contactArea"
                      options={emails}
                      type="contact"
                      component={MySelect}
                      placeholder={t('Contact Area', pageLang)}
                      className="ContactForm-contactArea"
                    />
                    {errors.contactArea && emptyFields && (
                      <div className="ContactForm-fieldError">
                        {errors.contactArea}
                      </div>
                    )}
                  </>
                )}
                {type === type003 && (
                  <>
                    <h5 className="ContactForm-selectHeading">
                      {t('Upload CV')}
                    </h5>
                    <div className="ContactForm-CV-upload-wrapper">
                      <div>
                        <label
                          htmlFor="file"
                          className="ContactForm-button CV-button upload"
                        >
                          {t('Choose File')}
                        </label>
                        <input
                          type="file"
                          name="file"
                          id="file"
                          className="ContactForm-button hidden"
                          autoComplete="off"
                          onChange={event => {
                            let file = event.currentTarget.files[0];
                            values.file = {
                              type: file.type,
                              name: file.name
                            };
                            let reader = new FileReader();
                            reader.readAsArrayBuffer(file);
                            reader.onloadend = function() {
                              let binary = '';
                              let bytes = new Uint8Array(
                                reader.result
                              );
                              let len = bytes.byteLength;
                              for (let i = 0; i < len; i++) {
                                binary += String.fromCharCode(
                                  bytes[i]
                                );
                              }
                              values.file.content = window.btoa(
                                binary
                              );
                            };
                          }}
                        />
                        <span className="ContactForm-text note">
                            {t('Accepted File Types')}:
                            <br />
                            MS Word, PDF, .TXT OR .RTF
                        </span>
                      </div>
                      <span className="ContactForm-text">
                          {t('or')}
                      </span>
                      <script
                        src="https://www.linkedin.com/autofill/js/autofill.js"
                        type="text/javascript"
                        async
                      />
                      <script
                        type="IN/Form2"
                        data-form="form"
                        data-field-firstname="firstName"
                        data-field-lastname="lastName"
                        data-field-phone="phone"
                        data-field-email="email"
                      />
                      <button
                        className="ContactForm-button CV-button linked-in"
                        type="button"
                        onClick={
                          this.addDetailsFromLinkedIn
                        }
                      >
                        {t('Add details from: Linkedin')}
                      </button>
                    </div>
                  </>
                )}
                <h5 className="ContactForm-selectHeading">
                  {type === type003
                    ? t('CV or additional info')
                    : t('Your message')}
                </h5>
                <Field
                  name="info"
                  value={values.info}
                  render={({ field }) => (
                    <textarea
                      {...field}
                      className="ContactForm-textarea"
                      rows="10"
                    >{values.info}</textarea>
                  )}
                />
                {errors.info && emptyFields && (
                  <div className="ContactForm-fieldError">
                    {errors.info}
                  </div>
                )}

                <label className="custom-check">
                  {t('agree form text')}
                  <Field type="checkbox" name="privacyCheck" required={true} />
                  <span className="checkmark" />
                </label>

                <label className="custom-check">
                  {t('subscribe form text')}
                  <Field type="checkbox" name="subscribeCheck" />
                  <span className="checkmark" />
                </label>

                <button
                  className="ContactForm-button"
                  type="submit"
                  disabled={
                    Object.values(values).every(v => v === '')
                    || isEmpty(errors)
                    || !values.privacyCheck
                  }
                >
                  {t('Submit')}
                </button>
                <div
                  onClick={this.emptyFieldHelper}
                  className={classNames(
                    'ContactForm-coverButton',
                    {
                      'ContactForm-coverButton--filled': !isEmpty(
                        errors
                      )
                    }
                  )}
                />
                {!isSubmit && submiting && (
                  <p className="ContactForm-fieldError">
                    {t('Please wait, data processing...')}
                  </p>
                )}
                {submitFailed && (
                  <p className="ContactForm-fieldError">
                    {msg}
                  </p>
                )}
              </Form>
            )}
          </Formik>
        </section>
      )
    );
  }
}
