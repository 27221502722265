import React from 'react';
import { object, array } from 'prop-types';
import { Controller, Scene } from 'react-scrollmagic';

import { ImageWrap, Quote } from 'components';
import './styles.scss';

const MoodBoardModuleEm007 = ({
  primary: { em7_hide, em7_message, em7_name_position },
  items
}) => {
  return em7_hide === 'false' ? (
    <section className="LSMoodboard">
      {items &&
        items.map((el, i) => {
          const img = `LSMoodboard-image${i}`;
          const imgWrap = `LSMoodboard-imageWrap${i}`;
          const index = items.length - 1;
          if (i === 4) {
            return;
          }
          return (
            <Controller key={el.em7_images.url}>
              <Scene classToggle="is-visible" triggerHook={1}>
                <figure className={imgWrap}>
                  <ImageWrap
                    className={img}
                    image={el.em7_images}
                  />
                  {em7_message &&
                    (i === index - 1 ? (
                      <Quote
                        classWrap="LSMoodboard-quoteWrap"
                        classQuote="LSMoodboard-quote"
                        classAuthor="LSMoodboard-sign"
                        quoteData={em7_message.html}
                        quoteAuthor={em7_name_position}
                      />
                    ) : index === 0 ? (
                      <Quote
                        classWrap="LSMoodboard-quoteWrap"
                        classQuote="LSMoodboard-quote"
                        classAuthor="LSMoodboard-sign"
                        quoteData={em7_message.html}
                        quoteAuthor={em7_name_position}
                      />
                    ) : null)}
                </figure>
              </Scene>
            </Controller>
          );
        })}
    </section>
  ) : null;
};

MoodBoardModuleEm007.propTypes = {
  primary: object.isRequired,
  items: array.isRequired
};

export default MoodBoardModuleEm007;
