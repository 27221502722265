import React from 'react';
import { string } from 'prop-types';

const Quote = ({
  classWrap,
  classQuote,
  classAuthor,
  quoteData,
  quoteAuthor
}) => (
  <div className={classWrap}>
    <blockquote
      className={classQuote}
      dangerouslySetInnerHTML={{
        __html: quoteData
      }}
    />
    <p className={classAuthor}>{quoteAuthor}</p>
  </div>
);

Quote.propTypes = {
  classWrap: string.isRequired,
  classQuote: string.isRequired,
  classAuthor: string.isRequired,
  quoteData: string,
  quoteAuthor: string
};

Quote.defaultProps = {
  quoteData: '',
  quoteAuthor: ''
};

export default Quote;
