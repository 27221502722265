import React, { Component } from 'react';
import { object, array } from 'prop-types';
import classNames from 'classnames';

import { ImageWrap, LinkWrap } from 'components';
import './styles.scss';

class MultipleSignpostModuleSm006 extends Component {
  static propTypes = {
    primary: object.isRequired,
    items: array.isRequired
  };

  render() {
    const {
      primary: { sm6_hide },
      items
    } = this.props;
    return sm6_hide === 'false' ? (
      <section className="DenimEpilogue-wrapper">
        {items.map((el, i) => (
          <LinkWrap
            key={el.sm6_link_name}
            className={classNames('DenimEpilogue', {
              // low: i + 1 === items.length
            })}
            to={el.sm6_link}
            rel={el.sm6_link_rel}
          >
            <ImageWrap
              className="DenimEpilogue-img"
              image={el.sm6_image}
            />
            <h3 className="DenimEpilogue-title">
              {el.sm6_link_name}
            </h3>
            <p className="DenimEpilogue-description">
              {el.sm6_link_description}
            </p>
          </LinkWrap>
        ))}
      </section>
    ) : null;
  }
}

export default MultipleSignpostModuleSm006;
