import * as yup from 'yup';
import { t } from './lang-helper';

export const applicant = yup.object().shape({
  firstName: yup
    .string()
    .required(t('First name is required'))
    .min(2, t('First name must be at least 2 characters!'))
    .max(40, t('First name must be less 40 characters!'))
    .matches(
      /^[a-z -]+$/i,
      t('First name must contain only upper and lower case characters')
    ),
  lastName: yup
    .string()
    .required(t('Last name is required'))
    .min(2, t('Last name must be at least 2 characters!'))
    .max(40, t('Last name must be less 40 characters!'))
    .matches(
      /^[a-z -]+$/i,
      t('Last name must contain only upper and lower case characters')
    ),
  email: yup
    .string()
    .email(t('Invalid email'))
    .required(t('Email is required')),
  address: yup.string().required(t('Address is required!')),
  phone: yup
    .string()
    .required(t('Phone number is required!'))
    .matches(
      /^[+{1}][0-9]+$/,
      t('Phone number must contain "+" and only numbers')
    )
    .min(6, t('Phone number must be at least 6 characters!'))
    .max(16, t('Phone number must be less 16 characters!')),
  date: yup.string().required(t('Date of birth is required!')),
  country: yup.string().required(t('Country is required!')),
  info: yup.string().required(t('This field is required!'))
});

export const contact = yup.object().shape({
  firstName: yup
    .string()
    .required(t('First name is required'))
    .min(2, t('First name must be at least 2 characters!'))
    .max(40, t('First name must be less 40 characters!'))
    .matches(
      /^[a-z -]+$/i,
      t('First name must contain only upper and lower case characters')
    ),
  lastName: yup
    .string()
    .required(t('Last name is required'))
    .min(2, t('Last name must be at least 2 characters!'))
    .max(40, t('Last name must be less 40 characters!'))
    .matches(
      /^[a-z -]+$/i,
      t('Last name must contain only upper and lower case characters')
    ),
  email: yup
    .string()
    .email(t('Invalid email'))
    .required(t('Email is required')),
  info: yup.string().required(t('This field is required!')),
  contactArea: yup.string().required(t('Please select contact area'))
});

export const interestedIn = yup.object().shape({
  firstName: yup
    .string()
    .min(2, t('First name must be at least 2 characters!'))
    .max(40, t('First name must be less 40 characters!'))
    .matches(
      /^[a-z -]+$/i,
      t('First name must contain only upper and lower case characters')
    ),
  lastName: yup
    .string()
    .min(2, t('Last name must be at least 2 characters!'))
    .max(40, t('Last name must be less 40 characters!'))
    .matches(
      /^[a-z -]+$/i,
      t('Last name must contain only upper and lower case characters')
    ),
  email: yup
    .string()
    .email(t('Invalid email'))
    .required(t('Email is required')),
  company: yup
    .string()
    .required(t('Company is required'))
    .min(2, t('Company must be at least 2 characters!'))
    .max(40, t('Company must be less 40 characters!')),
  country: yup
    .string()
    .required(t('Country is required!'))
    .min(2, t('Country must be at least 2 characters!'))
    .max(40, t('Country must be less 60 characters!'))
});

export const enquiry = yup.object().shape({
  firstName: yup
    .string()
    .required(t('First name is required'))
    .min(2, t('First name must be at least 2 characters!'))
    .max(40, t('First name must be less 40 characters!'))
    .matches(
      /^[a-z -]+$/i,
      t('First name must contain only upper and lower case characters')
    ),
  lastName: yup
    .string()
    .required(t('Last name is required'))
    .min(2, t('Last name must be at least 2 characters!'))
    .max(40, t('Last name must be less 40 characters!'))
    .matches(
      /^[a-z -]+$/i,
      t('Last name must contain only upper and lower case characters')
    ),
  email: yup
    .string()
    .email(t('Invalid email'))
    .required(t('Email is required'))
});

export const newsletter = yup.object().shape({
  email: yup
    .string()
    .email(t('Invalid email'))
    .required(t('Email is required'))
});

export const quickContact = yup.object().shape({
  interestedIn: yup.string().required(t('This field is required!')),
  firstName: yup
    .string()
    .required(t('First name is required'))
    .min(2, t('First name must be at least 2 characters!'))
    .max(40, t('First name must be less 40 characters!'))
    .matches(
      /^[a-z -]+$/i,
      t('First name must contain only upper and lower case characters')
    ),
  lastName: yup
    .string()
    .required(t('Last name is required'))
    .min(2, t('Last name must be at least 2 characters!'))
    .max(40, t('Last name must be less 40 characters!'))
    .matches(
      /^[a-z -]+$/i,
      t('Last name must contain only upper and lower case characters')
    ),
  email: yup
    .string()
    .email(t('Invalid email'))
    .required(t('Email is required')),
  interestedSummary: yup.string().required(t('This field is required!'))
});
