import React from 'react';
import { string } from 'prop-types';
import { Link } from 'gatsby-plugin-modal-routing';

import './styles.scss';

const MoreDetailsButton = ({ content, link }) => {
  return (
    <Link
      title={`More detail`}
      className="MoreDetailsButton"
      to={link}
      asModal
    >
      <p className="MoreDetailsButton-text">{content}</p>
    </Link>
  );
};

MoreDetailsButton.propTypes = {
  content: string.isRequired,
  link: string.isRequired
};

export default MoreDetailsButton;
