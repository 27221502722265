import React from 'react';
import { object, string, number } from 'prop-types';
import { Controller, Scene } from 'react-scrollmagic';
import classNames from 'classnames';
import './styles.scss';

const ContentSnippetFullWidthCopy = ({ primary, type, index }) => (
  <Controller>
    <Scene classToggle="is-visible" triggerHook={1}>
      <section
        className={classNames(
          'ContentEm015',
          type === 'contact' && 'ContentEm015--contact',
          type === 't13_faq' && 'ContentEm015--faq'
        )}
      >
        {type === 't13_faq' && (
          <span className="ContentEm015-index">{index}.</span>
        )}
        {primary.em15_title && (
          <h3
            className={classNames(
              'ContentEm015-title',
              type === 't13_faq' && 'ContentEm015-title--faq'
            )}
          >
            {primary.em15_title.text}
          </h3>
        )}
        <div
          className={classNames(
            'ContentEm015-content',
            type === 't13_faq' && 'ContentEm015-content--faq'
          )}
          dangerouslySetInnerHTML={{ __html: primary.content.html }}
        />
      </section>
    </Scene>
  </Controller>
);

ContentSnippetFullWidthCopy.propTypes = {
  primary: object.isRequired,
  type: string,
  index: number
};

ContentSnippetFullWidthCopy.defaultProps = {
  type: '',
  index: null
};

export default ContentSnippetFullWidthCopy;
