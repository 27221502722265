import React from 'react';
import { array, string } from 'prop-types';
import TabsLayout from 'components/TabsLayout';
import Tab from 'components/Tab';
import ContactForm from 'components/ContactForm';
import RegionalRepresentatives from 'components/RegionalRepresentatives';

export default class ContactFormWithTabsCfm003 extends React.Component {
  static propTypes = {
    items: array.isRequired,
    pageLang: string
  };

  constructor(props) {
    super(props);
    this.state = {
      index: props.items.findIndex(
        el => el.primary.cfm3_checked === 'true'
      ),
      isAppeared: props.items.some(
        el => el.primary.cfm3_checked === 'true'
      )
    };
  }

  checkedHandler = index => {
    if (index !== this.state.index && this.state.isAppeared === true) {
      this.setState({ index });
    } else if (index != this.state.index) {
      this.setState({ index, isAppeared: !this.state.isAppeared });
    }
  };

  mapAll = items => {
    const { index, isAppeared } = this.state;
    const { pageLang } = this.props;

    return items.map((el, i) => (
      <React.Fragment key={el.id}>
        <Tab
          hide={el.primary.cfm3_hide}
          index={i}
          stateIndex={index}
          isAppeared={isAppeared}
          title={el.primary.cfm3_tab_title.text}
          checked={el.primary.cfm3_checked}
          checkedHandler={this.checkedHandler}
        />
        {el.slice_type === 'contact_form_with_tabs_cfm0031' ? (
          <ContactForm
            pageLang={pageLang}
            index={i}
            stateIndex={index}
            isAppeared={isAppeared}
            type={el.slice_type}
            options={el.items}
          />
        ) : el.slice_type === 'contact_form_with_tabs_cfm003' ? (
          <ContactForm
            pageLang={pageLang}
            index={i}
            stateIndex={index}
            isAppeared={isAppeared}
            type={el.slice_type}
            clientEmail={el.primary.cfm3_email_to_receive}
            title={el.primary.cfm3_form_title.text}
            copy={el.primary.cfm3_form_copy.html}
            subtitle={el.primary.cfm3_form_subtitle}
          />
        ) : el.slice_type === 'regional_representatives_cfm003' ? (
          <RegionalRepresentatives
            pageLang={pageLang}
            index={i}
            stateIndex={index}
            isAppeared={isAppeared}
            items={el.items}
            options={el.options}
          />
        ) : null}
      </React.Fragment>
    ));
  };

  mapTabs = items => {
    const { index, isAppeared } = this.state;
    return items.map((el, i) => (
      <Tab
        key={el.id}
        hide={el.primary.cfm3_hide}
        index={i}
        stateIndex={index}
        isAppeared={isAppeared}
        title={el.primary.cfm3_tab_title.text}
        checked={el.primary.cfm3_checked}
        checkedHandler={this.checkedHandler}
      />
    ));
  };

  mapContent = items => {
    const { index, isAppeared } = this.state;
    const { pageLang } = this.props;

    return items.map((el, i) => (
      <React.Fragment key={el.id}>
        {el.slice_type === 'contact_form_with_tabs_cfm0031' ? (
          <ContactForm
            pageLang={pageLang}
            index={i}
            stateIndex={index}
            isAppeared={isAppeared}
            type={el.slice_type}
            options={el.items}
          />
        ) : el.slice_type === 'contact_form_with_tabs_cfm003' ? (
          <ContactForm
            pageLang={pageLang}
            index={i}
            stateIndex={index}
            isAppeared={isAppeared}
            type={el.slice_type}
            clientEmail={el.primary.cfm3_email_to_receive}
            title={el.primary.cfm3_form_title.text}
            copy={el.primary.cfm3_form_copy.html}
            subtitle={el.primary.cfm3_form_subtitle}
          />
        ) : el.slice_type === 'regional_representatives_cfm003' ? (
          <RegionalRepresentatives
            index={i}
            stateIndex={index}
            isAppeared={isAppeared}
            items={el.items}
            options={el.options}
          />
        ) : null}
      </React.Fragment>
    ));
  };

  render() {
    const { items } = this.props;
    return (
      <TabsLayout
        mapAll={() => this.mapAll(items)}
        mapTabs={() => this.mapTabs(items)}
        mapContent={() => this.mapContent(items)}
      />
    );
  }
}
