import React from 'react';
import { object } from 'prop-types';
import classNames from 'classnames';
import { Controller, Scene } from 'react-scrollmagic';
import { isIOS } from 'react-device-detect';
import { throttle } from 'lodash';
import ReactPlayer from 'react-player';
import { LinkWrap } from 'components';

import { ReactComponent as SvgSpeakerOn } from 'svg/isko_icon_video-noise.svg';
import { ReactComponent as SvgSpeakerOff } from 'svg/isko_icon_video-mute.svg';

import { ImageWrap } from 'components';
import './styles.scss';

export default class ContentSnippetEm013 extends React.PureComponent {
  static propTypes = {
    primary: object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isSvgSpeaker: true,
      isSoundMute: true,
      isPlaying: false,
      width: 1025
    };
    this.node = React.createRef();
    this.throttleScroll = throttle(this.turnOnOff, 400, {
      trailing: false
    });
  }

  handleWindowSizeChange = () => {
    this.setState({
      width: typeof window !== 'undefined' ? window.innerWidth : 0
    });
  };

  soundChange = () => {
    this.setState({
      isSvgSpeaker: !this.state.isSvgSpeaker,
      isSoundMute: !this.state.isSoundMute
    });
  };

  componentDidMount() {
    const {
      primary: { em13_main_video_link }
    } = this.props;
    const video = em13_main_video_link
      ? em13_main_video_link.embed_url
      : null;
    if (typeof window !== 'undefined') {
      video && window.addEventListener('scroll', this.throttleScroll);
      window.addEventListener('load', this.handleWindowSizeChange);
      window.addEventListener('resize', this.handleWindowSizeChange);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.throttleScroll);
      window.removeEventListener('load', this.handleWindowSizeChange);
      window.removeEventListener('resize', this.handleWindowSizeChange);
    }
    this.node = null;
  }

  turnOnOff = () => {
    if (!this.node) {
      return;
    }
    if (this.node.current.getBoundingClientRect().bottom < 0) {
      return this.setState({ isPlaying: false });
    } else if (this.node.current.getBoundingClientRect().top - 1100 < 0) {
      return this.setState({ isPlaying: true });
    } else if (this.node.current.getBoundingClientRect().top - 1100 > 0) {
      return this.setState({ isPlaying: false });
    }
  };

  supportMedia = () => {
    const {
      primary: {
        em13_supporting_image,
        em13_main_video_link,
        em13_section_style
      }
    } = this.props;
    const { isSoundMute, isSvgSpeaker } = this.state;
    const SvgSpeaker = isSvgSpeaker ? <SvgSpeakerOff /> : <SvgSpeakerOn />;
    const iframeOptions = {
      width: '100%',
      height: '100%',
      playing: this.state.isPlaying,
      loop: true,
      controls: false,
      volume: 1,
      muted: isSoundMute,
      playsinline: true
    };
    const video = em13_main_video_link
      ? em13_main_video_link.embed_url
      : null;
    return (
      (em13_supporting_image || em13_main_video_link) &&
      em13_section_style !== 'Image + Copy' && (
        <div
          className={classNames(
            'CSVWithCopy-supportImg',
            em13_section_style === 'Copy + Image + Image'
              ? 'CSVWithCopy-supportImg--first'
              : 'CSVWithCopy-supportImg--last'
          )}
        >
          {video ? (
            <>
              <ReactPlayer
                className="CSVWithCopy-mainVideo videoPlayer-overlay"
                url={video}
                {...iframeOptions}
                config={{
                  vimeo: {
                    playerOptions: {
                      background: true
                    }
                  }
                }}
              />
              <button
                onClick={this.soundChange}
                className={classNames(
                  'CSVideo-muted',
                  isIOS && 'CSVideo-muted--forIOS'
                )}
                type="button"
              >
                {SvgSpeaker}
              </button>
            </>
          ) : (
            <ImageWrap image={em13_supporting_image} />
          )}
        </div>
      )
    );
  };

  minMatching = () => {
    if (typeof window !== 'undefined') {
      return (
        window.matchMedia('(min-width: 1024px)').matches &&
        this.supportMedia()
      );
    }
  };

  maxMatching = () => {
    if (typeof window !== 'undefined') {
      return (
        window.matchMedia('(max-width: 1024px)').matches &&
        this.supportMedia()
      );
    }
  };

  render() {
    const {
      primary: {
        em13_hide,
        em13_section_style,
        em13_title,
        em13_copy,
        em13_highlight_background,
        em13_image_video_thumb,
        em13_main_video_link,
        em13_supporting_image,
        em13_highlighted_text,
        em13_text,
        em13_link,
        em13_link_rel
      }
    } = this.props;
    const { width } = this.state;
    const isMobile = width < 1024;
    return em13_hide === 'false' ? (
      <section ref={this.node} className="CSVWithCopy">
        <Controller>
          <Scene classToggle="is-visible" triggerHook={1}>
            <figure
              className={classNames(
                'CSVWithCopy-wrap',
                em13_section_style === 'Copy + Image + Image'
                  ? 'CSVWithCopy-wrap--first'
                  : 'CSVWithCopy-wrap--last',
                {
                  highlightWithOneImage:
                    em13_highlight_background === 'Yes' &&
                    em13_section_style === 'Image + Copy'
                }
              )}
            >
              {(em13_link || em13_title.text) && (
                <div className="CSVWithCopy-caption">
                  {em13_link && (
                    <LinkWrap
                      className={classNames(
                        'rotated-link',
                        em13_section_style ===
                        'Copy + Image + Image'
                          ? 'CSVWithCopy-caption--first'
                          : 'CSVWithCopy-caption--last',
                        {
                          oneImage:
                            em13_section_style ===
                            'Image + Copy'
                        }
                      )}
                      to={em13_link}
                      rel={em13_link_rel}
                    >
                                            <span className="CSVWithCopy-captionHighlightedText">
                                                {em13_highlighted_text.text}
                                            </span>
                      {` `}
                      <span className="CSVWithCopy-captionText">
                                                {em13_text.text}
                                            </span>
                    </LinkWrap>
                  )}
                  {em13_title.text &&
                    em13_highlight_background === 'No' &&
                    em13_section_style ===
                    'Image + Copy' && (
                      <h4
                        className={classNames(
                          'CSVWithCopy-title',
                          {
                            topPosition:
                              em13_highlight_background ===
                              'No'
                          }
                        )}
                      >
                        {em13_title.text}
                      </h4>
                    )}
                </div>
              )}
              {(em13_image_video_thumb ||
                em13_main_video_link) && (
                <div
                  className={classNames(
                    'CSVWithCopy-mainImg',
                    em13_section_style ===
                    'Copy + Image + Image'
                      ? 'CSVWithCopy-mainImg--first'
                      : 'CSVWithCopy-mainImg--last',
                    {
                      withHighlight:
                        em13_highlight_background ===
                        'Yes' &&
                        em13_section_style ===
                        'Image + Copy'
                    }
                  )}
                >
                  <ImageWrap image={em13_image_video_thumb} />
                </div>
              )}
              <div
                className={classNames(
                  'CSVWithCopy-supportSection',
                  em13_section_style ===
                  'Copy + Image + Image'
                    ? 'CSVWithCopy-supportSection--first'
                    : 'CSVWithCopy-supportSection--last'
                )}
              >
                {em13_section_style === 'Copy + Image + Image'
                  ? this.minMatching()
                  : em13_section_style ===
                  'Image + Image + Copy' &&
                  this.supportMedia()}
                <figcaption
                  className={classNames(
                    'CSVWithCopy-textWrap',
                    em13_section_style ===
                    'Copy + Image + Image'
                      ? 'CSVWithCopy-textWrap--first'
                      : 'CSVWithCopy-textWrap--last',
                    {
                      withOutSuppImg:
                        !em13_supporting_image ||
                        em13_section_style ===
                        'Image + Copy'
                    },
                    {
                      highlightBackground:
                        isMobile &&
                        em13_highlight_background ===
                        'Yes' &&
                        em13_section_style ===
                        'Image + Copy'
                    }
                  )}
                >
                  {em13_title.text &&
                    (em13_highlight_background === 'Yes' ||
                      em13_section_style !==
                      'Image + Copy') && (
                      <h4 className="CSVWithCopy-title">
                        {em13_title.text}
                      </h4>
                    )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        em13_copy.html ? (
                          em13_copy.html
                            .toString()
                            .includes('WEB+SPREADS.pdf')
                            ?
                            em13_copy.html
                              .toString()
                              .replace(
                                new RegExp('<a', 'g'),
                                '<a onclick="try{ dataLayer.push({event: \'Sust Summary Download\'}) } catch(e) {}" target="_blank"'
                              )
                            :
                            em13_copy.html
                              .toString()
                              .replace(
                                new RegExp('<a', 'g'),
                                '<a target="_blank"'
                              )
                        ) : ''
                    }}
                    className="CSVWithCopy-copy"
                  />
                  {em13_highlight_background === 'Yes' &&
                    em13_section_style ===
                    'Image + Copy' && (
                      <div className="CSVWithCopy-background" />
                    )}
                </figcaption>
              </div>
              {em13_section_style === 'Copy + Image + Image' &&
                this.maxMatching()}
            </figure>
          </Scene>
        </Controller>
      </section>
    ) : null;
  }
}
