const CryptoJS = require('crypto-js');
const OAuth = require('oauth-1.0a');

export async function spotlerSubscribe(email, firstName, lastName, formName) {
  const authKey = 'rQRpCeCDhcebCzuAQvBffKDDTzLqxkX7';
  const authSecret = 'znHr3bcbcBYfijf4InpWU8U8Z4mF2hE2';
  const groupEncryptedId = '2fLK8euFPj';

  // Log event
  try {
    dataLayer.push({
      event: 'newsletter_subscribe',
      data: {
        source: formName
      }
    });
  } catch (e) {
    // do nothing
  }

  // External id
  let externalId = 'w';
  for (let i = 0; i < email.length; i++) {
    externalId += Number(Math.abs(email.charCodeAt(i) - 64));
  }

  // Contact add request
  const contactUrl = 'https://restapi.mailplus.nl/integrationservice/contact';
  await fetch(contactUrl, {
    'headers': {
      'authorization': oAuthHeader(contactUrl, 'post', authKey, authSecret).Authorization,
      'content-type': 'application/json'
    },
    'method': 'POST',
    'body': JSON.stringify({
      'update': true,
      'purge': false,
      'contact': {
        'testGroup': false,
        'temporary': false,
        'externalId': String(externalId),
        'properties': {
          'freeField3': formName,
          'email': email,
          ...(firstName ? { firstName } : {}),
          ...(lastName ? { lastName } : {}),
          'permissions': [
            {
              'bit': 1,
              'enabled': true
            }
          ]
        }
      }
    })
  });

  // Group add request
  let groupUrl = `https://restapi.mailplus.nl/integrationservice/campaign/trigger/${groupEncryptedId}`;
  await fetch(groupUrl, {
    'headers': {
      'authorization': oAuthHeader(groupUrl, 'post', authKey, authSecret).Authorization,
      'content-type': 'application/json'
    },
    'method': 'POST',
    'body': JSON.stringify({
      'externalContactId': String(externalId),
      'campaignFields': [
        // {
        //   'subscribedForm': subscribedForm
        // }
      ]
    })
  });
}

function oAuthHeader(url, method, session, token) {
  let oauthObject = new OAuth({
    consumer: {
      key: session,
      secret: token
    },
    signature_method: 'HMAC-SHA1',
    nonce_length: 6,
    version: '1.0',
    hash_function: function(baseString, key) {
      return CryptoJS.HmacSHA1(baseString, key).toString(CryptoJS.enc.Base64);
    }
  });

  return oauthObject.toHeader(oauthObject.authorize({ url: url, method: method }));
}
