import React from 'react';
import { string, array, number, bool } from 'prop-types';
import AccordionSection from 'components/AccordionSection';

export default class Accordion extends React.Component {
  static propTypes = {
    items: array.isRequired,
    hide: string.isRequired,
    index: number.isRequired,
    isAppeared: bool.isRequired,
    stateIndex: number
  };

  static defaultProps = {
    stateIndex: null
  };

  state = {
    sectionIndex: 0,
    isOpened: true
  };

  openHandler = idx => {
    const { sectionIndex, isOpened } = this.state;
    if (idx !== sectionIndex && isOpened === true) {
      this.setState({ sectionIndex: idx });
    } else if (idx === sectionIndex) {
      this.setState({ isOpened: !isOpened });
    } else {
      this.setState({ sectionIndex: idx, isOpened: !isOpened });
    }
  };

  render() {
    const { items, hide, index, stateIndex, isAppeared } = this.props;
    const { isOpened, sectionIndex } = this.state;
    return hide === 'false'
      ? index === stateIndex && isAppeared && (
      <div>
        {items.map((el, i) => (
          <AccordionSection
            key={i}
            title={el.em21_section_title.text}
            text={el.em21_section_content.html}
            linkType={
              el.em21_route_download_link
                ? el.em21_route_download_link
                : ''
            }
            link={el.em21_link ? el.em21_link : null}
            linkName={
              el.em21_link_name
                ? el.em21_link_name.text
                : ''
            }
            image={el.em21_image}
            index={i}
            sectionIndex={sectionIndex}
            openHandler={this.openHandler}
            isOpened={isOpened}
          />
        ))}
      </div>
    )
      : null;
  }
}
