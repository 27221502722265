import React from 'react';
import { oneOfType, node, string, arrayOf } from 'prop-types';
import { Layout, ModalPage } from 'components';

const ArticleDetailRender = ({ locationKey, pageLang, children, newsletter }) =>
  locationKey === 'initial' ? (
    <Layout pageLang={pageLang} newsletter={newsletter}>{children}</Layout>
  ) : (
    <ModalPage pageLang={pageLang} newsletter={newsletter}>{children}</ModalPage>
  );

ArticleDetailRender.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  locationKey: string.isRequired
};

export default ArticleDetailRender;
