import React from 'react';
import { object } from 'prop-types';

import './styles.scss';

const CopyOnlyHighlightsModuleEm006 = ({
  primary: { em6_title, em6_description, em6_hide }
}) => {
  return em6_hide === 'false' ? (
    <article className="LSHighlightArticle">
      {em6_title && (
        <h2 className="LSHighlightArticle-title">{em6_title}</h2>
      )}
      {em6_description && (
        <div
          className="LSHighlightArticle-content"
          dangerouslySetInnerHTML={{
            __html: em6_description.html
          }}
        />
      )}
    </article>
  ) : null;
};

CopyOnlyHighlightsModuleEm006.propTypes = {
  primary: object.isRequired
};

export default CopyOnlyHighlightsModuleEm006;
