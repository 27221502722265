import React from 'react';
import { array } from 'prop-types';
import TabsLayout from 'components/TabsLayout';
import Tab from 'components/Tab';
import Accordion from 'components/Accordion';

export default class AccordionWithTabsEm021 extends React.Component {
  static propTypes = {
    items: array.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      index: props.items.findIndex(
        el => el.primary.em21_checked === 'true'
      ),
      isAppeared: props.items.some(
        el => el.primary.em21_checked === 'true'
      )
    };
  }

  checkedHandler = index => {
    if (index !== this.state.index && this.state.isAppeared === true) {
      this.setState({ index });
    } else if (index != this.state.index) {
      this.setState({ index, isAppeared: !this.state.isAppeared });
    }
  };

  mapAll = items => {
    const { index, isAppeared } = this.state;
    return items.map((el, i) => (
      <React.Fragment key={el.id}>
        <Tab
          hide={el.primary.em21_hide}
          index={i}
          stateIndex={index}
          isAppeared={isAppeared}
          title={el.primary.em21_tab_title.text}
          checked={el.primary.em21_checked}
          checkedHandler={this.checkedHandler}
        />
        <Accordion
          hide={el.primary.em21_hide}
          items={el.items}
          index={i}
          stateIndex={index}
          isAppeared={isAppeared}
        />
      </React.Fragment>
    ));
  };

  mapTabs = items => {
    const { index, isAppeared } = this.state;
    return items.map((el, i) => (
      <Tab
        key={el.id}
        hide={el.primary.em21_hide}
        index={i}
        stateIndex={index}
        isAppeared={isAppeared}
        title={el.primary.em21_tab_title.text}
        checked={el.primary.em21_checked}
        checkedHandler={this.checkedHandler}
      />
    ));
  };

  mapContent = items => {
    const { index, isAppeared } = this.state;
    return items.map((el, i) => (
      <Accordion
        key={el.id}
        hide={el.primary.em21_hide}
        items={el.items}
        index={i}
        stateIndex={index}
        isAppeared={isAppeared}
      />
    ));
  };

  render() {
    const { items } = this.props;
    return (
      <TabsLayout
        mapAll={() => this.mapAll(items)}
        mapTabs={() => this.mapTabs(items)}
        mapContent={() => this.mapContent(items)}
      />
    );
  }
}
