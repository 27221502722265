import React, { Component } from 'react';
import { object, array } from 'prop-types';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import Slider from 'react-slick';
import { isIOS } from 'react-device-detect';
import ReactPlayer from 'react-player';

import { ImageWrap } from 'components';
import './styles.scss';

export default class InteractiveCarouselEm012 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      isActivated: false,
      index: 0,
      isPlaying: true
    };
  }

  static propTypes = {
    primary: object.isRequired,
    items: array.isRequired
  };

  handleVisibility = () => {
    if (document.visibilityState === 'visible') {
      this.setState({ isPlaying: false });
      this.setState({ isPlaying: true });
    }
  };

  componentDidMount() {
    if (isIOS) {
      document.addEventListener(
        'visibilitychange',
        this.handleVisibility
      );
    }
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  componentWillUnmount() {
    if (isIOS) {
      document.removeEventListener(
        'visibilitychange',
        this.handleVisibility
      );
    }
  }

  extrasettings = () => ({
    beforeChange: (current, next) => {
      this.state.nav1.slickGoTo(next);
    }
  });

  render() {
    const {
      state: { isActivated, isPlaying },
      props: {
        primary: { em12_hide },
        items
      }
    } = this;
    const iframeOptions = {
      width: isMobile ? '100vw' : '100%',
      height: isMobile ? '51vh ' : '100%',
      playing: true,
      loop: true,
      controls: false,
      volume: 1,
      muted: true,
      playsinline: true
    };
    const settings = [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
          arrows: true
        }
      }
    ];

    return em12_hide === 'false' ? (
      <div className="InteractiveCarousel">
        <div className="InteractiveCarousel-topWrapper">
          <Slider
            asNavFor={this.state.nav2}
            ref={slider => (this.slider1 = slider)}
            arrows={false}
            infinite={false}
            fade={true}
            speed={300}
          >
            {items.map(el => {
              const video = el.em12_video
                ? el.em12_video.embed_url
                : null;
              return (
                <div
                  key={el.em12_title}
                  className="InteractiveCarousel-slide"
                >
                  <div className="InteractiveCarousel-videoWrap">
                    {video && isPlaying ? (
                      <ReactPlayer
                        className="InteractiveCarousel-video videoPlayer-overlay"
                        url={video}
                        {...iframeOptions}
                        config={{
                          vimeo: {
                            playerOptions: {
                              background: true
                            }
                          }
                        }}
                      />
                    ) : (
                      <ImageWrap
                        className="InteractiveCarousel-image"
                        style={{ objectFit: 'cover' }}
                        image={el.em12_image}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="InteractiveCarousel-bottomWrapper">
          <Slider
            asNavFor={this.state.nav1}
            ref={slider => (this.slider2 = slider)}
            slidesToShow={3}
            swipeToSlide={true}
            focusOnSelect={true}
            infinite={true}
            arrows={false}
            variableWidth={true}
            responsive={settings}
            speed={300}
            {...this.extrasettings()}
          >
            {items.map((el, i) => {
              const video = el.em12_video
                ? el.em12_video.embed_url
                : null;
              return (
                <div
                  key={el.em12_title}
                  className={classNames(
                    'InteractiveCarousel-chapter',
                    {
                      'is-active': isActivated
                    }
                  )}
                >
                  <div>
                    {video && el.em12_image && (
                      <ImageWrap
                        className="InteractiveCarousel-icon"
                        image={el.em12_image}
                      />
                    )}
                    <h4 className="InteractiveCarousel-subTitle">
                      {el.em12_sub_title}
                    </h4>
                    <h3 className="InteractiveCarousel-title">
                      {el.em12_title}
                    </h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                        el.em12_description.html
                      }}
                      className="InteractiveCarousel-description"
                    />
                    <p className="InteractiveCarousel-pagination">
                      {i + 1}/{items.length}
                    </p>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    ) : null;
  }
}
