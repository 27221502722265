import React, { Component } from 'react';
import { func } from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

export default class TabsLayout extends Component {
  static propTypes = {
    mapAll: func.isRequired,
    mapTabs: func.isRequired,
    mapContent: func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      width: 1025
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('load', this.handleWindowSizeChange);
      window.addEventListener('resize', this.handleWindowSizeChange);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('load', this.handleWindowSizeChange);
      window.removeEventListener('resize', this.handleWindowSizeChange);
    }
  }

  handleWindowSizeChange = () => {
    this.setState({
      width: typeof window !== 'undefined' ? window.innerWidth : 0
    });
  };

  render() {
    const { width } = this.state;
    return (
      <section
        className={classNames('TabsLayout', {
          isMobile: width < 1024
        })}
      >
        {width < 1024 ? (
          this.props.mapAll()
        ) : (
          <div className="TabsLayout-wrap">
            <div className="TabsLayout-tabs">
              {this.props.mapTabs()}
            </div>
            <div className="TabsLayout-content">
              {this.props.mapContent()}
            </div>
          </div>
        )}
      </section>
    );
  }
}
