import React from 'react';
import { object } from 'prop-types';
import { Scene, Controller } from 'react-scrollmagic';

import './styles.scss';

const TwoColumnCopy = ({
  primary: { em20_hide, em20_left_copy, em20_right_copy }
}) =>
  em20_hide === 'false' ? (
    <Controller>
      <Scene classToggle="is-visible-copy" triggerHook={1}>
        <article className="TwoColumnCopy">
          <div
            className="TwoColumnCopy-copy"
            dangerouslySetInnerHTML={{
              __html: em20_left_copy.html
            }}
          />
          <div
            className="TwoColumnCopy-copy"
            dangerouslySetInnerHTML={{
              __html: em20_right_copy.html
            }}
          />
        </article>
      </Scene>
    </Controller>
  ) : null;

TwoColumnCopy.propTypes = {
  primary: object.isRequired
};

export default TwoColumnCopy;
