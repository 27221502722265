import React from 'react';
import { object } from 'prop-types';
import classNames from 'classnames';
import { Controller, Scene } from 'react-scrollmagic';
import { isMobileOnly, isIOS } from 'react-device-detect';
import ReactPlayer from 'react-player';
import { LinkWrap } from 'components';

import { ImageWrap } from 'components';
import './styles.scss';

export default class TwoImagesIntroModuleEm011 extends React.PureComponent {
  static propTypes = {
    primary: object.isRequired
  };

  constructor() {
    super();
    this.state = {
      iframeClassname: '',
      isPlaying: true
    };
    this.frame = React.createRef();
  }

  handleVisibility = () => {
    if (document.visibilityState === 'visible') {
      this.setState({ isPlaying: false });
      this.setState({ isPlaying: true });
    }
  };

  componentDidMount() {
    if (isIOS) {
      document.addEventListener(
        'visibilitychange',
        this.handleVisibility
      );
    }
  }

  componentWillUnmount() {
    if (isIOS) {
      document.removeEventListener(
        'visibilitychange',
        this.handleVisibility
      );
    }
  }

  descriptionBlock = () => {
    const {
      primary: {
        em11_title,
        em11_description: { html },
        em11_extra_copy,
        em11_video_thumbnail_image,
        em11_video_link
      }
    } = this.props;
    return (
      <div
        className={classNames(
          'TwoImages-text',
          !em11_video_link &&
          !em11_video_thumbnail_image &&
          'oneImage'
        )}
      >
        <p className="TwoImages-title">{em11_title}</p>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
          className="TwoImages-description"
        />
        {isMobileOnly && em11_extra_copy ? (
          <p
            dangerouslySetInnerHTML={{
              __html: em11_extra_copy.html
            }}
            className="TwoImages-optionalDesc"
          />
        ) : null}
      </div>
    );
  };

  optionalBlock = () => {
    const {
      primary: { em11_extra_copy }
    } = this.props;
    return em11_extra_copy ? (
      <figcaption
        dangerouslySetInnerHTML={{ __html: em11_extra_copy.html }}
        className="TwoImages-optionalText"
      />
    ) : null;
  };

  checkHeight = () => {
    const height = this.frame.current.player.player.player.element.height;
    if (height < 360) {
      this.setState({ iframeClassname: 'isWide' });
    }
    return;
  };

  render() {
    const {
      primary: {
        em11_hide,
        em11_caption_link,
        em11_caption_link_rel,
        em11_image,
        em11_video_thumbnail_image,
        em11_highlighted_text,
        em11_text,
        em11_video_link
      }
    } = this.props;
    const iframeOptions = {
      playing: true,
      loop: true,
      controls: false,
      volume: 0,
      muted: true,
      playsinline: true
    };
    const video = em11_video_link ? em11_video_link.embed_url : null;
    const uid =
      em11_caption_link && em11_caption_link.uid
        ? em11_caption_link.uid
        : null;
    return em11_hide === 'false' ? (
      <Controller>
        <Scene classToggle="is-visible" triggerHook={1}>
          <section className="TwoImages">
            <div className="TwoImages-firstBlock">
              <div className="TwoImages-runningTitleWrapper">
                <div className="TwoImages-runningTitle">
                  <LinkWrap
                    className="TwoImages-runningTitleLink rotated-link"
                    to={uid}
                    rel={em11_caption_link_rel}
                  >
                                        <span className="TwoImages-captionHighlightedText">
                                            {em11_highlighted_text !== '-'
                                              ? em11_highlighted_text
                                              : ''}
                                        </span>
                    {` `}
                    <span className="TwoImages-captionText">
                                            {em11_text !== '-' ? em11_text : ''}
                                        </span>
                  </LinkWrap>
                </div>
              </div>
              <figure className="TwoImages-firstImage">
                <ImageWrap image={em11_image} />
              </figure>
              {this.descriptionBlock()}
            </div>
            {video && this.state.isPlaying ? (
              <figure className="TwoImages-secondImage">
                <ReactPlayer
                  ref={this.frame}
                  className={classNames('TwoImages-video', this.state.iframeClassname, 'videoPlayer-overlay')}
                  url={video}
                  width="100%"
                  height="100%"
                  onReady={this.checkHeight}
                  {...iframeOptions}
                  config={{
                    vimeo: {
                      playerOptions: {
                        background: true
                      }
                    }
                  }}
                />
              </figure>
            ) : (
              em11_video_thumbnail_image &&
              em11_video_thumbnail_image.fluid && (
                <figure className="TwoImages-secondImage">
                  <ImageWrap
                    image={em11_video_thumbnail_image}
                  />
                  {this.optionalBlock()}
                </figure>
              )
            )}

            <div className="TwoImages-mobileOnly">
              {this.descriptionBlock()}
              {em11_video_thumbnail_image && this.optionalBlock()}
            </div>
          </section>
        </Scene>
      </Controller>
    ) : null;
  }
}
