import React from 'react';
import { array, func, string } from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { FilterItem } from 'components';
import { getCurrentLocale, t } from '../../utils/lang-helper';
import './styles.scss';

class Filters extends React.PureComponent {
  static propTypes = {
    items: array.isRequired,
    filterHandler: func.isRequired,
    routedHandler: func.isRequired,
    routedTag: string
  };

  static defaultProps = {
    routedTag: null
  };

  filterHandler = value => this.props.filterHandler(value);

  routedHandler = value => this.props.routedHandler(value);

  render() {
    const { items, routedTag } = this.props;

    // Filter to show only current locale
    const filteredItems = items.filter(
      el => el.node.lang === getCurrentLocale()
    );

    return (
      <section className="ListingFilters">
        <h2 className="ListingFilters-title">
          {t('filter our stories')}
        </h2>
        <ul className="ListingFilters-list">
          {filteredItems.map(el => (
            <FilterItem
              key={el.node.uid}
              filterHandler={this.filterHandler}
              routedHandler={this.routedHandler}
              slug={el.node.uid}
              tagName={el.node.data.name}
              routedTag={
                routedTag === el.node.uid ? routedTag : null
              }
              checked={routedTag === el.node.uid && !!routedTag}
              resetCheck={!!routedTag}
            />
          ))}
        </ul>
      </section>
    );
  }
}

const ListingFiltersGm003 = props => (
  <StaticQuery
    query={graphql`
      {
        all: allPrismicTag {
          edges {
            node {
              uid
              lang
              data {
                name
              }
            }
          }
        }
      }
    `}
    render={({ all: { edges } }) => <Filters items={edges} {...props} />}
  />
);

export default ListingFiltersGm003;
