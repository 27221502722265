import React from 'react';
import { array, string } from 'prop-types';
import { Controller, Scene } from 'react-scrollmagic';
import { graphql, StaticQuery } from 'gatsby';
import { LinkWrap } from 'components';
import { t } from '../../utils/lang-helper';
import './styles.scss';

const ArticleTags = ({ url, items }) => {
  const tags = items.filter(n => n.tag.document !== null);

  return tags && tags.length ? (
    <Controller>
      <Scene classToggle="is-visible" triggerHook={1}>
        <section className="ArticleTagsEm018">
          <h2 className="ArticleTagsEm018-title">{t('Tags')}</h2>
          <ul className="ArticleTagsEm018-box">
            {tags.map(
              ({
                tag: {
                  document: {
                    id,
                    data: { name },
                    uid
                  }
                }
              }) => (
                <li key={id} className="ArticleTagsEm018-item">
                  <LinkWrap
                    to={{ uid: url }}
                    state={{ tag: uid }}
                    className="ArticleTagsEm018-link"
                  >
                    {name}
                  </LinkWrap>
                </li>
              )
            )}
          </ul>
        </section>
      </Scene>
    </Controller>
  ) : null;
};

ArticleTags.propTypes = {
  items: array.isRequired,
  url: string.isRequired
};

const ArticleTagsEm018 = props => (
  <StaticQuery
    query={graphql`
      {
        st: prismicStories {
          uid
        }
      }
    `}
    render={({ st: { uid } }) => <ArticleTags url={uid} {...props} />}
  />
);

export default ArticleTagsEm018;
