import React, { Component } from 'react';
import { string, array, object, bool } from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import './styles.scss';
import { findIndex } from 'lodash';
import { Link } from 'gatsby';
import { t, getLocalePrefix } from '../../utils/lang-helper';

export default class MySelect extends Component {
  static propTypes = {
    value: string,
    type: string.isRequired,
    options: array.isRequired,
    field: object.isRequired,
    form: object.isRequired,
    placeholder: string.isRequired,
    className: string,
    dropUp: bool
  };
  static defaultProps = {
    value: '',
    className: ''
  };

  constructor(props) {
    super(props);
    this.state = {
      careerMessageActive: false
    };
  }

  render() {
    const { careerMessageActive } = this.state;
    const {
      options,
      field,
      form,
      placeholder,
      className,
      type,
      dropUp
    } = this.props;
    return (
      <div className={classNames('selectWrap', className)}>
        <Select
          className={classNames('react-select-container', {
            'react-select-drop-up': dropUp
          })}
          classNamePrefix="react-select"
          options={options}
          placeholder={placeholder}
          value={
            options
              ? options.find(op => op.value === field.value)
              : ''
          }
          onChange={option =>
            type === 'applicant'
              ? (form.setFieldValue(field.name, option.value),
                this.setState({
                  careerMessageActive:
                    option.value == 'CAREERS' && true
                }))
              : (form.setFieldValue(field.name, option.init),
                this.setState({
                  careerMessageActive:
                    option.value == 'CAREERS' && true
                }))
          }
          onBlur={field.onBlur}
        />
        {findIndex(options, ['value', 'CAREERS']) != -1 &&
          ((options[findIndex(options, ['value', 'CAREERS'])].label =
            'CAREERS >'),
            (
              <div
                className={classNames(
                  'careersMessage',
                  careerMessageActive === true && 'isActive'
                )}
              >
                <p>
                  {t(
                    'For any job applications we kindly invite you to visit the '
                  )}
                  <Link to={getLocalePrefix() + '/careers'}>
                    {t('Careers')}
                  </Link>
                  {t(
                    ' section of our website, where you will be able to apply for a specific opening or to submit an unsolicited application. Please refrain from sending any job application through this form, as only information submitted through '
                  )}
                  <Link to={getLocalePrefix() + '/careers'}>
                    {t('Careers')}
                  </Link>
                  {t(' will be considered.')}
                </p>
              </div>
            ))}
      </div>
    );
  }
}
