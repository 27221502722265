import React from 'react';
import { string, func } from 'prop-types';

import './styles.scss';

const LoadButton = ({ text, count, clickHandler }) => (
  <div className="Load">
    <span className="Load-count">{count}</span>
    <button className="Load-button" type="button" onClick={clickHandler}>
      {text}
    </button>
  </div>
);

LoadButton.propTypes = {
  text: string.isRequired,
  count: string.isRequired,
  clickHandler: func.isRequired
};

export default LoadButton;
