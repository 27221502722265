import React from 'react';
import { object } from 'prop-types';
import { snakeCase } from 'lodash';
import classNames from 'classnames';

import './styles.scss';

const SectionIntroEm004 = ({
  primary: {
    em4_hide,
    em4_background,
    em4_section_alignment,
    em4_big_title,
    em4_small_title,
    em4_medium_title,
    em4_copy
  }
}) => {
  return em4_hide === 'false' ? (
    <article
      className={classNames(
        'IntroEm004',
        em4_section_alignment === 'center'
          ? 'IntroEm004--center'
          : em4_section_alignment === 'left'
            ? 'IntroEm004--left'
            : 'IntroEm004--right',
        em4_background === 'darker' && 'is-darker'
      )}
      id={snakeCase(em4_big_title)}
    >
      <div className="IntroEm004-textWrap">
        {em4_small_title && (
          <h4 className="IntroEm004-smallTitle">{em4_small_title}</h4>
        )}
        {em4_big_title && (
          <h2 className="IntroEm004-bigTitle">{em4_big_title}</h2>
        )}
        {em4_medium_title && (
          <h3 className="IntroEm004-mediumTitle">
            {em4_medium_title}
          </h3>
        )}
        {em4_copy && (
          <div
            dangerouslySetInnerHTML={{ __html: em4_copy.html }}
            className={classNames(
              'IntroEm004-copy',
              em4_medium_title
                ? 'IntroEm004-copy--smallSize'
                : 'IntroEm004-copy--bigSize'
            )}
          />
        )}
      </div>
    </article>
  ) : null;
};

SectionIntroEm004.propTypes = {
  primary: object.isRequired
};

export default SectionIntroEm004;
