import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';

import { ReactComponent as FacebookSvg } from 'svg/facebook.svg';
import { ReactComponent as TwitterSvg } from 'svg/twitter.svg';
import { ReactComponent as PinterestSvg } from 'svg/pinterest.svg';
import { ReactComponent as WeiboSvg } from 'svg/weibo.svg';
import './styles.scss';
import { t } from '../../utils/lang-helper';

// We want to share this URL, you can change it
const locationHref = typeof window !== 'undefined' && window.location.href;
const socialList = [
  {
    name: 'Facebook',
    icon: FacebookSvg,
    share: `https://www.facebook.com/sharer/sharer.php?u=${locationHref}`
  },
  {
    name: 'Twitter',
    icon: TwitterSvg,
    share: `https://twitter.com/intent/tweet?url=${locationHref}`
  },
  {
    name: 'Pinterest',
    icon: PinterestSvg,
    share: `https://www.pinterest.com/pin/create/bookmarklet/?url=${locationHref}`
  },
  {
    name: 'Weibo',
    icon: WeiboSvg,
    share: `http://service.weibo.com/share/share.php?appkey=&title=${locationHref}`
  }
];

const SocialShareButtonsEm019 = () => {
  return (
    <Controller>
      <Scene classToggle="is-visible" triggerHook={1}>
        <section className="SocialShareButtonsEm019">
          <h2 className="SocialShareButtonsEm019-title">
            {t('Share')}
          </h2>
          <nav className="SocialShareButtonsEm019-socials">
            <ul className="SocialShareButtonsEm019-list">
              {socialList.map(({ name, share, icon = null }) => {
                return (
                  <li
                    key={name}
                    className="SocialShareButtonsEm019-item"
                  >
                    {/* Note that external links still use `a` tags. */}
                    <a
                      className="SocialShareButtonsEm019-link"
                      href={share}
                      target="blank"
                    >
                      {typeof icon === 'function'
                        ? icon()
                        : name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </nav>
        </section>
      </Scene>
    </Controller>
  );
};

export default SocialShareButtonsEm019;
