import React from 'react';
import { object, arrayOf, func, oneOfType, node } from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { t } from '../../utils/lang-helper';
import {
  SocialShareButtonsEm019,
  ArticleTagsEm018
} from 'components';
import './styles.scss';

class ArticleDetail extends React.Component {
  static propTypes = {
    children: oneOfType([arrayOf(node), node]).isRequired,
    videoModule: func.isRequired,
    next: func.isRequired,
    carousel: func.isRequired,
    articleDate: object.isRequired
  };

  state = {
    isVisibleSection: true
  };

  render() {
    const { isVisibleSection } = this.state;
    const {
      children,
      articleDate: { firstPublicationDate, title, articleTags },
      videoModule,
      next,
      carousel
    } = this.props;
    const publicationDate = moment(firstPublicationDate).format('ll');
    return (
      <section
        className={classNames('ArticleDetail js-detailBox', {
          isVisibleSection: isVisibleSection
        })}
      >
        <div className="ArticleDetail-contentBox js-contentBox">
          {videoModule()}
          <div className="ArticleDetail-datePosted">
                        <span>
                            {t('POSTED ON')} {publicationDate}
                        </span>
          </div>
          <div className="ArticleDetail-itemsBox">
            <h3 className="ArticleDetail-titlePosted">
              {title.text}
            </h3>
            {children}
            <ArticleTagsEm018 items={articleTags} />
            <SocialShareButtonsEm019 />
          </div>
          {/*<div className='ArticleDetail-itemsBox'>*/}
          {/*  <Newsletter*/}
          {/*    gainBefore={t(*/}
          {/*      'Sign up to our mailing list to receive latest stories, news, events and much more.',*/}
          {/*    )}*/}
          {/*    gainAfter={t(*/}
          {/*      'Congratulations! You are now subscribed to ISKO’s newsletter',*/}
          {/*    )}*/}
          {/*    titleBefore={t('Never miss a story')}*/}
          {/*    titleAfter={t('Thank you')}*/}
          {/*    btnText={t('Reset')}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="ArticleDetail-itemsBox ArticleDetail-carousel">
            {carousel()}
          </div>
          {next()}
        </div>
      </section>
    );
  }
}

export const WrapForNewsLetterBlock = props => <ArticleDetail {...props} />;

export default WrapForNewsLetterBlock;
