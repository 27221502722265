import React from 'react';
import { object, array, string } from 'prop-types';
import { navigate, StaticQuery, graphql } from 'gatsby';
import Slider from 'react-slick';
import { isMobileOnly } from 'react-device-detect';
import { Controller, Scene } from 'react-scrollmagic';
import classNames from 'classnames';
import { getLocalePrefix, t } from '../../utils/lang-helper';
import { ImageWrap } from 'components';
import './styles.scss';

const settings = (type, style) => {
  const storyType = isMobileOnly ? 1.6 : style === 'indent' ? 1.4 : 2.4;
  const normalType = isMobileOnly ? 2.2 : 3.8;
  return {
    slidesToShow: type === 'story' ? storyType : normalType,
    slidesToScroll: 1,
    arrows: !isMobileOnly,
    swipeToSlide: true,
    speed: 500,
    easing: 'ease-in-out',
    infinite: false
  };
};

const StoriesCarousel = ({
  primary: {
    rm2_title,
    rm2_hide,
    rm2_section_style,
    rm2_image_size,
    rm2_background
  },
  items,
  type,
  uid
}) => {
  const stories = items.filter(
    n =>
      n.rm2_related_story !== null &&
      n.rm2_related_story.document !== null &&
      n.rm2_related_story.document.data !== null &&
      n.rm2_related_story.document.data.lister_image !== null &&
      n.rm2_related_story.document.data.lister_image.fluid !== null &&
      n.rm2_related_story.document.data.lister_title !== null
  ); // Filter empty items
  return rm2_hide === 'false' && stories.length > 0 ? (
    <section
      className={classNames(
        'RSCarousel',
        rm2_image_size === 'different size' && 'RSCarousel-different',
        type === 'story' &&
        rm2_section_style === 'indent' &&
        'RSCarousel-indent',
        type === 'story' &&
        rm2_section_style === 'full' &&
        'RSCarousel-full',
        rm2_background === 'darker' && 'RSCarousel--is-darker'
      )}
    >
      <h2
        className={classNames(
          'RSCarousel-title',
          type === 'story' &&
          rm2_section_style === 'indent' &&
          'RSCarousel-title--start',
          rm2_background === 'darker' && 'RSCarousel-title--adapt'
        )}
      >
        {rm2_title.text}
      </h2>
      <Controller>
        <Scene classToggle="is-visible" triggerHook={1}>
          <div className="RSCarousel-sliderWrap">
            <Slider
              className={classNames(
                'RSCarousel-slider',
                type === 'story' &&
                'RSCarousel-slider--storyType'
              )}
              {...settings(type, rm2_section_style)}
            >
              {stories.map((el, i) => (
                <figure
                  className={classNames(
                    'Story',
                    type === 'story' &&
                    rm2_section_style === 'indent' &&
                    'Story-indent',
                    type === 'story' &&
                    rm2_section_style === 'full' &&
                    'Story-full'
                  )}
                  key={i}
                  onClick={() => {
                    navigate(
                      getLocalePrefix() +
                      el.rm2_related_story.document
                        .uid
                    );
                  }}
                >
                  <ImageWrap
                    image={
                      el.rm2_related_story.document.data
                        .lister_image
                    }
                    className={classNames(
                      'Story-image',
                      rm2_image_size ===
                      'different size' &&
                      'Story-image--different',
                      type === 'story' &&
                      rm2_section_style ===
                      'indent' &&
                      'Story-image--indent',
                      type === 'story' &&
                      rm2_section_style === 'full' &&
                      'Story-image--full'
                    )}
                  />
                  <figcaption
                    className="Story-description"
                    dangerouslySetInnerHTML={{
                      __html:
                      el.rm2_related_story.document
                        .data.lister_description
                        .html
                    }}
                  />
                </figure>
              ))}
            </Slider>
          </div>
        </Scene>
      </Controller>
      {type === 'story' && (
        <div className="StoriesGrid-viewAllButton">
          <button
            onClick={() => navigate(getLocalePrefix() + uid)}
            type="button"
          >
            {t('VIEW ALL STORIES')}
          </button>
        </div>
      )}
    </section>
  ) : null;
};

StoriesCarousel.propTypes = {
  primary: object.isRequired,
  items: array.isRequired,
  type: string,
  uid: string.isRequired
};

StoriesCarousel.defaultProps = {
  type: ''
};

const RelatedStoriesCarouselRm002 = props => (
  <StaticQuery
    query={graphql`
      query {
        prismicStories {
          uid
        }
      }
    `}
    render={({ prismicStories: { uid } }) => (
      <StoriesCarousel uid={uid} {...props} />
    )}
  />
);

export default RelatedStoriesCarouselRm002;
