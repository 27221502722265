import React from 'react';
import { object } from 'prop-types';
import './styles.scss';

const ContentTextSnippetEm022 = ({
  primary: {
    em022_title,
    em022_sub_title,
    em022_text
  }
}) => {
  return (
    <section className="ContentTextSnippetEm022">
      <div className="ContentTextSnippetEm022-wrap">
        <div className="ContentTextSnippetEm022-subWrap">
          <h2 className="ContentTextSnippetEm022-title">{em022_title}</h2>
          <h3 className="ContentTextSnippetEm022-subTitle">{em022_sub_title}</h3>
          <p className="ContentTextSnippetEm022-text">{em022_text.text}</p>
        </div>
      </div>
    </section>
  );
};

ContentTextSnippetEm022.propTypes = {
  primary: object.isRequired
};

export default ContentTextSnippetEm022;
