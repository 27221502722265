import React from 'react';
import { object } from 'prop-types';

import './styles.scss';

const CopyOnlyLeftHandSideEm002 = ({ primary: { em2_hide, em2_copy } }) => {
  return em2_hide === 'false' ? (
    <section className="CopyOnlyLeftHandSideEm002">
      <div className="CopyOnlyLeftHandSideEm002-wrap">
        <div
          dangerouslySetInnerHTML={{ __html: em2_copy.html }}
          className="CopyOnlyLeftHandSideEm002-description"
        />
      </div>
    </section>
  ) : null;
};

CopyOnlyLeftHandSideEm002.propTypes = {
  primary: object.isRequired
};

export default CopyOnlyLeftHandSideEm002;
