import React from 'react';
import { string, func, number, bool } from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

const Tab = ({
  title,
  hide,
  stateIndex,
  index,
  isAppeared,
  checkedHandler
}) => {
  return hide === 'false' ? (
    <article className="Tab">
      <label
        className={classNames(
          'Tab-label',
          stateIndex === index && isAppeared && 'Tab-label--checked'
        )}
      >
        <input
          type="checkbox"
          className="Tab-checkbox"
          onChange={() => checkedHandler(index)}
          checked={stateIndex === index && isAppeared}
        />
        <h2 className="Tab-title">{title}</h2>
        <div className="Tab-iconContainer">
          <div
            className={classNames(
              'Tab-iconButton',
              stateIndex === index &&
              isAppeared &&
              'Tab-iconButton--checked'
            )}
          />
        </div>
      </label>
    </article>
  ) : null;
};

Tab.propTypes = {
  title: string.isRequired,
  hide: string.isRequired,
  checked: string.isRequired,
  checkedHandler: func.isRequired,
  index: number.isRequired,
  stateIndex: number.isRequired,
  isAppeared: bool.isRequired
};

export default Tab;
