import React from 'react';
import { object, bool, string } from 'prop-types';
import classNames from 'classnames';
import { throttle } from 'lodash';
import { getLocalePrefix, t } from '../../utils/lang-helper';
import { Link, StaticQuery, graphql, navigate } from 'gatsby';
import { NavLink, Button, LanguageSwitcher, Footer } from 'components';
import { ReactComponent as Logo } from 'svg/logo.svg';
import './styles.scss';

class NavigationContainer extends React.PureComponent {
  static propTypes = {
    prismic: object.isRequired,
    alwaysVisible: bool,
    pageLang: string
  };

  static defaultProps = {
    alwaysVisible: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isScrolled: false,
      isOpened: false,
      expandedItemKey: null,
      additionalClass: ''
    };

    this.node = React.createRef();

    this.throttleScroll = throttle(this.handleScroll, 10, {
      trailing: false
    });
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.throttleScroll);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.throttleScroll);
    }
  }

  handleScroll = () => {
    if (this.state.isScrolled && window.scrollY > 200) {
      return;
    }

    this.setState({ isScrolled: window.scrollY > 0 });
  };

  handleClickForFirstRedirect = () => {
    if (!sessionStorage.getItem('singleRenderSubscription')) {
      sessionStorage.setItem('singleRenderSubscription', true);
    }
  };

  render() {
    let {
      state: {
        isScrolled,
        isOpened,
        expandedItemKey,
        additionalClass
      },
      props: {
        alwaysVisible,
        pageLang,
        prismic: { allPrismicNavigation }
      },
      handleClickForFirstRedirect
    } = this;

    // Get only the current language
    const navDataPrismic = allPrismicNavigation.nodes.filter(obj => {
      return obj.lang === pageLang;
    });

    if (!navDataPrismic[0]) {
      return null;
    }

    const navData = navDataPrismic[0].data;

    const showLanguageDropdown = navData.navigation_show_language_dropdown;
    const showSearch = pageLang === 'en-gb';

    let keyId = 0;
    const navLinks = navData.body
      .filter(el => el.primary.navigation_text.search(/PRISMIC-FIX/i) === -1)
      .map(el => ({
        name: el.primary.navigation_text,
        link: el.primary.navigation_link ? (el.primary.navigation_link?.url ? el.primary.navigation_link?.url : getLocalePrefix() + (el.primary.navigation_link?.uid || '')) : '',
        linkTarget: el.primary?.navigation_link?.target,
        rel: el.primary.navigation_item_link_rel && el.primary.navigation_item_link_rel !== 'follow' ? el.primary.navigation_item_link_rel : null,
        highlighted: el.primary.navigation_highlight !== 'none',
        showCaret: el.primary.navigation_show_caret,
        keyId: keyId++,
        columns: !el.items.length ? [] : ['1', '2', '3', '4']
          .map(columnId => ({
            columnId,
            isHidden: !el.primary[`show_column_${columnId}`],
            subItems: el.items
              .filter(subEl => subEl.navigation_item_column_id === columnId)
              .filter(subEl => subEl.navigation_item_title[0].text.search(/PRISMIC-FIX/i) === -1)
              .map(subEl => ({
                subName: subEl.navigation_item_title[0].text,
                subImage: subEl.navigation_item_image.url ? subEl.navigation_item_image.url + '&w=500' : null,
                subLink: subEl.navigation_item_link ? (subEl.navigation_item_link.url ? subEl.navigation_item_link.url : getLocalePrefix() + subEl.navigation_item_link.uid) : '',
                subRel: subEl.navigation_item_link_rel && subEl.navigation_item_link_rel !== 'follow' ? subEl.navigation_item_link_rel : null,
                cssClass: 'type-' + subEl.navigation_item_type,
                subKeyId: keyId++
              }))
          }))
      }));

    // Re-push footer links in 4th column (with mobile only type)
    for (let i1 = 0; i1 < navLinks.length; i1++) {
      if (!navLinks[i1].columns.length) {
        continue;
      }

      for (let i2 = 0; i2 < navLinks[i1].columns.length; i2++) {
        // Collect all footer links
        navLinks[i1].columns[i2].subItems.forEach(subItem => {
          if (subItem.cssClass === 'type-footerLink') {
            // clone & change type
            navLinks[i1].columns[3].subItems.push({
              ...subItem,
              ...{ cssClass: 'type-mobileOnly type-mobileFooter', subKeyId: `clone-${i2}` }
            });
          }
        });
      }
    }

    // Is scrolled
    if (!!alwaysVisible) {
      isScrolled = true;
    }

    // Events
    const toggleNav = () => {
      this.setState({ isOpened: !isOpened, expandedItemKey: null });
    };

    return (
      <header
        ref={this.node}
        className={classNames(
          'Navigation',
          isScrolled ? 'is-shown' : 'is-hidden',
          {
            'is-scrolled': isScrolled,
            'is-opened': isOpened
          },
          additionalClass
        )}
      >
        <Link
          className="Navigation-logo"
          to={getLocalePrefix()}
          onClick={handleClickForFirstRedirect}
        >
          <Logo className="logo-img" />
        </Link>

        <ul className="Navigation-links">
          {navLinks.map(({ name, link, linkTarget, rel = null, columns = [], highlighted, showCaret, keyId }) =>
            columns.length > 0 ? (
              <li
                key={`item-${keyId}`}
                id={`navigation-item-${keyId}`}
                onMouseEnter={this.setSubLeftPosition.bind(this, keyId)}
                className={classNames('Navigation-item', {
                  'is-expanded': expandedItemKey === keyId
                })}
              >
                <div
                  className="Navigation-item-link"
                  onClick={this.clickHandler.bind(this, keyId, link)}
                >
                  <div className={classNames(
                    'sub-arrow',
                    { 'sub-arrow-hidden': !showCaret }
                  )} />
                  <span className="lined">{name}</span>
                </div>

                <div className="Navigation-sub-items-wrapper">
                  <div
                    className={classNames(
                      'Navigation-sub-items',
                      `length-${navLinks.length}`
                    )}
                    id={`navigation-sub-${keyId}`}
                  >
                    {columns.map(({ columnId, subItems, isHidden }) =>
                      <ul
                        key={`column-${keyId}-${columnId}`}
                        className={classNames(
                          'Navigation-column',
                          'column-' + columnId,
                          'column-length-' + subItems.length,
                          { 'column-is-hidden': isHidden }
                        )}
                      >
                        {subItems.map(({
                            subName,
                            subLink,
                            subImage,
                            subRel = null,
                            subKeyId,
                            cssClass
                          }) =>
                            <NavLink
                              key={`sub-${subKeyId}`}
                              link={subLink}
                              liClass={classNames(
                                'Navigation-sub-item',
                                cssClass
                              )}
                              aClass={classNames(
                                'Navigation-sub-item-link',
                                { 'with-image': subImage }
                              )}
                              rel={subRel}
                              callback={this.clickHandler.bind(this, subKeyId, null)}
                            >
                              <>
                                {subImage ? (
                                  <img key={`sub-img-${subKeyId}`} src={subImage} alt="" />
                                ) : null}

                                <span>{subName}</span>
                              </>
                            </NavLink>
                        )}
                      </ul>
                    )}
                  </div>
                </div>
              </li>
            ) : (
              <React.Fragment key={`nav-fragment-${keyId}`}>
                {showSearch &&
                  link.toLowerCase().includes('contact') &&
                  <li className="Navigation-item">
                    <button className="desktop-search-but" onClick={this.searchClickHandler.bind(this)} />
                  </li>
                }

                <NavLink
                  key={`item-${keyId}`}
                  link={link}
                  target={linkTarget}
                  liClass={classNames('Navigation-item', {
                    'active-item': expandedItemKey === keyId
                  })}
                  aClass={classNames('Navigation-item-link', {
                    'Navigation-item-link-contact': link.toLowerCase().includes('contact')
                  })}
                  rel={rel}
                  highlighted={highlighted}
                  callback={this.clickHandler.bind(this, keyId, null)}
                >
                  <span className="lined">{name}</span>
                </NavLink>
              </React.Fragment>
            )
          )}

          {showLanguageDropdown &&
            <LanguageSwitcher isExpanded={true} callback={this.clickHandler.bind(this)}
                              expandedItemKey={expandedItemKey} />
          }

          <li className="Navigation-footerRepeat">
            <Footer footerClass="Footer" pageLang={pageLang} />
          </li>
        </ul>

        <Button isOpened={isOpened} toggleNav={toggleNav} />

        {showSearch &&
          <>
            <button className="mobile-search-but" onClick={this.searchClickHandler.bind(this)} />

            <div className="searh-block-wrap">
              <div className="search-block">
                <form method="get" action={getLocalePrefix() + 'search'}>
                  <input id="search_query" name="search_query" type="text" placeholder={t('Search', pageLang)}
                         autoComplete="off" required />
                  <input className="enter-button" type="submit" />
                  <div className="close-button" onClick={this.searchClickHandler.bind(this)} />
                </form>
              </div>
            </div>
          </>
        }
      </header>
    );
  }

  searchClickHandler(e) {
    const searchBlock = document.getElementsByClassName('searh-block-wrap');
    const searchInput = document.getElementById('search_query');

    if (!searchBlock[0].classList.contains('open')) {
      searchBlock[0].classList.add('open');
      searchInput.focus();

    } else {
      searchBlock[0].classList.remove('open');
      searchInput.blur();
      searchInput.value = '';
    }
  }

  clickHandler(expandKey, link, props) {
    // Go to (on desktop)
    if (link && typeof link === 'string' && link !== '/null') {
      if (window.innerWidth >= 1200) { //1024
        if (link.search('http://') !== -1 || link.search('https://') !== -1) {
          document.location = link;
        } else {
          navigate(link);
        }
      }
    }

    // Expand on mobile
    const {
      state: {
        expandedItemKey
      }
    } = this;

    if (expandKey !== null) {
      this.setState({ expandedItemKey: expandedItemKey === expandKey ? null : expandKey });
    } else {
      this.setState({ isOpened: false });
    }

    // Track
    if (props && props.to) {
      try {
        dataLayer.push({
          event: 'navigationClick',
          data: {
            place: 'Header',
            location: props.to
          }
        });
      } catch (e) {
        // do nothing
      }
    }
  };

  setSubLeftPosition(keyId) {
    const navigationWidth = document.querySelector('.Navigation-links').getBoundingClientRect().width;
    const sub = document.getElementById(`navigation-sub-${keyId}`);

    sub.style.width = `${navigationWidth}px`;
  };
}

const Navigation = props => (
  <StaticQuery
    query={graphql`
      {
        allPrismicNavigation {
          nodes {
            lang
            data {
              navigation_show_language_dropdown
              body {
                items {
                  navigation_item_column_id
                  navigation_item_title {
                    text
                  }
                  navigation_item_link {
                    link_type
                    uid
                    lang
                    url
                  }
                  navigation_item_type
                  navigation_item_link_rel
                  navigation_item_image {
                    dimensions {
                      width
                      height
                    }
                    url
                  }
                }
                primary {
                  navigation_text
                  navigation_highlight
                  navigation_link {
                    link_type
                    uid
                    lang
                    url
                    target
                  }
                  navigation_link_rel
                  navigation_show_caret
                  show_column_1
                  show_column_2
                  show_column_3
                  show_column_4
                }
              }
            }
          }
        }
      }
    `}
    render={data => <NavigationContainer prismic={data} {...props} />}
  />
);

export default Navigation;
