export function packingObjects(array) {
  const tabs = [];

  // Only pack these slice types
  const packingSliceTypes = [
    'contact_form_with_tabs_cfm003',
    'contact_form_with_tabs_cfm0031',
    'regional_representatives_cfm003',
    'accordion_with_tabs_em021'
  ];

  const keyObjects = array.filter(el => packingSliceTypes.includes(el.slice_type));

  if (keyObjects.length === 0) {
    return;
  }

  function sortArray(arr) {
    const index = arr.findIndex((el, i, arr) => {
      const currId = array.findIndex(elm => elm.id == el.id);
      const arrId =
        arr[i - 1] === undefined
          ? currId
          : array.findIndex(elm => elm.id == arr[i - 1].id);
      return currId - arrId > 1;
    });
    const chunk = arr.splice(0, index);
    index !== -1 && chunk.length !== 0 && tabs.push(chunk);
    return index !== -1 ? sortArray(arr) : tabs.push(arr);
  }

  sortArray(keyObjects);

  const preparedArray = tabs.map(el => ({
    __typename: el[0].__typename,
    id: el[0].id,
    items: el
  }));

  function result(arr) {
    const idx = arr.findIndex(el => packingSliceTypes.includes(el.slice_type));
    if (idx !== -1) {
      const item = preparedArray.splice(0, 1);
      const last = arr.splice(idx, item[0].items.length, item[0]);
      last.length = 0;
    }
    return idx !== -1 ? result(arr) : arr;
  }

  return result(array);
}

export function packingObjects0041(array) {
  function transfer(arr, length) {
    const idx = arr.findIndex(el => el.__typename === type);
    if (idx !== -1) {
      const crucial = arr.splice(idx, length);
      crucial.length = 0;
    }
    return arr;
  }

  const type = 'PrismicContactBodyRegionCfm0041';
  const keyObjects = array.filter(el => el.__typename === type);

  if (keyObjects.length === 0) {
    return array;
  }

  const options = keyObjects.map(el => ({
    value: el.primary.cfm41_region_name,
    label: el.primary.cfm41_region_name
  }));

  transfer(array, keyObjects.length);

  for (let i = array.length; i--;) {
    const el = array[i];
    if (el.slice_type === 'regional_representatives_cfm003') {
      el.items = keyObjects;
      el.options = options;
    }
  }

  return array;
}

export function elementHasClass(element, classNames, maxParents = 0) {
  let findIndex = 0;
  let didFind = false;
  let currentNode = element;

  didFind = classNames.some(className =>
    currentNode.className.includes(className)
  );

  while (!didFind && findIndex < maxParents) {
    findIndex += 1;

    if (currentNode.parentElement) {
      currentNode = currentNode.parentElement;

      didFind = classNames.some(className =>
        currentNode.className.includes(className)
      );
    }
  }

  return didFind;
}

export function isEmpty(obj) {
  for (var key in obj) {
    return true;
  }
  return false;
}
