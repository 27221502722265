import React, { Component } from 'react';
import { Link } from 'gatsby';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { getLocalePrefix, t } from '../../utils/lang-helper';
import './styles.scss';

export default class PrivacyPopUp extends Component {
  render() {
    const isPrivacyAwesomeCookie = Cookies.set('PrivacyAwesomeCookie');
    return !isPrivacyAwesomeCookie === true ? (
      <CookieConsent
        debug={true}
        location="bottom"
        buttonText=""
        cookieName="PrivacyAwesomeCookie"
        containerClasses="PrivacyPopUp"
        disableStyles={true}
        contentClasses="PrivacyPopUp-contentClasses"
        buttonClasses="PrivacyPopUp-buttonClasses"
        buttonId="cookieFade"
        disableButtonStyles={true}
        acceptOnScroll={true}
        acceptOnScrollPercentage={50}
      >
        <p className="PrivacyPopUp-text">
          {t(
            'We use cookies to elevate your user experience and enhance the quality of our site. To find out more or adjust cookie settings, '
          )}
          <Link
            to={getLocalePrefix() + '/cookie-policy'}
            className="PrivacyPopUp-cookieLink"
          >
            {t('click here.')}
          </Link>
        </p>
      </CookieConsent>
    ) : null;
  }
}
