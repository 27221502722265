import React from 'react';
import { object } from 'prop-types';
import { Controller, Scene } from 'react-scrollmagic';
import classNames from 'classnames';

import { ImageWrap, LinkWrap } from 'components';
import './styles.scss';

const ContentSnippet1xVisualWithoutCopyEm017 = ({
  primary: {
    em17_link,
    em17_link_rel,
    em17_image,
    em17_title,
    em17_section_alignment
  }
}) => {
  return (
    <Controller>
      <Scene classToggle="is-visible" triggerHook={1}>
        <section className="ContentSnippet1xVisualWithoutCopyEm017">
          <figure
            className={classNames(
              `ContentSnippet1xVisualWithoutCopyEm017-imgWrap is-position-${em17_section_alignment}`
            )}
          >
            {em17_link ? (
              <LinkWrap
                to={em17_link}
                rel={em17_link_rel}
                className="ContentSnippet1xVisualWithoutCopyEm017-link"
              >
                <ImageWrap
                  className="ContentSnippet1xVisualWithoutCopyEm017-image"
                  image={em17_image}
                />
                <figcaption className="ContentSnippet1xVisualWithoutCopyEm017-info">
                  {em17_title}
                </figcaption>
              </LinkWrap>
            ) : (
              <div className="ContentSnippet1xVisualWithoutCopyEm017-link">
                <ImageWrap
                  className="ContentSnippet1xVisualWithoutCopyEm017-image"
                  image={em17_image}
                />
                <figcaption className="ContentSnippet1xVisualWithoutCopyEm017-info">
                  {em17_title}
                </figcaption>
              </div>
            )}
          </figure>
        </section>
      </Scene>
    </Controller>
  );
};

ContentSnippet1xVisualWithoutCopyEm017.propTypes = {
  primary: object.isRequired
};

export default ContentSnippet1xVisualWithoutCopyEm017;
