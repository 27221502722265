import React, { Component } from 'react';
import { object, array, func, string } from 'prop-types';
import { Controller, Scene } from 'react-scrollmagic';
import classNames from 'classnames';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';

import { ImageWrap } from 'components';
import { ReactComponent as ArrowLeftSVG } from 'svg/arrow-left.svg';
import { ReactComponent as ArrowRightSVG } from 'svg/arrow-right.svg';
import './styles.scss';

function ArrowRight(props) {
  const { onClick, className } = props;
  return <ArrowRightSVG className={className} onClick={onClick} />;
}

function ArrowLeft(props) {
  const { onClick, className } = props;
  return <ArrowLeftSVG className={className} onClick={onClick} />;
}

ArrowLeft.propTypes = {
  onClick: func,
  className: string
};

ArrowRight.propTypes = {
  onClick: func,
  className: string
};

const settings = {
  slidesToShow: 1,
  infinite: true,
  focusOnSelect: false,
  arrows: true,
  prevArrow: <ArrowLeft />,
  nextArrow: <ArrowRight />
};

class GallerySet extends Component {
  state = {
    currentSlide: 1,
    isVideoPlaying: false
  };

  static propTypes = {
    dataSet: array.isRequired
  };

  onPlay = () => {
    this.setState({ isVideoPlaying: true });
  };

  onPause = () => {
    this.setState({ isVideoPlaying: false });
  };

  render() {
    const {
      state: { currentSlide, isVideoPlaying },
      props: { dataSet }
    } = this;
    const iframeOptions = {
      width: '100%',
      height: '100%',
      playing: isVideoPlaying,
      loop: true,
      controls: false,
      volume: 0,
      playsinline: true
    };

    const dataSetRs = dataSet ? dataSet : [];

    return (
      <>
        <Slider
          {...settings}
          beforeChange={() => {
            this.onPause();
          }}
          afterChange={currentSlide => {
            this.setState({ currentSlide: currentSlide + 1 });
            this.onPause();
          }}
        >
          {dataSetRs.map(el => {
            const videoUrl = !el.em16_vimeo_link
              ? ''
              : el.em16_vimeo_link;
            return (
              <div
                key={el.em16_image_name}
                className="SlideshowGalleryEm016-contentBox"
              >
                <figure className="SlideshowGalleryEm016-imgBgWrap">
                  {videoUrl ? (
                    <>
                      {isVideoPlaying ? (
                        <ReactPlayer
                          className="SlideshowGalleryEm016-video videoPlayer-overlay"
                          onPause={this.onPause}
                          url={el.em16_vimeo_link}
                          {...iframeOptions}
                          config={{
                            vimeo: {
                              playerOptions: {
                                background: true
                              }
                            }
                          }}
                        />
                      ) : (
                        <ImageWrap
                          className="SlideshowGalleryEm016-image"
                          image={el.em16_image}
                        />
                      )}
                      <button
                        onClick={this.onPlay}
                        className={classNames(
                          'SlideshowGalleryEm016-play',
                          isVideoPlaying &&
                          'SlideshowGalleryEm016-hideButton'
                        )}
                        type="button"
                      />
                    </>
                  ) : (
                    <ImageWrap
                      className="SlideshowGalleryEm016-image"
                      image={el.em16_image}
                    />
                  )}
                  <figcaption className="SlideshowGalleryEm016-info">
                    {el.em16_image_name}
                  </figcaption>
                </figure>
              </div>
            );
          })}
        </Slider>
        <div className="SlideshowGalleryEm016-pagination">
          {currentSlide}/{dataSet.length}
        </div>
      </>
    );
  }
}

export default class SlideshowGalleryEm016 extends Component {
  static propTypes = {
    primary: object.isRequired,
    items: array.isRequired
  };

  render() {
    const {
      primary: { em16_hide, em16_section_style },
      items
    } = this.props;
    return em16_hide === 'false' ? (
      <section
        className={classNames(
          `SlideshowGalleryEm016 is-width-${em16_section_style}`
        )}
      >
        <Controller>
          <Scene classToggle="is-visible" triggerHook={1}>
            <div className="SlideshowGalleryEm016-container">
              <GallerySet dataSet={items} />
            </div>
          </Scene>
        </Controller>
      </section>
    ) : null;
  }
}
