import React from 'react';
import { object } from 'prop-types';
import { LinkWrap } from 'components';

import { Separator } from 'components';
import './styles.scss';

const CtaCenteredSm007 = ({
  primary: { sm7_hide, sm7_link_name, sm7_link, sm7_link_rel = null }
}) => {
  return sm7_hide === 'false' && sm7_link && sm7_link ? (
    <div className="LSBack">
      <LinkWrap to={sm7_link} rel={sm7_link_rel} className="LSBack-button">
        {sm7_link_name}
      </LinkWrap>
      <Separator />
    </div>
  ) : null;
};
CtaCenteredSm007.propTypes = {
  primary: object.isRequired
};

export default CtaCenteredSm007;
