import React, { Component } from 'react';

export default class AnchorPlaceEm024 extends Component {
  offsetAdd = -100;

  elementId = null;

  constructor(props) {
    super(props);

    const {
      primary: {
        em024_id
      }
    } = this.props;

    this.anchorId = em024_id;
    this.elementId = `anchor-${this.anchorId}`;
  }

  componentDidMount() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const element = document.getElementById(this.elementId);
      const hash = window.location.hash.replace(/#/g, '');

      if (this.anchorId !== hash) {
        return;
      }

      if (element) {
        const offsetTop = element.offsetTop;

        window.scroll({
          top: offsetTop + this.offsetAdd,
          behavior: 'smooth'
        });
      }
    }
  }

  render() {
    return (
      <section id={this.elementId} />
    );
  }
}
