import React from 'react';
import { object } from 'prop-types';
import classNames from 'classnames';
import ImageWrap from 'components/ImageWrap';
import './styles.scss';

const Region = ({ item: { primary, items } }) => (
  <div className="Region">
    <div
      className="Region-address"
      dangerouslySetInnerHTML={{ __html: primary ? primary.cfm41_contacts.html : '' }}
    />
    {items && items.lenght !== 0 && (
      <div className="Region-contacts">
        {items.map(
          (
            { cfm41_member_photo, cfm41_email, cfm41_member_name },
            i
          ) => {
            const img =
              cfm41_member_photo !== null ||
              cfm41_member_photo.fluid !== null
                ? cfm41_member_photo
                : null;
            const mailToLink = `mailto:${cfm41_email}`;
            return (
              <figure
                className={classNames(
                  'Region-contact',
                  img.fluid === null &&
                  'Region-contact--noPhoto'
                )}
                key={i}
              >
                <a href={mailToLink}>
                  <ImageWrap
                    className="Region-contactPhoto"
                    image={img}
                  />
                </a>
                <figcaption className="Region-contactDetails">
                  <b>{cfm41_member_name}</b>
                  <div>
                    <a href={mailToLink}>{cfm41_email}</a>
                  </div>
                </figcaption>
              </figure>
            );
          }
        )}
      </div>
    )}
  </div>
);

Region.propTypes = {
  item: object.isRequired
};

export default Region;
