import React, { Component } from 'react';
import { object, array } from 'prop-types';
import { Controller, Scene } from 'react-scrollmagic';
import LinkWrap from '../LinkWrap';
import { ReactComponent as ArrowDown } from 'svg/arrow-down.svg';
import './styles.scss';

export default class AnchorModuleOm001 extends Component {
  static propTypes = {
    primary: object.isRequired,
    items: array.isRequired
  };

  render() {
    const {
      primary: { om1_hide },
      items
    } = this.props;

    return om1_hide === 'false' ? (
      <Controller>
        <Scene classToggle="is-visible" triggerHook={1}>
          <section className="DenimAnchors">
            <div className="DenimAnchors-inner">
              {items.map(el => (
                <div
                  key={el.om1_anchor_title}
                  className="DenimAnchors-block"
                >
                  <LinkWrap
                    className="DenimAnchors-link"
                    to={el.om1_achore_link}
                    smooth={true}
                    offset={-70}
                    duration={1500}
                  >
                    <h3 className="DenimAnchors-title">
                      {el.om1_anchor_title}
                    </h3>
                    <p className="DenimAnchors-comment">
                      {el.om1_achore_subtitle}
                    </p>
                    <button className="DenimAnchors-link">
                      <ArrowDown className="DenimAnchors-arrowDown" />
                    </button>
                  </LinkWrap>
                </div>
              ))}
            </div>
          </section>
        </Scene>
      </Controller>
    ) : null;
  }
}
