import React from 'react';
import { array } from 'prop-types';
import { navigate } from 'gatsby';
import ReactPlayer from 'react-player';
import { isIOS } from 'react-device-detect';
import Slider from 'react-slick';
import { getLocalePrefix } from '../../utils/lang-helper';
import { ImageWrap } from 'components';
import './styles.scss';

class FullWidthSignpostCarouselSm008 extends React.Component {
  static propTypes = {
    items: array.isRequired
  };

  state = {
    isPlaying: true
  };

  handleVisibility = () => {
    if (document.visibilityState === 'visible') {
      this.setState({ isPlaying: false });
      this.setState({ isPlaying: true });
    }
  };

  componentDidMount() {
    if (isIOS) {
      document.addEventListener(
        'visibilitychange',
        this.handleVisibility
      );
    }
  }

  componentWillUnmount() {
    if (isIOS) {
      document.removeEventListener(
        'visibilitychange',
        this.handleVisibility
      );
    }
  }

  change(fromTo) {
    const images = document.querySelectorAll('.FullWidthCarousel-item');
    Array.from(images).map(el => {
      el.style.opacity = fromTo;
      el.style.transition = 'opacity .2s ease-in-out';
    });

    const prev = document.querySelector('.slick-prev');
    const next = document.querySelector('.slick-next');
    prev.style.opacity = fromTo;
    prev.style.transition = 'opacity .2s ease-in';
    next.style.opacity = fromTo;
    next.style.transition = 'opacity .2s ease-in';
  }

  settings = () => ({
    className: 'FullWidthCarousel-slider',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    speed: 300,
    easing: 'ease-in-out',
    infinite: false,
    beforeChange: () => {
      this.change(0);
    },
    afterChange: () => {
      this.change(1);
    }
  });

  render() {
    const { items } = this.props;
    const iframeOptions = {
      width: '100%',
      height: '100%',
      playing: true,
      loop: true,
      controls: false,
      volume: 0,
      muted: true,
      playsinline: true
    };
    return (
      <section className="FullWidthCarousel">
        <Slider {...this.settings()}>
          {items.map((el, i) => {
            const video = el.sm008_video
              ? el.sm008_video.embed_url
              : null;
            return (
              <div key={i}>
                <figure className="FullWidthCarousel-item">
                  <div className="FullWidthCarousel-media">
                    {video && this.state.isPlaying ? (
                      <ReactPlayer
                        className="FullWidthCarousel-video videoPlayer-overlay"
                        url={video}
                        {...iframeOptions}
                        config={{
                          vimeo: {
                            playerOptions: {
                              background: true
                            }
                          }
                        }}
                      />
                    ) : (
                      <ImageWrap
                        className="FullWidthCarousel-image"
                        image={el.sm008_image}
                      />
                    )}
                  </div>
                  <figcaption
                    onClick={() =>
                      navigate(
                        getLocalePrefix() +
                        el.sm008_link.uid
                      )
                    }
                    data-rel={el.sm008_link_rel}
                    className="FullWidthCarousel-textWrap"
                  >
                    <div
                      className="FullWidthCarousel-description"
                      dangerouslySetInnerHTML={{
                        __html: el.sm008_desc.html
                      }}
                    />
                    <h3 className="FullWidthCarousel-title">
                      {el.sm008_title.text}
                    </h3>
                  </figcaption>
                  <p className="FullWidthCarousel-pagination">
                    {i + 1}/{items.length}
                  </p>
                </figure>
              </div>
            );
          })}
        </Slider>
      </section>
    );
  }
}

export default FullWidthSignpostCarouselSm008;
