import React, { Component } from 'react';
import { object, string, array, PropTypes } from 'prop-types';
import { Link } from 'gatsby';
import { getLocalePrefix } from '../../utils/lang-helper';

class LinkWrap extends Component {
  static propTypes = {
    to: object,
    state: object,
    className: string,
    children: PropTypes.oneOfType([array, string]),
    rel: string
  };

  render() {
    const { to, className, children, state, rel = null } = this.props;
    if (to && to.uid === 'homepage') {
      to.uid = '';
    }
    return to ? (
      <>
        {typeof to.uid === 'string' ? (
          <Link
            className={className}
            to={getLocalePrefix() + to.uid}
            state={state}
            rel={rel}
          >
            {children}
          </Link>
        ) : to.url ? (
          <a
            href={to.url}
            target={to.url.includes('iskodenim.com') ? null : '_blank'}
            className={className}
            rel={rel}
          >
            {children}
          </a>
        ) : (
          <div className={className} data-rel={rel}>
            {children}
          </div>
        )}
      </>
    ) : (
      children
    );
  }
}

export default LinkWrap;
