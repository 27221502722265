import React from 'react';
import { string, object } from 'prop-types';
import { Formik, Field, Form } from 'formik';
import classNames from 'classnames';
import MySelect from 'components/CustomSelect';
import { graphql, StaticQuery, navigate } from 'gatsby';
import { quickContact } from '../../utils/validation';
import { getLocalePrefix, t } from '../../utils/lang-helper';
import './styles.scss';
import { spotlerSubscribe } from '../../utils/spotler-subscribe';
import { bcc } from '../../utils/bcc';

class QuickContactFormContainer extends React.Component {
  static propTypes = {
    pageLang: string,
    prismic: object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      msg: '',
      isExpand: false,
      scrolled: false,
      staySticky: true,
      submitMsg: ''
    };
  }

  componentDidMount() {
    this.eventFn = this.handleScroll.bind(this);

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.eventFn);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.eventFn);

      this.hideBodyScroll(false);

      if (this.stateStickyTimer) {
        clearTimeout(this.stateStickyTimer);
      }
    }
  }

  handleScroll() {
    if (typeof window !== 'undefined') {
      let value = window.scrollY >= 200;
      if (this.state.scrolled !== value) {
        this.setState({ scrolled: value });
      }
    }
  }

  hideBodyScroll(hideIt) {
    if (typeof window !== 'undefined' && document.body) {
      if (hideIt) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'visible';
      }
    }
  }

  getPrismicNode() {
    const {
      props: { pageLang }
    } = this;

    const {
      props: {
        prismic: {
          allPrismicFooter
        }
      }
    } = this;

    // Get only the current language
    const prismicNode = allPrismicFooter.nodes.filter(obj => {
      return obj.lang === pageLang;
    });

    return prismicNode?.[0]?.data;
  }

  render() {
    const {
      msg: msg,
      isExpand,
      scrolled,
      staySticky,
      submitMsg
    } = this.state;

    const {
      pageLang
    } = this.props;

    const {
      quick_contact_category,
      quick_contact_hide_page
    } = this.getPrismicNode();

    // Hide the component in specific page
    if (typeof window !== 'undefined' && quick_contact_hide_page) {
      if (
        quick_contact_hide_page.filter((e) =>
          e.pages.uid === window.location.pathname
          || `/${e.pages.uid}` === window.location.pathname
        ).length > 0
      ) {
        this.hideBodyScroll(false);
        return null;
      }
    }

    // Format categories
    const categories = quick_contact_category.map((item, key) => {
      return {
        label: item.category_name,
        value: key.toString(),
        email: item.category_email
      };
    });

    // Hide scroll
    this.hideBodyScroll(isExpand);

    const navigateToContact = () => {
      this.hideBodyScroll(false);
      navigate(getLocalePrefix() + 'contact-us');
    };
    const navigateToPrivacy = () => {
      this.hideBodyScroll(false);
      navigate(getLocalePrefix() + 'privacy-policy1');
    };

    if (msg && staySticky) {
      this.stateStickyTimer = setTimeout(() => {
        if (msg && staySticky) {
          this.setState({ staySticky: false });
          this.hideBodyScroll(false);
        }
      }, 6000);
    }

    return (
      <section className={classNames(
        'QuickContactForm', {
          'QuickContactForm-expanded': isExpand,
          'QuickContactForm-scrolled': scrolled,
          'QuickContactForm-sticky': staySticky
        }
      )}>
        <div className="Wrapper">
          {!msg && (
            <span
              className={classNames(
                'QuickContactForm-action', {
                  'QuickContactForm-action-open': !isExpand,
                  'QuickContactForm-action-close': isExpand
                }
              )}
              onClick={() => this.setState({ isExpand: !isExpand, staySticky: !msg, msg: '' })}
            />
          )}

          <div className="QuickContactForm-content">
            {msg && (
              <>
                <h5 className="ContactForm-selectHeading">{submitMsg}</h5>
                <p className="QuickContactForm-text">{msg}</p>
                <button
                  onClick={() => this.setState({ msg: '', isExpand: false, staySticky: false })}
                  className="ContactForm-button"
                >{t('Close')}</button>
              </>
            )}

            {!msg && !isExpand && (
              <button
                onClick={() => this.setState({ msg: '', isExpand: true, staySticky: true })}
                className="ContactForm-button"
              >
                {t('Interested? Contact Us')}
              </button>
            )}

            {!msg && (
              <>
                <Formik
                  initialValues={
                    {
                      interestedIn: categories?.[0]?.value,
                      firstName: '',
                      lastName: '',
                      email: '',
                      interestedSummary: '',
                      privacyCheck: false,
                      subscribeCheck: false
                    }
                  }
                  validationSchema={quickContact}
                  onSubmit={async (val, actions) => {
                    const {
                      interestedIn,
                      firstName,
                      lastName,
                      email,
                      interestedSummary,
                      privacyCheck,
                      subscribeCheck
                    } = val;

                    this.setState({
                      submitMsg: subscribeCheck ? t('Thank you') : t('Thank you - contact')
                    });

                    let toEmail = categories.filter(item => item.value === interestedIn)[0].email;
                    let interestedInLabel = categories.filter(item => item.value === interestedIn)[0].label;

                    let mailData = {
                      subject: `Quick Contact Form - ISKO Website`,
                      html: `
                        <h2>Interested? Contact Us</h2>
                        <hr/>
                        <p>Interested In: ${interestedInLabel}</p>
                        <p>First Name: ${firstName}</p>
                        <p>Last Name: ${lastName}</p>
                        <p>Email: ${email}</p>
                        <p>Interested Summary: ${interestedSummary}</p>
                        <p>Privacy Agree: ${privacyCheck ? 'Yes' : 'No'}</p>
                        <p>Subscribe Check: ${subscribeCheck ? 'Yes' : 'No'}</p>
                        <p>URL: ${document.location.href}</p>
                      `,
                      from_email: email,
                      from_name:
                        firstName + ' ' + lastName,
                      to: [{
                        email: toEmail,
                        type: 'to'
                      }]
                    };

                    fetch(
                      'https://mandrillapp.com/api/1.0/messages/send.json',
                      {
                        method: 'post',
                        body: JSON.stringify({
                          key: '0K9RSSnf1OxqWsXqaIYZQw',
                          message: mailData
                        })
                      }
                    ).then(responseData => {
                      if (responseData.ok) {
                        actions.resetForm();
                        this.setState({
                          msg: t('We will get back to you.'),
                          staySticky: true
                        });
                      } else {
                        this.setState({
                          msg: t('Something was wrong, try again.'),
                          staySticky: true
                        });
                      }

                      try {
                        window.dataLayer.push({
                          'event': 'formSubmission',
                          'data': {
                            component: 'QuickContactForm',
                            type: null
                          }
                        });
                      } catch (e) {
                        //
                      }

                      // Send user subscription
                      let formName = 'quick-contact-form';
                      if (subscribeCheck) {
                        spotlerSubscribe(email, firstName, lastName, formName);
                      }

                      // BCC
                      bcc(mailData.subject, mailData.html, toEmail, formName);
                    });
                  }}
                >
                  {({ values, errors }) => (
                    <Form
                      name="Form"
                      id="form"
                      className={classNames(
                        msg
                          ? 'ContactForm-none'
                          : 'ContactForm-form'
                      )}
                    >
                      <h5 className="ContactForm-selectHeading">
                        {t('I`m Interested In', pageLang)}
                      </h5>

                      <div className="outlineDropDown">
                        <Field
                          name="interestedIn"
                          id="interestedIn"
                          type="applicant"
                          options={categories}
                          component={MySelect}
                          value={values.interestedIn}
                          placeholder={'*' + t('I`m Interested In', pageLang)}
                        />
                      </div>

                      <Field
                        className={classNames(
                          'ContactForm-input',
                          values.firstName && !errors.firstName && 'ContactForm-imageWithIcon',
                          values.firstName && errors.firstName && 'QuickContactForm-imageWithErrorIcon'
                        )}
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder={'*' + t('Your first name', pageLang)}
                        autoComplete="off"
                        value={values.firstName}
                      />

                      <Field
                        className={classNames(
                          'ContactForm-input',
                          values.lastName && !errors.lastName && 'ContactForm-imageWithIcon',
                          values.lastName && errors.lastName && 'QuickContactForm-imageWithErrorIcon'
                        )}
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder={'*' + t('Your last name', pageLang)}
                        autoComplete="off"
                        value={values.lastName}
                      />

                      <Field
                        className={classNames(
                          'ContactForm-input',
                          values.email && !errors.email && 'ContactForm-imageWithIcon',
                          values.email && errors.email && 'QuickContactForm-imageWithErrorIcon'
                        )}
                        type="email"
                        name="email"
                        id="email"
                        placeholder={'*' + t('Your email', pageLang)}
                        autoComplete="off"
                        value={values.email}
                      />

                      <Field
                        name="interestedSummary"
                        id="interestedSummary"
                        value={values.info}
                        render={({ field }) => (
                          <textarea
                            {...field}
                            className={classNames(
                              'ContactForm-textarea ContactForm-textarea-simplified',
                              values.interestedSummary && !errors.interestedSummary && 'ContactForm-imageWithIcon',
                              values.interestedSummary && errors.interestedSummary && 'QuickContactForm-imageWithErrorIcon'
                            )}
                            placeholder={'*' + t('Your message', pageLang)}
                            maxLength="500"
                            rows="3"
                          >{values.interestedSummary}</textarea>
                        )}
                      />

                      <label className="custom-check">
                        {t('agree form text')}
                        <Field type="checkbox" name="privacyCheck" required={true} />
                        <span className="checkmark" />
                      </label>

                      <label className="custom-check">
                        {t('subscribe form text')}
                        <Field type="checkbox" name="subscribeCheck" />
                        <span className="checkmark" />
                      </label>

                      <p className="QuickContactForm-text">
                        {t('To write a full message go to our')}
                        &nbsp;
                        <a href="#" onClick={navigateToContact}>{t('contact form')}</a>
                        .
                        &nbsp;
                        {t('Your data will only be used by ISKO. Consult our')}
                        &nbsp;
                        <a href="#" onClick={navigateToPrivacy}>{t('Privacy Policy')}</a>
                        &nbsp;
                        {t('for further information')}
                      </p>

                      <button
                        className="ContactForm-button"
                        type="submit"
                        disabled={
                          (!values.interestedIn || errors.interestedIn)
                          || (!values.firstName || errors.firstName)
                          || (!values.lastName || errors.lastName)
                          || (!values.email || errors.email)
                          || (!values.interestedSummary || errors.interestedSummary)
                          || (!values.privacyCheck || errors.privacyCheck)
                        }
                      >
                        {t('Submit')}
                      </button>
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </div>
        </div>
      </section>
    );
  }
}

const QuickContactForm = props => (
  <StaticQuery
    query={graphql`
      query {
        allPrismicFooter {
          nodes {
            lang
            data {
              quick_contact_category {
                category_name
                category_email
              }
              quick_contact_hide_page {
                pages {
                  id
                  uid
                }
              }
            }
          }
        }
      }
    `}
    render={data => <QuickContactFormContainer prismic={data} {...props} />}
  />
);

export default QuickContactForm;
