import React from 'react';
import { object } from 'prop-types';
import { LinkWrap } from 'components';
import classNames from 'classnames';
import { Controller, Scene } from 'react-scrollmagic';
import ReactPlayer from 'react-player';

import { ImageWrap } from 'components';
import { ReactComponent as LongArrowRight } from 'svg/isko_icon_long-arrow-right.svg';
import './styles.scss';

const LargeSignpostModuleSm004 = (data) => {
  const {
    primary: {
      sm4_hide,
      sm4_section_alignment,
      sm4_section_rotate,
      sm4_title,
      sm4_description,
      sm4_link_name,
      sm4_link,
      sm4_link_rel,
      sm4_image,
      sm4_video_link
    }
  } = data;

  const iframeOptions = {
    width: '100%',
    height: '100%',
    playing: true,
    loop: true,
    controls: false,
    volume: 0,
    muted: true,
    playsinline: true
  };
  const video = sm4_video_link ? sm4_video_link.embed_url : null;
  return sm4_hide !== 'true' ? (
    <section
      className={classNames(
        'LargeSignpostModuleSm004',
        sm4_section_alignment === 'center'
          ? 'LargeSignpostModuleSm004--center'
          : sm4_section_alignment === 'left'
            ? 'LargeSignpostModuleSm004--left'
            : 'LargeSignpostModuleSm004--right'
      )}
    >
      <Controller>
        <Scene classToggle="is-visible" triggerHook={1}>
          <div>
            <LinkWrap
              className={classNames(
                'LargeSignpostModuleSm004-container',
                sm4_section_alignment === 'center' &&
                'LargeSignpostModuleSm004-container--center'
              )}
              rel={sm4_link_rel}
              to={sm4_link}
            >
              <div
                className={classNames(
                  'LargeSignpostModuleSm004-media',
                  sm4_section_alignment === 'left' &&
                  'LargeSignpostModuleSm004-media--left',
                  sm4_section_alignment === 'right' &&
                  'LargeSignpostModuleSm004-media--right',
                  sm4_section_rotate === 'landscape'
                    ? 'LargeSignpostModuleSm004-media--landscape'
                    : 'LargeSignpostModuleSm004-media--portrait'
                )}
              >
                {video !== null ? (
                  <ReactPlayer
                    className="LargeSignpostModuleSm004-video videoPlayer-overlay"
                    url={video}
                    {...iframeOptions}
                    config={{
                      vimeo: {
                        playerOptions: {
                          background: true
                        }
                      }
                    }}
                  />
                ) : (
                  <ImageWrap
                    className={classNames(
                      'LargeSignpostModuleSm004-image',
                      sm4_section_rotate === 'landscape'
                        ? 'LargeSignpostModuleSm004-image--landscape'
                        : 'LargeSignpostModuleSm004-image--portrait'
                    )}
                    image={sm4_image}
                  />
                )}
              </div>
              <div
                className={classNames(
                  'LargeSignpostModuleSm004-content',
                  sm4_section_alignment === 'center'
                    ? 'LargeSignpostModuleSm004-content--center'
                    : sm4_section_alignment === 'left'
                      ? 'LargeSignpostModuleSm004-content--left'
                      : 'LargeSignpostModuleSm004-content--right',
                  {
                    righLand:
                      sm4_section_rotate ===
                      'landscape' &&
                      sm4_section_alignment === 'right'
                  },
                  {
                    leftLand:
                      sm4_section_rotate ===
                      'landscape' &&
                      sm4_section_alignment === 'left'
                  }
                )}
              >
                {sm4_section_alignment === 'center' && (
                  <LongArrowRight className="LargeSignpostModuleSm004-nextPostBtn" />
                )}
                <div
                  className={classNames(
                    'LargeSignpostModuleSm004-textBox',
                    sm4_section_rotate === 'landscape'
                      ? 'LargeSignpostModuleSm004-textBox--landscape'
                      : 'LargeSignpostModuleSm004-textBox--portrait'
                  )}
                >
                  <h2
                    className={classNames(
                      'LargeSignpostModuleSm004-title',
                      sm4_section_alignment === 'center'
                        ? 'LargeSignpostModuleSm004-title--center'
                        : 'LargeSignpostModuleSm004-title--side'
                    )}
                  >
                    {sm4_title}
                  </h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sm4_description.html
                    }}
                    className={classNames(
                      'LargeSignpostModuleSm004-description',
                      sm4_section_alignment === 'center'
                        ? 'LargeSignpostModuleSm004-description--center'
                        : 'LargeSignpostModuleSm004-description--side'
                    )}
                  />
                  <span
                    className={classNames(
                      'LargeSignpostModuleSm004-subTitle',
                      {
                        isCentered:
                          sm4_section_alignment ===
                          'center'
                      }
                    )}
                  >
                                        {sm4_link_name}
                                    </span>
                </div>
              </div>
            </LinkWrap>
          </div>
        </Scene>
      </Controller>
    </section>
  ) : null;
};

// LargeSignpostModuleSm004.propTypes = {
//     primary: object.isRequired,
// };

export default LargeSignpostModuleSm004;
