import React from 'react';
import { string, object } from 'prop-types';
import Modal from 'react-modal';
import classNames from 'classnames';
import { t } from '../../utils/lang-helper';
import { Newsletter } from '../index';
import './styles.scss';

Modal.setAppElement('#___gatsby');

export default class NewsletterWrapper extends React.Component {
  static propTypes = {
    pageLang: string
  };

  state = {
    load: false,
    modalIsOpen: true
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      console.log('NP Set', new Date());

      this.showTimer = window.setTimeout(() => {
        this.setState({
          load: true
        });
      }, 15000);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      if (this.showTimer) {
        clearTimeout(this.showTimer);
      }
    }
  }


  closeModal = () => {
    this.setState({ modalIsOpen: false });
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('newsletterModalClosed', '1');
    }
  };

  render() {
    const {
      state: { load, modalIsOpen },
      closeModal
    } = this;

    // Show the modal
    let showModal = load;

    // Check is already closed
    if (typeof window !== 'undefined') {
      if (window.localStorage.getItem('newsletterModalClosed')) {
        showModal = false;
      }
    }
    // Don`t show the modal
    if (!showModal) {
      return null;
    }

    console.log('NP Show', new Date());

    return (
      <div>
        <Modal
          className={classNames('Modal', 'Modal-NewsletterWrapper', {
            'is-closed': !modalIsOpen
          })}
          closeTimeoutMS={300}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              zIndex: 999,
              backgroundColor: 'none'
            }
          }}
          shouldFocusAfterRender={false}
          shouldReturnFocusAfterClose={false}
        >
          <div className="Modal-decorate">
            <button className="Modal-close" onClick={closeModal} />

            <Newsletter
              className="Modal-newsLetter"
              gainBefore={t('Sign up to our mailing list to receive latest stories, news, events and much more.')}
              gainAfter={t('Congratulations! You are now subscribed to ISKO’s newsletter')}
              titleBefore={t('Never miss a story')}
              titleAfter={t('Thank you')}
              btnText={t('Continue')}
              isModal={true}
              closeModal={closeModal}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
