import React from 'react';
import { array, bool } from 'prop-types';
import classNames from 'classnames';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';
import { Controller, Scene } from 'react-scrollmagic';
import { navigate } from 'gatsby';

import { Layout } from 'components';
import './styles.scss';

class ModalPage extends React.Component {
  static propTypes = {
    children: array.isRequired,
    modify: bool
  };

  static defaultProps = {
    modify: false
  };

  componentDidMount() {
    const page = document.querySelector('.ModalPage');
    if (page) {
      return (page.scrollTop = 0);
    }
  }

  closeModal(closeTo) {
    const ModalEl = document.querySelector('.ModalPage');
    const ModalOverlay = document.querySelector('.ModalPage__Overlay');
    if (ModalEl) {
      ModalEl.classList.add('modalOut');
      if (ModalOverlay) {
        ModalOverlay.classList.remove(
          'ReactModal__Overlay--after-open'
        );
      }
      setTimeout(function() {
        navigate(closeTo, {
          state: { noScroll: true }
        });
      }, 1000);
    }
  }

  render() {
    const { children, modify, ...rest } = this.props;
    return (
      <ModalRoutingContext.Consumer>
        {({ modal, closeTo }) =>
          modal ? (
            <>
              <div
                className="ModalOverlayTrigger"
                onClick={() => this.closeModal(closeTo)}
              />
              <Controller>
                <Scene classToggle="is-visible" triggerHook={1}>
                  <section
                    className={classNames(
                      'ModalPage-wrapper',
                      {
                        'is-faq': modify
                      }
                    )}
                  >
                    {modify && (
                      <span className="ModalPage-faq">
                                                FAQ
                                            </span>
                    )}
                    <div
                      className="ArticleDetail-closeBox"
                      onClick={() =>
                        this.closeModal(closeTo)
                      }
                    />
                    <div
                      className={classNames(
                        'ModalPage-content',
                        {
                          'is-faq': modify
                        }
                      )}
                    >
                      {children}
                    </div>
                  </section>
                </Scene>
              </Controller>
            </>
          ) : (
            <Layout {...rest}>{children}</Layout>
          )
        }
      </ModalRoutingContext.Consumer>
    );
  }
}

export default ModalPage;
