import React from 'react';
import classNames from 'classnames';
import { isIOS } from 'react-device-detect';
import { throttle } from 'lodash';
import { isMobileOnly } from 'react-device-detect';
import { ReactComponent as SvgLongArrowUp } from 'svg/isko_icon_long-arrow-up.svg';
import './styles.scss';

export default class BackToTopButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleButton: false
    };
    this.throttleScroll = throttle(this._scrollVisibleButton, 50, {
      capture: true,
      passive: true
    });
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.throttleScroll);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.throttleScroll);
    }
  }

  _scrollVisibleButton = () => {
    if (window.pageYOffset < 3000) {
      return;
    }
    if (window.pageYOffset >= 3800 && isMobileOnly) {
      this.setState({
        isVisibleButton: true
      });
    } else if (window.pageYOffset >= 6000) {
      this.setState({
        isVisibleButton: true
      });
    } else {
      this.setState({
        isVisibleButton: false
      });
    }
  };

  _scrollStep = () => {
    if (window.pageYOffset === 0) {
      clearInterval(this.intervalId);
    }
    window.scroll(0, window.pageYOffset - 50);
  };

  _scrollToTop = () => {
    this.intervalId = setInterval(this._scrollStep, 0.8);
  };

  render() {
    return (
      <button
        title={`Back to top`}
        className={classNames('BackToTopButton', {
          'is-visibleButton': this.state.isVisibleButton,
          'BackToTopButton-forIOS': isIOS
        })}
        onClick={this._scrollToTop}
      >
        <SvgLongArrowUp />
      </button>
    );
  }
}
