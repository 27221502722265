import React from 'react';
import { Link } from 'gatsby-plugin-modal-routing';
import { isIOS } from 'react-device-detect';
import ReactPlayer from 'react-player';
import { getLocalePrefix } from '../../utils/lang-helper';
import { ImageWrap } from 'components';
import './styles.scss';

export default class StoryOfTheMonthSm001 extends React.Component {
  static propTypes = {
    // prismic: object.isRequired,
  };

  state = {
    isPlaying: true
  };

  handleVisibility = () => {
    if (document.visibilityState === 'visible') {
      this.setState({ isPlaying: false });
      this.setState({ isPlaying: true });
    }
  };

  componentDidMount() {
    if (isIOS) {
      document.addEventListener(
        'visibilitychange',
        this.handleVisibility
      );
    }
  }

  componentWillUnmount() {
    if (isIOS) {
      document.removeEventListener(
        'visibilitychange',
        this.handleVisibility
      );
    }
  }

  render() {
    const {
      sm1_hide,
      sm1_title,
      sm1_image,
      sm1_link_name,
      sm1_link_story,
      sm1_link_rel,
      sm1_video_link,
      sm1_section_title
    } = this.props.prismic ? this.props.prismic : {};
    const iframeOptions = {
      width: '100%',
      height: '100%',
      playing: true,
      loop: true,
      controls: false,
      volume: 0,
      playsinline: true
    };
    const videoUrl = sm1_video_link && sm1_video_link.embed_url !== null;
    const isBackgroundName = videoUrl ? 'video' : 'image';
    return sm1_hide === 'false' && sm1_link_story ? (
      <section className="StoryOfTheMonthSm001">
        <Link
          className="StoryOfTheMonthSm001-linkBox"
          to={getLocalePrefix() + sm1_link_story.uid}
          rel={sm1_link_rel}
          asModal
        >
          <div className="StoryOfTheMonthSm001-header">
            <p className="StoryOfTheMonthSm001-subTitle StoryOfTheMonthSm001-subTitle--before">
              {sm1_section_title}
            </p>
            <h3 className="StoryOfTheMonthSm001-title">
              {sm1_title}
            </h3>
            <p className="StoryOfTheMonthSm001-subTitle StoryOfTheMonthSm001-subTitle--after">
              {isBackgroundName}
            </p>
          </div>
          <div className="StoryOfTheMonthSm001-box">
            {videoUrl && this.state.isPlaying ? (
              <div className="StoryOfTheMonthSm001-videoWrap">
                <ReactPlayer
                  className="StoryOfTheMonthSm001-iframe videoPlayer-overlay"
                  url={sm1_video_link.embed_url}
                  {...iframeOptions}
                  config={{
                    vimeo: {
                      playerOptions: {
                        background: true
                      }
                    }
                  }}
                />
                <div className="StoryOfTheMonthSm001-capBox" />
              </div>
            ) : (
              <div className="StoryOfTheMonthSm001-imageWrap">
                <ImageWrap
                  className="StoryOfTheMonthSm001-image"
                  image={sm1_image}
                />
              </div>
            )}
          </div>
          <div className="StoryOfTheMonthSm001-footer">
                        <span className="StoryOfTheMonthSm001-link">
                            {sm1_link_name}
                        </span>
          </div>
        </Link>
      </section>
    ) : null;
  }
}
