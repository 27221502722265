import React from 'react';
import PropTypes from 'prop-types';
import { isIOS } from 'react-device-detect';
import { Controller, Scene } from 'react-scrollmagic';
import { CSSTransition } from 'react-transition-group';
import ReactPlayer from 'react-player';
import classNames from 'classnames';
import './styles.scss';
import ImageWrap from '../ImageWrap';

export default class IntroModuleEm003 extends React.PureComponent {
  static propTypes = {
    primary: PropTypes.instanceOf(Object).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      appearElements: true,
      isPlaying: true
    };
  }

  handleVisibility = () => {
    if (document.visibilityState === 'visible') {
      this.setState({ isPlaying: false });
      this.setState({ isPlaying: true });
    }
  };

  componentDidMount() {
    if (isIOS) {
      document.addEventListener(
        'visibilitychange',
        this.handleVisibility
      );
    }
  }

  componentWillUnmount() {
    if (isIOS) {
      document.removeEventListener(
        'visibilitychange',
        this.handleVisibility
      );
    }
  }

  render() {
    const { appearElements, isPlaying } = this.state;
    const {
      primary: {
        em3_hide,
        em3_section_alignment,
        em3_title,
        em3_video_link,
        em3_subtitle,
        em3_copy_top,
        em3_copy_bottom,
        em3_bg_image,
        em3_main_image
      }
    } = this.props;
    const iframeOptions = {
      width: '100%',
      height: '100%',
      loop: true,
      playsinline: true
    };
    const video = em3_video_link ? em3_video_link.embed_url : null;
    return em3_hide === 'false' ? (
      <>
        <section className="IntroSection">
          {video && isPlaying ? (
            <div className="IntroSection-videoWrap">
              <ReactPlayer
                className="IntroSection-video videoPlayer-overlay"
                url={video}
                {...iframeOptions}
                config={{
                  vimeo: {
                    playerOptions: {
                      background: true
                    }
                  }
                }}
              />
            </div>
          ) : (
            em3_bg_image && (
              <figure className="IntroSection-imgBgWrap">
                <ImageWrap
                  image={em3_bg_image}
                  className="IntroSection-bgimage"
                />
              </figure>
            )
          )}
          <CSSTransition
            in={appearElements}
            appear={true}
            timeout={600}
            classNames="fading"
          >
            <div
              className={classNames(
                'IntroSection-copytop',
                em3_section_alignment === 'right'
                  ? 'IntroSection-copytop--right'
                  : 'IntroSection-copytop--left'
              )}
            >
              {em3_subtitle && (
                <span className="IntroSection-subtitle">
                    {em3_subtitle.text}
                </span>
              )}
              {em3_title && (
                <h2 className="IntroSection-title">
                  {em3_title}
                </h2>
              )}
              {em3_copy_top && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: em3_copy_top.html
                  }}
                  className="IntroSection-topParagraf"
                />
              )}
            </div>
          </CSSTransition>
          {em3_main_image && (
            <CSSTransition
              in={appearElements}
              appear={true}
              timeout={600}
              classNames="fading"
            >
              <figure className="IntroSection-imgMainWrap">
                <ImageWrap
                  image={em3_main_image}
                  className="IntroSection-mainImage"
                />
              </figure>
            </CSSTransition>
          )}
        </section>
        {em3_copy_bottom.text && (
          <Controller>
            <Scene classToggle="is-visible" triggerHook={1}>
              <article
                className={classNames('IntroArticle', {
                  withoutMainImage: !em3_main_image
                })}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: em3_copy_bottom.html
                  }}
                  className="IntroArticle-copybottom"
                />
              </article>
            </Scene>
          </Controller>
        )}
      </>
    ) : null;
  }
}
