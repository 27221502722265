import React, { Component } from 'react';
import { arrayOf, oneOfType, node, object, string, bool } from 'prop-types';

import {
  Navigation,
  Footer,
  BackToTopButton,
  MoreDetailsButton,
  SEO,
  PrivacyPopUp,
  QuickContactForm,
  NewsletterWrapper
} from './../components';

export default class Layout extends Component {
  static propTypes = {
    children: oneOfType([arrayOf(node), node]).isRequired,
    pageType: string,
    faq_link: string,
    newsletter: string,
    navAlwaysVisible: bool,
    pageLang: string
  };

  static defaultProps = {
    pageType: '',
    faq_link: '',
    newsletter: 'hidden',
    navAlwaysVisible: false
  };

  render() {
    const {
      props: {
        children,
        pageType,
        faq_link,
        newsletter,
        navAlwaysVisible,
        pageLang
      }
    } = this;

    let contentProp;

    switch (pageType) {
      case 'product':
      case 'service':
      case 'contact':
        contentProp = 'more detail';
        break;
      default:
        contentProp = undefined;
        break;
    }

    return (
      <>
        <SEO />
        <Navigation
          pageLang={pageLang}
          alwaysVisible={navAlwaysVisible}
        />
        {children}
        {contentProp && faq_link ? (
          <MoreDetailsButton content={contentProp} link={faq_link} />
        ) : null}
        <BackToTopButton />
        <QuickContactForm pageLang={pageLang} />
        <Footer footerClass="Footer" pageLang={pageLang} />
        <PrivacyPopUp />

        {newsletter === 'show' ? (
          <NewsletterWrapper pageLang={pageLang} />
        ) : null}
      </>
    );
  }
}
