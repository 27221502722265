import React, { Component } from 'react';
import { object } from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { TweenMax, TweenLite, Power0, Power2 } from 'gsap';
import ReactPlayer from 'react-player';
import { isIOS } from 'react-device-detect';
import { throttle, debounce } from 'lodash';

import { ReactComponent as LogoTitle } from 'svg/logo-full-width.svg';
import { ReactComponent as SvgSpeakerOn } from 'svg/isko_icon_video-noise.svg';
import { ReactComponent as SvgSpeakerOff } from 'svg/isko_icon_video-mute.svg';
import './styles.scss';

class FullScreenMoodVideoEm001Container extends Component {
  state = {
    isSvgSpeaker: true,
    isSoundMute: true,
    iframeURL: '',
    isIframeVideo: false,
    isPlaying: true
  };

  static propTypes = {
    prismic: object.isRequired
  };

  handleVisibility = () => {
    if (document.visibilityState === 'visible') {
      this.setState({ isPlaying: false });
      this.setState({ isPlaying: true });
    }
  };

  componentDidMount() {
    if (isIOS) {
      document.addEventListener(
        'visibilitychange',
        this.handleVisibility
      );
    }
    let backgroundSection = document.querySelectorAll('.js-videoSection');
    let backgroundVideo = document.querySelectorAll('.js-videoTag');
    let logoTitle = document.querySelectorAll('.js-logoTitle');

    TweenMax.to(logoTitle, 0.5, {
      opacity: 1,
      zIndex: 0,
      ease: Power0.easeNone
    });
    TweenMax.to(backgroundVideo, 0.2, {
      opacity: 1,
      ease: Power0.easeNone
    });
    object.isRequired,
      TweenMax.to(backgroundSection, 1, {
        scale: 1,
        ease: Power0.easeNone
      });

    this._selectVideoSource();
    this._videoPlayer();

    document.addEventListener(
      'scroll',
      throttle(this._hideLogoAfterScrollPage, 30, {
        capture: true,
        passive: true
      })
    );

    backgroundSection[0].addEventListener('click', () => {
      if (typeof window !== 'undefined') {
        if (window.pageYOffset === 0) {
          this._scrollingTween();
        }
      }
    });
    debounce(
      () => {
        if (typeof window !== 'undefined') {
          if (window.pageYOffset === 0) {
            this._scrollingTween();
          }
        }
      },
      3500,
      false
    )();
  }

  componentWillUnmount() {
    if (isIOS) {
      document.removeEventListener(
        'visibilitychange',
        this.handleVisibility
      );
    }
    document.removeEventListener('scroll', this._hideLogoAfterScrollPage);
    document.removeEventListener('click', this._scrollingTween);
  }

  // Method that allows you to hide background section logo after scrolling a page by more than 1px.
  // Also produces a parallax background effect.
  // To enable the method, uncomment the call throttle(this._hideLogoAfterScrollPage, 30).
  _hideLogoAfterScrollPage = () => {
    let backgroundSection = document.querySelectorAll('.js-videoSection');
    let logoTitle = document.querySelectorAll('.js-logoTitle');
    let scrolled = window.pageYOffset;
    TweenMax.to(logoTitle, 0.2, {
      opacity: 0,
      zIndex: -1,
      ease: Power0.easeNone
    });
    if (backgroundSection.length && scrolled === 0) {
      TweenMax.to(logoTitle, 0.3, {
        opacity: 1,
        zIndex: 0,
        ease: Power0.easeNone
      });
      backgroundSection[0].style.top = scrolled * 0.2 + 'px';
    }
  };

  // Method that scrolls a section when a user clicks a section.
  // Also set the height of the necessary scroll (scrollTop).
  // To disable the method, comment the call this._scrollingTween();
  _scrollingTween = () => {
    const scrollAnimation = { scrollTop: 0 };
    let scrollTop = 60;
    if (window.matchMedia('(max-width: 980px)').matches) {
      scrollTop = 160;
    }

    const scrollTween = TweenLite.to(scrollAnimation, 0.5, {
      scrollTop: scrollTop,
      ease: Power2.easeInOut,
      onUpdate: () => {
        if (window.pageYOffset < scrollTop - 1) {
          window.scrollTo(0, scrollAnimation.scrollTop);
        }
      }
    });

    window.addEventListener(
      'mousewheel',
      throttle(mouseHandler, 30, {
        capture: true,
        passive: true
      })
    );

    function mouseHandler() {
      scrollTween.kill();
      window.removeEventListener('mousewheel', mouseHandler);
    }
  };

  // Method that Sound turns on and off the sound of the video section.
  _soundUnmute = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isSvgSpeaker: !this.state.isSvgSpeaker,
      isSoundMute: !this.state.isSoundMute
    });
  };

  // Method determine and select the source of the video.
  _selectVideoSource = () => {
    const em1_video_link = this.props
    && this.props.prismic
    && this.props.prismic.primary
    && this.props.prismic.primary.em1_video_link
      ? this.props.prismic.primary.em1_video_link : '';

    let videoURL = em1_video_link;
    let videoId = videoURL.split('.com/')[1];

    if (videoURL.includes('vimeo')) {
      // vimeo video
      videoId = videoId.split('?')[0];
      this.setState({
        iframeURL: `//vimeo.com/${videoId}`
      });
    } else if (videoURL.includes('youtube')) {
      // youtube video
      videoId = videoId.split('v=')[1];
      this.setState({
        iframeURL: `//www.youtube.com/watch?v=${videoId}`
      });
    } else {
      // html5 video
      this.setState({
        isIframeVideo: true
      });
    }
  };

  _videoPlayer = () => {
    const {
      state: { isIframeVideo, iframeURL, isSoundMute, isPlaying }
    } = this;

    const em1_video_link = this.props
    && this.props.prismic
    && this.props.prismic.primary
    && this.props.prismic.primary.em1_video_link
      ? this.props.prismic.primary.em1_video_link : '';

    const videoOptions = {
      loop: true,
      autoPlay: true,
      controls: true,
      muted: isSoundMute
    };
    const iframeOptions = {
      width: '100vw',
      height: '56.5vw',
      playing: true,
      loop: true,
      controls: false,
      volume: 1,
      muted: isSoundMute,
      playsinline: true
    };
    return isIframeVideo ? (
      <video
        className="FullScreenMoodVideoEm001-video js-videoTag"
        {...videoOptions}
      >
        <source src={em1_video_link} type="video/mp4" />
      </video>
    ) : isPlaying ? (
      <ReactPlayer
        className="FullScreenMoodVideoEm001-iframe js-videoTag videoPlayer-overlay"
        url={iframeURL}
        {...iframeOptions}
        config={{
          vimeo: {
            playerOptions: {
              background: true
            }
          }
        }}
      />
    ) : null;
  };

  render() {
    const videoPlayer = this._videoPlayer();
    const {
      state: { isSvgSpeaker }
    } = this;
    const SvgSpeaker = isSvgSpeaker ? <SvgSpeakerOff /> : <SvgSpeakerOn />;

    return (
      <section className="FullScreenMoodVideoEm001 js-videoSection">
        <div className="FullScreenMoodVideoEm001-box js-backgroundVideo">
          {videoPlayer}
        </div>
        <div
          className="FullScreenMoodVideoEm001-muted"
          onClick={this._soundUnmute}
        >
          {SvgSpeaker}
        </div>
        <div className="FullScreenMoodVideoEm001-logoTitle js-logoTitle">
          <LogoTitle />
        </div>
      </section>
    );
  }
}

const FullScreenMoodVideoEm001 = ({ primary }) => {
  return <FullScreenMoodVideoEm001Container prismic={primary} />;
};

FullScreenMoodVideoEm001.propTypes = {
  primary: object.isRequired
};

export default FullScreenMoodVideoEm001;
