import React from 'react';
import { object } from 'prop-types';
import { Scene, Controller } from 'react-scrollmagic';
import classNames from 'classnames';

import { ImageWrap } from 'components';
import './styles.scss';
import { LinkWrap } from 'components';

const FullWidthSignpostSm002 = ({
  primary: {
    sm2_hide,
    sm2_link,
    sm2_link_rel,
    sm2_link_name,
    sm2_title,
    sm2_description,
    sm2_image
  }
}) =>
  sm2_hide === 'false' ? (
    <Controller>
      <Scene classToggle="is-visible" triggerHook={1}>
        <section className="FullWidthSignpostSm002 js-fullWidthSignpost">
          <LinkWrap
            className="FullWidthSignpostSm002-linkBox"
            to={sm2_link}
            rel={sm2_link_rel}
          >
            <div className="FullWidthSignpostSm002-imgWrap">
              <ImageWrap
                className="FullWidthSignpostSm002-image"
                image={sm2_image}
              />
            </div>
            <div
              className={classNames(
                'FullWidthSignpostSm002-container'
              )}
            >
              <h2 className="FullWidthSignpostSm002-title">
                {sm2_title}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: sm2_description.html
                }}
                className="FullWidthSignpostSm002-text"
              />
              <span className="FullWidthSignpostSm002-linkName">
                                {sm2_link_name}
                            </span>
            </div>
          </LinkWrap>
        </section>
      </Scene>
    </Controller>
  ) : null;

FullWidthSignpostSm002.propTypes = {
  primary: object.isRequired
};

export default FullWidthSignpostSm002;
