import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';
import { Controller, Scene } from 'react-scrollmagic';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import { Link } from 'gatsby';
import { getLocalePrefix, t } from '../../utils/lang-helper';
import { ReactComponent as ArrowRight } from 'svg/arrow-right.svg';
import './styles.scss';
import { spotlerSubscribe } from '../../utils/spotler-subscribe';
import { bcc } from '../../utils/bcc';

export const schema = yup.object().shape({
  email: yup
    .string()
    .email(t('Invalid email'))
    .required(t('Email is required'))
});

export default class Newsletter extends Component {
  static propTypes = {
    className: string,
    titleBefore: string.isRequired,
    titleAfter: string.isRequired,
    gainBefore: string.isRequired,
    gainAfter: string.isRequired,
    btnText: string.isRequired,
    isModal: bool,
    closeModal: func
  };

  static defaultProps = {
    className: '',
    isModal: false,
    closeModal: null
  };

  state = {
    isSubmitted: false
  };

  toggleFormStatus = () => {
    this.setState({ isSubmitted: false });
  };

  // The following three methods will simply render this or that content depending on the stage of the submission.
  NewsletterBase = ({ children, title, gain, className, ...rest }) => {
    return (
      <div {...rest} className={classNames('Newsletter-step', className)}>
        <h2 className="Newsletter-title">{title}</h2>
        <p className="Newsletter-gain">{gain}</p>
        {children}
      </div>
    );
  };

  render() {
    const {
      state: {
        isSubmitted
      },
      props: {
        className,
        btnText,
        titleAfter,
        gainAfter,
        isModal,
        closeModal,
        gainBefore,
        titleBefore
      }
    } = this;

    const {
      NewsletterBase,
      toggleFormStatus
    } = this;

    const newsletterSubscribe = async (email) => {
      let formName = 'newsletter-modal';
      await spotlerSubscribe(email, null, null, formName);

      // BCC
      bcc('Newsletter subscribe', 'Newsletter subscribe', email, formName);

      this.setState({
        isSubmitted: true
      });
    };

    return (
      <div className={classNames('Newsletter', className, { 'is-submitted': isSubmitted })}>
        <NewsletterBase title={titleAfter} gain={gainAfter} className="Newsletter-after">
          <button
            className="Newsletter-buttonReset"
            onClick={isModal ? closeModal : toggleFormStatus}
          >
            {btnText}
          </button>
        </NewsletterBase>

        <NewsletterBase title={titleBefore} gain={gainBefore} className="Newsletter-before">
          <Formik
            initialValues={{
              email: '',
              privacyCheck: false
            }}
            validationSchema={schema}
            onSubmit={async (data, actions) => {
              await newsletterSubscribe(data.email, actions);
            }}
          >
            {({ values, errors }) => {
              return (
                <Form className="Newsletter-form" name="Form">
                  <Field
                    className="Newsletter-input"
                    type="email"
                    name="email"
                    autoComplete="off"
                    placeholder={t(
                      'Enter your e-mail address here'
                    )}
                    value={values.email}
                  />

                  <div className="Newsletter-invalid-wrapper">
                    {errors.email && values.email && (
                      <Controller>
                        <Scene classToggle="is-visible" triggerHook={1}>
                          <div className="Newsletter-invalid">
                            {errors.email}
                          </div>
                        </Scene>
                      </Controller>
                    )}

                    {
                      (!errors.email && values.email)
                      && (!values.privacyCheck || errors.privacyCheck)
                      && (
                        <Controller>
                          <Scene classToggle="is-visible" triggerHook={1}>
                            <div className="Newsletter-invalid">
                              {t('agree with privacy policy')}
                            </div>
                          </Scene>
                        </Controller>
                      )}

                    <Controller>
                      <Scene classToggle="is-visible" triggerHook={1}>
                        <button
                          type="submit"
                          className="Newsletter-button"
                          data-eemail={errors.email}
                          data-vemail={values.email}
                          data-eprivacyCheck={errors.privacyCheck}
                          data-vprivacyCheck={values.privacyCheck}
                          disabled={!values.email || !values.privacyCheck}
                        >
                          <span className="Newsletter-btnText">{t('Submit')}</span>
                          <ArrowRight className="Newsletter-arrowRight" />
                        </button>
                      </Scene>
                    </Controller>
                  </div>

                  <label className="custom-check">
                    {t('agree form text')}
                    <Field type="checkbox" name="privacyCheck" required={true} />
                    <span className="checkmark" />
                  </label>

                  <div className="Newsletter-faq">
                    <Controller>
                      <Scene classToggle="is-visible" triggerHook={1}>
                        <div className="Newsletter-faqText">
                          <p>
                            {t('newsletter start text 1')}
                            &nbsp;
                            {t('newsletter start text 2')}
                            &nbsp;
                            <Link to={getLocalePrefix() + 'cookie-policy'}>{t('Privacy Policy')}</Link>
                            &nbsp;
                            {t('newsletter start text 3')}
                          </p>
                        </div>
                      </Scene>
                    </Controller>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </NewsletterBase>
      </div>
    );
  }
}
