import React, { Component } from 'react';
import { isMobileOnly, isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { object, array } from 'prop-types';
import { navigate } from 'gatsby';
import Slider from 'react-slick';
import { getLocalePrefix } from '../../utils/lang-helper';
import { ProductCard } from 'components';
import './styles.scss';

export default class RelatedProductsCarouselRm001 extends Component {
  static propTypes = {
    primary: object.isRequired,
    items: array.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      videoLoop: false,
      index: 0
    };
    this.hasBeenTouchedRecently = false;
  }

  _settings = () => ({
    className: 'RPCarousel-slider',
    slidesToShow: isMobileOnly ? 2.2 : 3.8,
    slidesToScroll: 1,
    arrows: isMobileOnly ? false : true,
    swipeToSlide: true,
    speed: 500,
    easing: 'ease-in-out',
    infinite: false,
    beforeChange: () => {
      if (isMobileOnly) {
        return;
      }
      const prev = document.querySelector('.slick-prev');
      const next = document.querySelector('.slick-next');
      prev.style.opacity = 0;
      prev.style.transition = 'opacity .1s ease-in';
      next.style.opacity = 0;
      next.style.transition = 'opacity .1s ease-in';
    },
    afterChange: () => {
      if (isMobileOnly) {
        return;
      }
      const prev = document.querySelector('.slick-prev');
      const next = document.querySelector('.slick-next');
      prev.style.opacity = 1;
      prev.style.transition = 'opacity .2s ease-in';
      next.style.opacity = 1;
      next.style.transition = 'opacity .2s ease-in';
    }
  });

  handleMouseEnter = i => {
    this.hoverTime = setTimeout(() => {
      !isMobile && this.setState({ index: i, videoLoop: true });
    }, 500);
  };

  handleMouseLeave = () => {
    !isMobile && this.setState({ videoLoop: false });
    clearTimeout(this.hoverTime);
  };

  handleClick = url => {
    if (this.hasBeenTouchedRecently) {
      return;
    }
    navigate(getLocalePrefix() + url);
  };

  handleTouchStart = i => {
    this.buttonPressTimer = setTimeout(() => {
      this.hasBeenTouchedRecently = true;
      this.setState({
        index: i,
        videoLoop: true
      });
    }, 800);
  };

  handleTouchEnd = () => {
    this.hasBeenTouchedRecently = false;
    clearTimeout(this.buttonPressTimer);
  };

  componentWillUnmount() {
    clearTimeout(this.buttonPressTimer);
    clearTimeout(this.hoverTime);
  }

  render() {
    const {
      primary: {
        rm1_title,
        rm3_title,
        rm1_hide,
        rm3_hide,
        rm3_background
      },
      items
    } = this.props;
    const title = rm1_title || rm3_title;
    const hide = rm1_hide || rm3_hide;
    const { videoLoop, index } = this.state;
    return hide === 'false' ? (
      <section
        className={classNames(
          'RPCarousel',
          rm3_background === 'darker' && 'RPCarousel--is-darker'
        )}
      >
        <h2 className="RPCarousel-title">{title.text}</h2>
        <Slider {...this._settings()}>
          {items.map((el, i) => {
            const prod =
              el.rm1_related_product ||
              el.rm3_related_lifestyle_page;
            const item = prod && prod.document;
            return item && item.data ? (
              <ProductCard
                key={item.uid}
                arrayIndex={i}
                carousel={true}
                stateIndex={index}
                videoUrl={item.data?.lister_video_link?.embed_url}
                image={item.data?.lister_image}
                name={item.data?.lister_title}
                description={item.data?.lister_description}
                videoLoop={videoLoop}
                handleMouseEnter={() =>
                  this.handleMouseEnter(i)
                }
                handleMouseLeave={this.handleMouseLeave}
                handleTouchStart={() =>
                  this.handleTouchStart(i)
                }
                handleTouchEnd={this.handleTouchEnd}
                handleClick={() => this.handleClick(item.uid)}
              />
            ) : null;
          })}
        </Slider>
        <hr
          className={classNames(
            'RPCarousel-separator',
            rm3_background === 'darker' &&
            'RPCarousel-separator--adapt'
          )}
        />
      </section>
    ) : null;
  }
}
