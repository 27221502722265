import React from 'react';
import { string, bool, func } from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

export default class FilterItem extends React.Component {
  static propTypes = {
    tagName: string.isRequired,
    checked: bool.isRequired,
    resetCheck: bool.isRequired,
    filterHandler: func.isRequired,
    routedHandler: func.isRequired,
    slug: string.isRequired,
    routedTag: string
  };

  static defaultProps = {
    routedTag: null
  };

  constructor(props) {
    super(props);
    this.state = { isChecked: props.checked };
    props.routedTag && props.routedHandler(props.routedTag);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.resetCheck &&
      !this.props.checked &&
      prevState.isChecked &&
      this.props.routedTag === null
    ) {
      this.setState({ isChecked: !prevState.isChecked });
    }
    if (
      this.props.routedTag !== prevProps.routedTag &&
      this.props.routedTag !== null
    ) {
      this.setState({ isChecked: true });
      this.props.routedHandler(this.props.routedTag);
    }
  }

  checkHandler = () => {
    this.setState(state => ({ isChecked: !state.isChecked }));
    this.props.filterHandler(this.props.slug);
  };

  render() {
    const { tagName } = this.props;
    const { isChecked } = this.state;
    return (
      <li className={classNames('FilterItem', isChecked && 'is-checked')}>
        <label className="FilterItem-label">
          <input
            type="checkbox"
            className="FilterItem-checkbox"
            onChange={this.checkHandler}
            checked={isChecked}
          />
          <span className="FilterItem-name">{tagName}</span>
        </label>
      </li>
    );
  }
}
