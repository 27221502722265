import React from 'react';
import { string, object } from 'prop-types';
import { Link } from 'gatsby-plugin-modal-routing';
import { ImageWrap } from 'components';
import { getLocalePrefix, t } from '../../utils/lang-helper';
import './styles.scss';

const PreviewOfNextStory = ({ uid, title, image, locationKey }) => (
  (uid !== 'nnn111-story' ?
    <section className="PreviewOfNextStory">
      <Link
        to={getLocalePrefix() + uid}
        asModal={locationKey !== 'initial'}
        className="PreviewOfNextStory-container"
      >
        <div className="PreviewOfNextStory-imgWrap">
          <ImageWrap image={image} className="PreviewOfNextStory-image" />
        </div>
        <div className="PreviewOfNextStory-content">
                <span className="PreviewOfNextStory-subTitle">
                    {t('NEXT STORY')}
                </span>
          <h2 className="PreviewOfNextStory-title">{title}</h2>
        </div>
      </Link>
    </section>
    : null)
);

PreviewOfNextStory.propTypes = {
  uid: string.isRequired,
  title: string.isRequired,
  image: object.isRequired,
  locationKey: string.isRequired
};

export default PreviewOfNextStory;
