import React from 'react';
import { object } from 'prop-types';
import { isMobileOnly, isIOS } from 'react-device-detect';
import classNames from 'classnames';
import { throttle } from 'lodash';
import ReactPlayer from 'react-player';

import { ImageWrap } from 'components';
import { ReactComponent as SvgSpeakerOn } from 'svg/isko_icon_video-noise.svg';
import { ReactComponent as SvgSpeakerOff } from 'svg/isko_icon_video-mute.svg';
import './styles.scss';

export default class VideoModuleEm005 extends React.PureComponent {
  static propTypes = {
    primary: object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isSvgSpeaker: true,
      isSoundMute: true,
      isVideoPlaying: false
    };
    this.node = React.createRef();
    this.throttleScroll = throttle(this.turnOnOff, 400, {
      trailing: false
    });
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.props.primary.em5_video_link &&
      window.addEventListener('scroll', this.throttleScroll);
    }
  }

  componentWillUnmount() {
    this.node = null;
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.throttleScroll);
    }
  }

  turnOnOff = () => {
    if (!this.node) {
      return;
    }
    if (this.node.current.getBoundingClientRect().bottom < 0) {
      return this.setState({ isVideoPlaying: false });
    } else if (this.node.current.getBoundingClientRect().top - 1100 < 0) {
      return this.setState({ isVideoPlaying: true });
    } else if (this.node.current.getBoundingClientRect().top - 1100 > 0) {
      return this.setState({ isVideoPlaying: false });
    }
  };

  soundChange = () => {
    this.setState({
      isSvgSpeaker: !this.state.isSvgSpeaker,
      isSoundMute: !this.state.isSoundMute
    });
  };

  onPlay = () => {
    this.setState({ isVideoPlaying: true });
  };

  onPause = () => {
    this.setState({ isVideoPlaying: false });
  };

  render() {
    const {
      primary: {
        em5_hide,
        em5_section_alignment,
        em5_description,
        em5_bottom_title,
        em5_bottom_description,
        em5_video_thumbnail,
        em5_video_behavior,
        em5_video_link
      }
    } = this.props;
    const { isSoundMute, isSvgSpeaker, isVideoPlaying } = this.state;
    const SvgSpeaker = isSvgSpeaker ? <SvgSpeakerOff /> : <SvgSpeakerOn />;
    const iframeOptions = {
      width: '100%',
      height: '100%',
      playing: this.state.isVideoPlaying,
      loop: true,
      controls: false,
      volume: 1,
      muted: isSoundMute,
      playsinline: true
    };

    return em5_hide === 'false' ? (
      <section ref={this.node} className="VideoModuleEm005-content">
        {em5_section_alignment === 'left'
          ? null
          : em5_description && (
          <div
            dangerouslySetInnerHTML={{
              __html: em5_description.html
            }}
            className="VideoModuleEm005-prologue"
          />
        )}
        {em5_video_link ? (
          <section
            className={classNames('VideoModuleEm005-video', {
              leftAlignmentVideo:
                em5_section_alignment === 'left'
            })}
          >
            <div
              className={classNames(
                'VideoModuleEm005-videoWrap',
                {
                  leftAlignmentWrap:
                    em5_section_alignment === 'left'
                }
              )}
            >
              {em5_video_behavior === 'backdrop' ||
              isVideoPlaying ? (
                <ReactPlayer
                  className="VideoModuleEm005-videoPlayer videoPlayer-overlay"
                  onPause={this.onPause}
                  url={em5_video_link}
                  {...iframeOptions}
                  config={{
                    vimeo: {
                      playerOptions: {
                        background: true
                      }
                    }
                  }}
                />
              ) : (
                <ImageWrap
                  image={em5_video_thumbnail}
                  className="VideoModuleEm005-videoImage"
                />
              )}
              {em5_video_behavior === 'standard' && (
                <button
                  onClick={this.onPlay}
                  className={classNames(
                    'VideoModuleEm005-play',
                    isVideoPlaying &&
                    'VideoModuleEm005-hideButton'
                  )}
                  type="button"
                />
              )}
              <button
                onClick={this.soundChange}
                className={classNames(
                  'VideoModuleEm005-muted',
                  isIOS && 'VideoModuleEm005-muted--forIOS'
                )}
                type="button"
              >
                {SvgSpeaker}
              </button>
            </div>
          </section>
        ) : (
          <ImageWrap
            image={em5_video_thumbnail}
            className={classNames('VideoModuleEm005-image', {
              withoutShadow: em5_section_alignment === 'left'
            })}
          />
        )}
        {em5_bottom_title && em5_bottom_description.html && (
          <div
            className={classNames('VideoModuleEm005-epilogue', {
              bottomAlignment: em5_section_alignment === 'left'
            })}
          >
            <div className="VideoModuleEm005-textWrap">
              <h2
                className={classNames(
                  'VideoModuleEm005-bottomTitle',
                  {
                    bottomAlignment:
                      em5_section_alignment === 'left'
                  }
                )}
              >
                {em5_bottom_title}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: em5_bottom_description.html
                }}
                className={classNames(
                  'VideoModuleEm005-description',
                  {
                    bottomAlignment:
                      em5_section_alignment === 'left'
                  }
                )}
              />
            </div>
          </div>
        )}
      </section>
    ) : isMobileOnly ? (
      <section className="VideoModuleEm005-content">
        {em5_section_alignment === 'left' ? null : (
          <div
            dangerouslySetInnerHTML={{
              __html: em5_description.html
            }}
            className="VideoModuleEm005-prologue"
          />
        )}
        {em5_bottom_title && em5_bottom_description.html && (
          <div className="VideoModuleEm005-epilogue">
            <h2 className="VideoModuleEm005-bottomTitle">
              {em5_bottom_title}
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: em5_bottom_description.html
              }}
              className="VideoModuleEm005-description"
            />
          </div>
        )}
      </section>
    ) : (
      <section className="VideoModuleEm005-content--noIntroModule">
        {em5_section_alignment === 'left' ? null : (
          <div
            dangerouslySetInnerHTML={{
              __html: em5_description.html
            }}
            className="VideoModuleEm005-prologue"
          />
        )}
        <div className="VideoModuleEm005-epilogue--right">
          <h2 className="VideoModuleEm005-bottomTitle">
            {em5_bottom_title}
          </h2>
          <div
            dangerouslySetInnerHTML={{
              __html: em5_bottom_description.html
            }}
            className="VideoModuleEm005-description"
          />
        </div>
        <div />
      </section>
    );
  }
}
