import React from 'react';
import { array, number, bool } from 'prop-types';
import Select from 'react-select';
import Region from 'components/Region';
import './styles.scss';

export default class RegionalRepresentatives extends React.Component {
  static propTypes = {
    index: number.isRequired,
    isAppeared: bool.isRequired,
    stateIndex: number,
    items: array.isRequired,
    options: array.isRequired
  };

  static defaultProps = {
    stateIndex: null
  };

  constructor(props) {
    super(props);
    this.state = { currentOption: props.items.length ? props.items[0] : null };
  }

  optionHandler = option => {
    const { items } = this.props;
    items.forEach(el => {
      if (el.primary && el.primary.cfm41_region_name === option) {
        this.setState({ currentOption: el });
      }
    });
  };

  render() {
    const { index, stateIndex, isAppeared, options } = this.props;
    const { currentOption } = this.state;
    return (
      index === stateIndex &&
      isAppeared && (
        <section className="Representatives">
          <div className="Representatives-container">
            <div className="selectWrap">
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={options}
                value={options.find(
                  op =>
                    currentOption.primary
                    && op.value ===
                    currentOption.primary.cfm41_region_name
                )}
                onChange={op => this.optionHandler(op.value)}
              />
            </div>
            <Region item={currentOption} />
          </div>
        </section>
      )
    );
  }
}
