import React from 'react';
import { object } from 'prop-types';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import classNames from 'classnames';
import { elementHasClass } from '../../utils';
import { t } from '../../utils/lang-helper';
import './styles.scss';

const moment = extendMoment(Moment);

export default class BirthdayPicker extends React.PureComponent {
  static propTypes = {
    form: object.isRequired,
    field: object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      view: 'year',
      day: undefined,
      month: undefined,
      year: undefined
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    this.myRef = null;
  }

  handleClickOutside = event => {
    if (!this.myRef.current.contains(event.target)) {
      this.setState({ showDropdown: false });
    }
  };

  handleClick = event => {
    const { showDropdown } = this.state;
    const { target } = event;

    if (elementHasClass(target, ['BirthdayPicker-input'], 2)) {
      this.toggleFocus(!showDropdown);
    } else if (
      !elementHasClass(
        target,
        [
          'BirthdayPicker-dropdown',
          'BirthdayPicker-info',
          'BirthdayPicker-content',
          'BirthdayPicker-label'
        ],
        4
      )
    ) {
      this.toggleFocus(false);
    }
  };

  onChange = () => {
    const { day, month, year } = this.state;
    const { form, field } = this.props;
    const date = moment(`${year}-${month}-${day}`, 'Y-MMM-DD');
    if (date._i.slice(-9) === 'undefined') {
      form.setFieldError(
        field.name,
        t('Date of birth must contain day, month and year')
      );
    } else {
      form.setFieldValue(field.name, date.format('DD-MM-Y'));
    }
  };

  toggleFocus = value => this.setState({ showDropdown: value });

  createYears = () => {
    const range = moment.range(
      moment().subtract(100, 'years'),
      moment().subtract(18, 'years')
    );
    const years = Array.from(range.by('year')).reverse();
    return years.map(obj => obj.format('Y'));
  };

  createMonths = () => moment.months();

  createDays = (newMonth = null) => {
    const { year } = this.state;
    const month = newMonth || this.state.month;
    let daysInMonth = moment(month || 'January', 'MMM').daysInMonth();
    if (moment([year]).isLeapYear() && month === moment.months()[1]) {
      daysInMonth += 1;
    }
    return Array(daysInMonth)
      .fill()
      .map((values, index) => index + 1);
  };

  setView = view => this.setState({ view });

  setValue = date => {
    const { view, day, month } = this.state;

    if (view === 'day') {
      this.setState({ day: date }, () => this.onChange());
      this.toggleFocus(false);
    } else if (view === 'month') {
      const newState = {
        day,
        month: date
      };

      if (day && this.createDays(date).length < day) {
        newState.day = undefined;
      }

      this.setState(newState, () => this.onChange());
      this.setView('day');
    } else {
      this.setState({ year: date }, () => this.onChange());
      if (!month) {
        this.setView('month');
      }
    }
  };

  render() {
    const { day, month, year, view, showDropdown } = this.state;
    return (
      <div ref={this.myRef} className="BirthdayPicker">
        <div
          className={classNames(
            'BirthdayPicker-inputWrap',
            showDropdown && 'BirthdayPicker-inputWrap--opened'
          )}
          onClick={this.handleClick}
        >
          {!year && !month && !day && (
            <span className="BirthdayPicker-placeholder">
                            {t('Pick a date')}
                        </span>
          )}
          {(day || month || year) && (
            <div className="BirthdayPicker-valueWrap">
              <div
                className={classNames('BirthdayPicker-input', {
                  'BirthdayPicker-input--set': day
                })}
              >
                {day}
              </div>
              <div
                className={classNames('BirthdayPicker-input', {
                  'BirthdayPicker-input--set': month
                })}
              >
                {month}
              </div>
              <div
                className={classNames('BirthdayPicker-input', {
                  'BirthdayPicker-input--set': year
                })}
              >
                {year}
              </div>
            </div>
          )}
        </div>
        <div
          className={classNames('BirthdayPicker-dropdown', {
            'BirthdayPicker-dropdown--active': showDropdown
          })}
        >
          <div className="BirthdayPicker-infoWrap">
            <div
              className={classNames('BirthdayPicker-info', {
                'BirthdayPicker-info--active': view === 'day'
              })}
              onClick={() => this.setView('day')}
            >
              {day}
            </div>
            <div
              className={classNames('BirthdayPicker-info', {
                'BirthdayPicker-info--active': view === 'month'
              })}
              onClick={() => this.setView('month')}
            >
              {month}
            </div>
            <div
              className={classNames('BirthdayPicker-info', {
                'BirthdayPicker-info--active': view === 'year'
              })}
              onClick={() => this.setView('year')}
            >
              {year}
            </div>
          </div>
          <div
            className={classNames('BirthdayPicker-contentWrap', {
              'BirthdayPicker-contentItem--years':
                view === 'year'
            })}
          >
            {view === 'day' &&
              this.createDays().map(day => {
                return (
                  <div
                    className="BirthdayPicker-contentItem BirthdayPicker-contentItem--day"
                    key={day}
                    onClick={() => this.setValue(day)}
                  >
                    {day}
                  </div>
                );
              })}
            {view === 'month' &&
              this.createMonths().map(month => {
                return (
                  <div
                    className="BirthdayPicker-contentItem BirthdayPicker-contentItem--month"
                    key={month}
                    onClick={() => this.setValue(month)}
                  >
                    {month}
                  </div>
                );
              })}
            {view === 'year' &&
              this.createYears().map(year => {
                return (
                  <div
                    className="BirthdayPicker-contentItem BirthdayPicker-contentItem--year"
                    key={year}
                    onClick={() => this.setValue(year)}
                  >
                    {year}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}
