import React, { Component } from 'react';
import { object, string } from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { NavLink } from 'components';
import { getCurrentLocale, getLocalePrefix } from '../../utils/lang-helper';
import { ReactComponent as instagramSVG } from 'svg/instagram.svg';
import { ReactComponent as facebookSVG } from 'svg/facebook.svg';
import { ReactComponent as twitterSVG } from 'svg/twitter.svg';
import { ReactComponent as pinterestSVG } from 'svg/pinterest.svg';
import { ReactComponent as youtubeSVG } from 'svg/youtube.svg';
import { ReactComponent as linkedinSVG } from 'svg/linkedin.svg';
import { ReactComponent as weiboSVG } from 'svg/weibo.svg';
import { ReactComponent as wechatSVG } from 'svg/wechat.svg';
import './styles.scss';

const socialSvg = {
  instagram: instagramSVG,
  facebook: facebookSVG,
  twitter: twitterSVG,
  pinterest: pinterestSVG,
  youtube: youtubeSVG,
  linkedin: linkedinSVG,
  weibo: weiboSVG,
  wechat: wechatSVG
};

class FooterContainer extends Component {
  static propTypes = {
    pageLang: string
  };

  componentDidMount() {
    const _linkedin_partner_id = '2802801';
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);

    //Add Google Search
    const API_KEY = process.env.GATSBY_GOOGLE_SEARCH_API ? process.env.GATSBY_GOOGLE_SEARCH_API : '';
    const script = document.createElement('script');
    //const path = `https://cse.google.com/cse.js?cx=${process.env.GATSBY_GOOGLE_SEARCH_API}`;
    script.src = `https://cse.google.com/cse.js?cx=${API_KEY}`;
    document.body.append(script);

  }

  render() {
    const {
      pageLang,
      footerClass,
      prismic: { allPrismicFooter }
    } = this.props;

    // Get current Language
    const footerData = allPrismicFooter.nodes.filter(obj => {
      return obj.lang === pageLang;
    })[0].data;

    const socialLinks = footerData?.social_list.map(el => {
      return {
        icon: socialSvg[el.name.toLowerCase()],
        name: el.name,
        link: el.link ? el.link.url : '',
        rel: el.link_rel && el.link_rel !== 'follow' ? el.link_rel : null
      };
    });

    const footerLinks = footerData?.nav_list.map(el => {
      return {
        name: el.name,
        link: el.link ? getLocalePrefix() + el.link.uid : '',
        rel: el.link_rel && el.link_rel !== 'follow' ? el.link_rel : null
      };
    });

    const trackCallback = (props) => {
      try {
        dataLayer.push({
          event: 'navigationClick',
          data: {
            place: 'Footer',
            location: props.to
          }
        });
      } catch (e) {
        // do nothing
      }
    };

    return (
      <>
        <footer className={footerClass}>
          <div className="Wrapper">
            <nav className="Footer-menu Menu">
              <ul className="Menu-list">
                {footerLinks && footerLinks.map(({ name, link, rel = null }) =>
                  <NavLink
                    key={name}
                    link={link}
                    liClass="Menu-item"
                    aClass="Menu-link"
                    rel={rel}
                    highlighted={link.includes('contact-us')}
                    callback={trackCallback}
                  >
                    {name}
                  </NavLink>
                )}
              </ul>
            </nav>

            <div className="Footer-info">
              <nav className="Footer-socials Socials">
                <ul className="Socials-list">
                  {socialLinks && socialLinks.map(({ name, link, icon = null, rel = null }) =>
                    <NavLink
                      key={name}
                      link={link}
                      liClass="Socials-item"
                      aClass="Socials-link"
                      rel={rel}
                      callback={trackCallback}
                    >
                      {typeof icon === 'function' ? icon() : name}
                    </NavLink>
                  )}
                </ul>
              </nav>

              {/*<Newsletter*/}
              {/*    gainBefore={t(*/}
              {/*        'Sign up to our mailing list to receive latest stories, news, events and much more.',*/}
              {/*    )}*/}
              {/*    gainAfter={t(*/}
              {/*        'Congratulations! You are now subscribed to ISKO’s newsletter',*/}
              {/*    )}*/}
              {/*    titleBefore={t('Never miss a story')}*/}
              {/*    titleAfter={t('Thank you')}*/}
              {/*    btnText={t('Reset')}*/}
              {/*/>*/}
            </div>
          </div>
          <p className="Footer-copyright">{footerData.copyright}</p>
        </footer>
      </>
    );
  }
}

const Footer = props => (
  <StaticQuery
    query={graphql`
      query {
        allPrismicFooter {
          nodes {
            lang
            data {
              social_list {
                name: social_name
                link: social_link {
                  url
                }
                link_rel: social_link_rel
              }
              nav_list {
                name
                link {
                  uid
                }
                link_rel
              }
              copyright
            }
          }
        }
      }
    `}
    render={data => <FooterContainer prismic={data} {...props} />}
  />
);

FooterContainer.propTypes = {
  footerClass: string.isRequired,
  prismic: object.isRequired
};

export default Footer;
