import React from 'react';
import { object } from 'prop-types';
import { navigate } from 'gatsby';
import { Controller, Scene } from 'react-scrollmagic';
import { getLocalePrefix } from '../../utils/lang-helper';
import { Quote, ImageWrap } from 'components';
import './styles.scss';

const ContentSnippetPortraitModuleEm009 = ({
  primary: {
    em9_message,
    em9_name_position,
    em9_text,
    em9_link,
    em9_link_rel,
    em9_highlight_text,
    em9_hide,
    em9_image
  }
}) => {
  const highlight = em9_highlight_text && (
    <span className="LSLandScape-info--highlight">{`${em9_highlight_text} `}</span>
  );
  return em9_hide === 'false' ? (
    <Controller>
      <Scene classToggle="is-visible-image" triggerHook={1}>
        <section className="LSPortrait">
          <figure className="LSPortrait-imgWrap">
            <div onClick={() => em9_link && navigate(em9_link.uid)} data-rel={em9_link_rel}>
              <ImageWrap
                image={em9_image}
                className="LSPortrait-image"
              />
            </div>
            {em9_text || em9_highlight_text ? (
              <figcaption
                onClick={() =>
                  em9_link &&
                  navigate(getLocalePrefix() + em9_link.uid)
                }
                data-rel={em9_link_rel}
                className="LSPortrait-info"
              >
                {highlight}
                {em9_text}
              </figcaption>
            ) : null}
          </figure>
          <div onClick={() => em9_link && navigate(em9_link.uid)} data-rel={em9_link_rel}>
            <Quote
              classWrap="LSPortrait-quoteWrap"
              classQuote="LSPortrait-quote"
              classAuthor="LSPortrait-sign"
              quoteData={em9_message.html}
              quoteAuthor={em9_name_position}
            />
          </div>
        </section>
      </Scene>
    </Controller>
  ) : null;
};

ContentSnippetPortraitModuleEm009.propTypes = {
  primary: object.isRequired
};

export default ContentSnippetPortraitModuleEm009;
