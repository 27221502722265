import React from 'react';
import { object } from 'prop-types';
import ImageWrap from 'components/ImageWrap';
import './styles.scss';

const TwoTextSnippetEm023 = ({
  primary: {
    em023_main_title,
    em023_main_text,
    em023_main_photo,
    em023_sub_title,
    em023_sub_text
  }
}) => {

  const img =
    em023_main_photo !== null ||
    em023_main_photo.fluid !== null
      ? em023_main_photo
      : null;

  return (
    <section className="TwoTextSnippetEm023">
      {em023_main_title && (
        <h4 className="TwoTextSnippetEm023-mainTitle">{em023_main_title}</h4>
      )}

      {em023_main_text && (
        <p className="TwoTextSnippetEm023-mainText">{em023_main_text.text}</p>
      )}

      {img && (
        <div className="TwoTextSnippetEm023-mainPhoto">
          <ImageWrap
            className="Region-contactPhoto"
            image={img}
          />
        </div>
      )}

      {(em023_sub_title || em023_sub_text) && (
        <div className="TwoTextSnippetEm023-subWrapper">
          <div className="TwoTextSnippetEm023-subWrapper-content">
            {em023_sub_title && (
              <h5 className="TwoTextSnippetEm023-subTitle">{em023_sub_title}</h5>
            )}

            {em023_sub_text && (
              <p className="TwoTextSnippetEm023-subText">{em023_sub_text.text}</p>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

TwoTextSnippetEm023.propTypes = {
  primary: object.isRequired
};

export default TwoTextSnippetEm023;
