import React, { Component } from 'react';
import { object, array, string } from 'prop-types';
import { Controller, Scene } from 'react-scrollmagic';
import { graphql, StaticQuery, navigate } from 'gatsby';
import { Link } from 'gatsby-plugin-modal-routing';
import { getLocalePrefix, t } from '../../utils/lang-helper';
import { ImageWrap } from 'components';
import './styles.scss';

class StoriesGridLayout extends Component {
  state = {
    itemsToShow: 4,
    showStories: 4
  };

  static propTypes = {
    primary: object.isRequired,
    items: array.isRequired,
    uid: string.isRequired
  };

  goToStories = () => navigate(getLocalePrefix() + this.props.uid);

  loadMoreStories = () => {
    this.setState(prev => {
      return {
        itemsToShow: prev.itemsToShow + this.state.showStories
      };
    });
  };

  muteVideo = () => {
    //select all video playing so they can be muted
    const muteButtonImg = document.querySelectorAll(
      '#isko_icon_video-noise_svg__Layer_1'
    );
    if (muteButtonImg) {
      Array.from(muteButtonImg).map(el => {
        el.parentElement.click();
      });
    }
  };

  render() {
    const {
      state: { itemsToShow },
      props: {
        primary: { gm1_section_title, gm1_enable_load_more, gm1_hide },
        items
      }
    } = this;
    return gm1_hide === 'false' ? (
      <section className="StoriesGrid StoriesGridGm001">
        <h2 className="StoriesGridGm001-title">{gm1_section_title}</h2>
        {items.slice(0, itemsToShow).map((el, index) => (
          <div key={index}>
            <Controller>
              <Scene classToggle="is-visible" triggerHook={1}>
                <div className="StoriesGrid-contentBox isInvisibleContent">
                  {el.gm1_latest_stories_link &&
                    (el.gm1_latest_stories_link.uid ? (
                      <>
                        <Link
                          className="StoriesGrid-imageLink"
                          to={
                            getLocalePrefix() +
                            el
                              .gm1_latest_stories_link
                              .uid
                          }
                          rel={el.gm1_latest_stories_link_rel}
                          asModal
                          onClick={() =>
                            this.muteVideo()
                          }
                        >
                          <ImageWrap
                            className="StoriesGrid-image"
                            image={
                              el.gm1_latest_stories_image
                            }
                            style={{
                              objectFit: 'cover'
                            }}
                          />
                        </Link>
                        <Link
                          className="StoriesGrid-textBox"
                          to={
                            getLocalePrefix() +
                            el
                              .gm1_latest_stories_link
                              .uid
                          }
                          rel={el.gm1_latest_stories_link_rel}
                          asModal
                          onClick={() =>
                            this.muteVideo()
                          }
                        >
                          <div>
                            <h3 className="StoriesGrid-subTitle">
                              {
                                el
                                  .gm1_latest_stories_title
                                  .text
                              }
                            </h3>
                            <div
                              className="StoriesGrid-story"
                              dangerouslySetInnerHTML={{
                                __html:
                                el
                                  .gm1_latest_stories_text
                                  .html
                              }}
                            />
                            <br />
                            <span className="StoriesGrid-link">
                                                            {
                                                              el
                                                                .gm1_latest_stories_link_name
                                                                .text
                                                            }
                                                        </span>
                          </div>
                        </Link>
                      </>
                    ) : (
                      el.gm1_latest_stories_link.url && (
                        <>
                          <a
                            className="StoriesGrid-imageLink"
                            href={
                              el
                                .gm1_latest_stories_link
                                .url
                            }
                            rel={el.gm1_latest_stories_link_rel}
                            onClick={() =>
                              this.muteVideo()
                            }
                          >
                            <ImageWrap
                              className="StoriesGrid-image"
                              image={
                                el.gm1_latest_stories_image
                              }
                              style={{
                                objectFit:
                                  'cover'
                              }}
                            />
                          </a>
                          <a
                            className="StoriesGrid-textBox"
                            href={
                              el
                                .gm1_latest_stories_link
                                .url
                            }
                            rel={el.gm1_latest_stories_link_rel}
                            onClick={() =>
                              this.muteVideo()
                            }
                          >
                            <div>
                              <h3 className="StoriesGrid-subTitle">
                                {
                                  el
                                    .gm1_latest_stories_title
                                    .text
                                }
                              </h3>
                              <div
                                className="StoriesGrid-story"
                                dangerouslySetInnerHTML={{
                                  __html:
                                  el
                                    .gm1_latest_stories_text
                                    .html
                                }}
                              />
                              <br />
                              <span className="StoriesGrid-link">
                                                                {
                                                                  el
                                                                    .gm1_latest_stories_link_name
                                                                    .text
                                                                }
                                                            </span>
                            </div>
                          </a>
                        </>
                      )
                    ))}
                </div>
              </Scene>
            </Controller>
          </div>
        ))}
        {gm1_enable_load_more === 'false' ? (
          <div className="StoriesGrid-viewAllButton">
            <button onClick={this.goToStories} type="button">
              {t('ALL OF OUR STORIES')}
            </button>
          </div>
        ) : items.length > itemsToShow ? (
          <div className="StoriesGrid-viewAllButton">
            <button onClick={this.loadMoreStories} type="button">
              {t('LOAD MORE')}
            </button>
          </div>
        ) : null}
      </section>
    ) : null;
  }
}

const StoriesGridGm001 = props => (
  <StaticQuery
    query={graphql`
      {
        prismicStories {
          uid
        }
      }
    `}
    render={({ prismicStories: { uid } }) => (
      <StoriesGridLayout uid={uid} {...props} />
    )}
  />
);

export default StoriesGridGm001;
