import React from 'react';
import { object } from 'prop-types';
import { Link, navigate } from 'gatsby';
import { Controller, Scene } from 'react-scrollmagic';
import { getLocalePrefix } from '../../utils/lang-helper';
import { Quote, ImageWrap } from 'components';
import './styles.scss';

const ContentSnippetLandscapeModuleEm008 = ({
  primary: {
    em8_message,
    em8_name_position,
    em8_text,
    em8_link,
    em8_link_rel,
    em8_highlight_text,
    em8_hide,
    em8_image
  }
}) => {
  const highlight = em8_highlight_text && (
    <span className="LSLandScape-info--highlight">{`${em8_highlight_text} `}</span>
  );
  return em8_hide === 'false' ? (
    <section className="LSLandScape">
      <Controller>
        <Scene classToggle="is-visible-quote" triggerHook={1}>
          <div className="LSLandScape-hidden">
            <div onClick={() => em8_link && navigate(em8_link.uid)} data-rel={em8_link_rel}>
              <Quote
                classWrap="LSLandScape-quoteWrap"
                classQuote="LSLandScape-quote"
                classAuthor="LSLandScape-sign"
                quoteData={em8_message.html}
                quoteAuthor={em8_name_position}
              />
            </div>
          </div>
        </Scene>
      </Controller>
      <Controller>
        <Scene classToggle="is-visible-image" triggerHook={1}>
          <figure className="LSLandScape-imgWrap">
            <Link
              className=""
              to={getLocalePrefix() + em8_link.uid}
              state=""
              rel={em8_link_rel}
            >
              <ImageWrap
                className="LSLandScape-image"
                image={em8_image}
              />
            </Link>
            {em8_text || em8_highlight_text ? (
              <Link
                className=""
                to={getLocalePrefix() + em8_link.uid}
                state=""
                rel={em8_link_rel}
              >
                <figcaption
                  data-rel={em8_link_rel}
                  className="LSLandScape-info"
                >
                  {highlight}
                  {em8_text}
                </figcaption>
              </Link>
            ) : null}
          </figure>
        </Scene>
      </Controller>
    </section>
  ) : null;
};

ContentSnippetLandscapeModuleEm008.propTypes = {
  primary: object.isRequired
};

export default ContentSnippetLandscapeModuleEm008;
