import SEO from './SEO';
import Navigation from './Navigation';
import Footer from './Footer';
import Layout from './Layout';
import ModalPage from './ModalPage';

import { Button } from './Button';
import NavLink from './NavLink';
import LanguageSwitcher from './LanguageSwitcher';

import Newsletter from './Newsletter';
import BackToTopButton from './BackToTopButton';
import FullScreenMoodVideoEm001 from './FullScreenMoodVideoEm001';
import FullWidthSignpostSm002 from './FullWidthSignpostSm002';
import LargeSignpostModuleSm004 from './LargeSignpostModuleSm004';
import StoriesGridGm001 from './StoriesGridGm001';
import IntroModuleEm003 from './IntroModuleEm003';
import CopyOnlyHighlightsModuleEm006 from './CopyOnlyHighlightsModuleEm006';
import MoodBoardModuleEm007 from './MoodBoardModuleEm007';
import ContentSnippetLandscapeModuleEm008 from './ContentSnippetLandscapeModuleEm008';
import ContentSnippetPortraitModuleEm009 from './ContentSnippetPortraitModuleEm009';
import ContentSnippetInSituVideoModuleEm010 from './ContentSnippetInSituVideoModuleEm010';
import RelatedProductsCarouselRm001 from './RelatedProductsCarouselRm001';
import ContextualEnquiryFormCfm001 from './ContextualEnquiryFormCfm001';
import CtaCenteredSm007 from './CtaCenteredSm007';
import TwoImagesIntroModuleEm011 from './TwoImagesIntroModuleEm011';
import AnchorModuleOm001 from './AnchorModuleOm001';
import MultipleSignpostModuleSm006 from './MultipleSignpostModuleSm006';
import VideoModuleEm005 from './VideoModuleEm005';
import SignpostCarouselSm005 from './SignpostCarouselSm005';
import SectionIntroEm004 from './SectionIntroEm004';
import InteractiveCarouselEm012 from './InteractiveCarouselEm012';
import ContentSnippetEm013 from './ContentSnippetEm013';
import CopyOnlySignpostSm0091 from './CopyOnlySignpostSm0091';
import TwoColumnCopyEm020 from './TwoColumnCopyEm020';
import ProductListingGridGm002 from './ProductListingGridGm002';
import RelatedStoriesCarouselRm002 from './RelatedStoriesCarouselRm002';
import FullWidthSignpostCarouselSm008 from './FullWidthSignpostCarouselSm008';
import FullWidthVideoEm014 from './FullWidthVideoEm014';
import ContentSnippetFullWidthCopyEm015 from './ContentSnippetFullWidthCopyEm015';
import SlideshowGalleryEm016 from './SlideshowGalleryEm016';
import ContentSnippet1xVisualWithoutCopyEm017 from './ContentSnippet1xVisualWithoutCopyEm017';
import ListingFiltersGm003 from './ListingFiltersGm003';
import CopyOnlyLeftHandSideEm002 from './CopyOnlyLeftHandSideEm002';
import StoryOfTheMonthSm001 from 'components/StoryOfTheMonthSm001';
import MultipleImagesSignpostModuleSm003 from 'components/MultipleImagesSignpostModuleSm003';
import SocialShareButtonsEm019 from 'components/SocialShareButtonsEm019';
import Quote from 'components/Quote';
import ImageWrap from 'components/ImageWrap';
import LinkWrap from 'components/LinkWrap';
import Separator from 'components/Separator';
import FilterItem from 'components/FilterItem';
import LoadButton from 'components/LoadButton';
import ProductCard from 'components/ProductCard';
import CollectionCard from 'components/CollectionCard';
import ArticleDetail from 'components/ArticleDetail';
import ArticleDetailRender from 'components/ArticleDetailRender';
import PreviewOfNextStory from 'components/PreviewOfNextStory';
import ContactFormWithTabsCfm003 from 'components/ContactFormWithTabsCfm003';
import ArticleTagsEm018 from 'components/ArticleTagsEm018';
import AccordionWithTabsEm021 from 'components/AccordionWithTabsEm021';
import ContentTextSnippetEm022 from './ContentTextSnippetEm022';
import TwoTextSnippetEm023 from 'components/TwoTextSnippetEm023';
import AnchorPlaceEm024 from 'components/AnchorPlaceEm024';
import MoreDetailsButton from './MoreDetailsButton';
import PrivacyPopUp from './PrivacyPopUp';
import QuickContactForm from './QuickContactForm';
import NewsletterWrapper from './NewsletterWrapper';

export const allComponents = {
  Em001: FullScreenMoodVideoEm001,
  Sm002: FullWidthSignpostSm002,
  Sm004: LargeSignpostModuleSm004,
  Gm001: StoriesGridGm001,
  Em003: IntroModuleEm003,
  Em006: CopyOnlyHighlightsModuleEm006,
  Em007: MoodBoardModuleEm007,
  Em008: ContentSnippetLandscapeModuleEm008,
  Em009: ContentSnippetPortraitModuleEm009,
  Em010: ContentSnippetInSituVideoModuleEm010,
  Rm001: RelatedProductsCarouselRm001,
  Rm002: RelatedStoriesCarouselRm002,
  Rm003: RelatedProductsCarouselRm001,
  fm001: ContextualEnquiryFormCfm001,
  Sm007: CtaCenteredSm007,
  Om001: AnchorModuleOm001,
  Em004: SectionIntroEm004,
  Em005: VideoModuleEm005,
  Sm005: SignpostCarouselSm005,
  Sm006: MultipleSignpostModuleSm006,
  Em013: ContentSnippetEm013,
  m0091: CopyOnlySignpostSm0091,
  Em011: TwoImagesIntroModuleEm011,
  Em012: InteractiveCarouselEm012,
  Em020: TwoColumnCopyEm020,
  Gm002: ProductListingGridGm002,
  Sm008: FullWidthSignpostCarouselSm008,
  Em014: FullWidthVideoEm014,
  Em015: ContentSnippetFullWidthCopyEm015,
  Em016: SlideshowGalleryEm016,
  Em017: ContentSnippet1xVisualWithoutCopyEm017,
  Gm003: ListingFiltersGm003,
  Em002: CopyOnlyLeftHandSideEm002,
  Em021: AccordionWithTabsEm021,
  Em022: ContentTextSnippetEm022,
  Em023: TwoTextSnippetEm023,
  Em024: AnchorPlaceEm024,
  fm003: ContactFormWithTabsCfm003,
  m0031: ContactFormWithTabsCfm003
};

export {
  SEO,
  Navigation,
  Footer,
  Layout,
  ModalPage,
  Button,
  NavLink,
  LanguageSwitcher,
  Newsletter,
  BackToTopButton,
  FullScreenMoodVideoEm001,
  FullWidthSignpostSm002,
  LargeSignpostModuleSm004,
  StoriesGridGm001,
  IntroModuleEm003,
  CopyOnlyHighlightsModuleEm006,
  MoodBoardModuleEm007,
  ContentSnippetLandscapeModuleEm008,
  ContentSnippetPortraitModuleEm009,
  ContentSnippetInSituVideoModuleEm010,
  RelatedProductsCarouselRm001,
  RelatedStoriesCarouselRm002,
  ContextualEnquiryFormCfm001,
  CtaCenteredSm007,
  TwoImagesIntroModuleEm011,
  AnchorModuleOm001,
  MultipleSignpostModuleSm006,
  VideoModuleEm005,
  SignpostCarouselSm005,
  SectionIntroEm004,
  InteractiveCarouselEm012,
  CopyOnlySignpostSm0091,
  TwoColumnCopyEm020,
  ProductListingGridGm002,
  FullWidthSignpostCarouselSm008,
  FullWidthVideoEm014,
  ContentSnippetFullWidthCopyEm015,
  SlideshowGalleryEm016,
  ContentSnippet1xVisualWithoutCopyEm017,
  ListingFiltersGm003,
  CopyOnlyLeftHandSideEm002,
  StoryOfTheMonthSm001,
  MultipleImagesSignpostModuleSm003,
  SocialShareButtonsEm019,
  Quote,
  ImageWrap,
  LinkWrap,
  Separator,
  FilterItem,
  LoadButton,
  ProductCard,
  CollectionCard,
  ArticleDetail,
  ArticleDetailRender,
  PreviewOfNextStory,
  ArticleTagsEm018,
  AccordionWithTabsEm021,
  ContentTextSnippetEm022,
  TwoTextSnippetEm023,
  AnchorPlaceEm024,
  MoreDetailsButton,
  PrivacyPopUp,
  QuickContactForm,
  NewsletterWrapper
};
