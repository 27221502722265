export function bcc(subject, content, toEmail, source) {
  // let bccMail = 'office@studio.bg';
  let bccMail = 'all.leads@isko.com.tr';

  let mailData = {
    subject: `ISKO BCC: ${subject}`,
    html: `
      <p><b>TO:</b> ${toEmail}</p>
      <p><b>SOURCE:</b> ${source}</p>
      <p><b>SUBJECT:</b> ${subject}</p>
      <hr/>
      <h2>Content</h2>
      ${content}
    `,
    from_name: 'BCC NO REPLY',
    from_email: 'bcc-no-reply@isko.com.tr',
    to: [
      {
        email: `${bccMail}`,
        type: 'to'
      }
    ]
  };

  fetch('https://mandrillapp.com/api/1.0/messages/send.json', {
    method: 'post',
    body: JSON.stringify({
      key: '0K9RSSnf1OxqWsXqaIYZQw',
      message: mailData
    })
  });
}
