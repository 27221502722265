import React from 'react';
import { changeLocale, getCurrentLocale } from '../../utils/lang-helper';
import i18nData from '../../../config/i18n';
import './styles.scss';
import classNames from 'classnames';
import { any } from 'prop-types';

class LanguageSwitcher extends React.PureComponent {
  static propTypes = {
    callback: any,
    expandedItemKey: any
  };

  render() {
    const {
      props: {
        callback,
        expandedItemKey
      }
    } = this;

    const currentLocaleName = i18nData.locales[getCurrentLocale()].label;

    const handleChange = (localeId) => {
      // 1. Get current url
      // 2. Strip last part aka slug
      // 3. changeLocale and go to current page in new locale
      const segmentStr = window.location.pathname;
      const segmentArray = segmentStr.split('/');
      const lastSegmentOfUrl = segmentArray.pop();
      changeLocale(localeId, true, lastSegmentOfUrl, true);
    };

    return <>
      <li
        id="navigation-item-lang"
        className={classNames('Navigation-item', 'LanguageSwitcher', {
          'is-expanded': expandedItemKey === 'lang'
        })}
      >
        <div className="Navigation-item-link" onClick={() => callback('lang')}>
          <div className="sub-arrow" />
          <span className="lined">
            <span className="icon" />
            {currentLocaleName}
          </span>
        </div>

        <div className="Navigation-sub-items-wrapper">
          <div className="Navigation-sub-items">
            <ul className="Navigation-column">
              {Object.entries(i18nData.locales).map(([localeId, localeData]) =>
                <li className="Navigation-sub-item" key={`lang-${localeData.label}`}>
                  <span className="Navigation-sub-item-link" onClick={handleChange.bind(this, localeId)}>
                    <span>{localeData.label}</span>
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </li>
    </>;
  }
}

export default LanguageSwitcher;
