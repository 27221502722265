import React from 'react';
import { bool, func } from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

export const Button = ({ isOpened, toggleNav }) => {
  return (
    <button
      className={classNames('Navigation-toggleButton ToggleButton', {
        'is-opened': isOpened
      })}
      onClick={toggleNav}
    >
      <div className="ToggleButton-line line-1" />
      <div className="ToggleButton-line line-2" />
      <div className="ToggleButton-line line-3" />
    </button>
  );
};

Button.propTypes = {
  isOpened: bool.isRequired,
  toggleNav: func.isRequired
};
